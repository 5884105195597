import classNames from "classnames/bind";
import { Icon } from "semantic-ui-react";

import { type AssessmentQuestion as Question } from "../../../types";
import { AssessmentQuestion } from "../AssessmentQuestion/AssessmentQuestion";

import styles from "./AssessmentResults.module.scss";

const cx = classNames.bind(styles);

interface Props {
  questions: Question[];
  selectedAnswers: { [key: number]: boolean[] };
  results: { [key: number]: { isCorrect: boolean; answers: boolean[] } };
}

export const AssessmentResults: React.FC<Props> = ({ questions, selectedAnswers, results }) => {
  return (
    <div className={styles.root}>
      <div className={styles.questions}>
        {questions.map(question => (
          <div key={question.text} className={styles.question}>
            <Icon
              className={
                results[question.questionOrder].isCorrect
                  ? cx("check circle outline light", styles.green)
                  : cx("times circle outline", styles.red)
              }
            />
            <AssessmentQuestion
              isQuestionCorrect={results[question.questionOrder].isCorrect}
              question={question}
              selectedAnswers={selectedAnswers[question.questionOrder]}
              correctAnswers={results[question.questionOrder].answers}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
