import { createAction, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { isNumber } from "lodash";
import { RStatus, RtnStatus } from "../../../Application/types";
import {
  type AssetError,
  type EvaluatedAssessment,
  type Flow,
  type FlowAsset,
  type FlowAssetMeta,
  type FlowDetails,
  type FlowError,
  type FlowProgress,
  type Progress,
  type ProgressRecord,
  ProgressStatus,
} from "../../types";

import { AssessmentStep } from "../../../Assets/redux/assessment/assessmentSlice";
import { type QuestionsAnswers, type SurveyAnswers } from "../../../Assets/types";
import { EntityType, type FlowEntity } from "../../../common/types";
import { type SessionRegistrationFlowRequest } from "../../../Events/types";
import { getAssetKey } from "../../helpers/flowHelper";

export interface FlowState {
  status: RStatus;
  current?: FlowDetails;
  assets: FlowAssetMeta[];
  assetsMap: {
    [key: string]: {
      asset?: FlowAsset;
      status: RStatus;
      error?: AssetError;
    };
  };
  progress: FlowProgress;
  evaluatedAssessment?: EvaluatedAssessment;
  currentAssessmentStep: AssessmentStep;
  error?: FlowError;
  updateStatus: RtnStatus;
  assetTransitionPending?: boolean;
  redirectId: number | undefined;
}

export interface SkipPayload {
  assetId: number;
  assetType: FlowEntity;
}

const initialState: FlowState = {
  status: RStatus.Idle,
  current: undefined,
  assets: [],
  assetsMap: {},
  progress: { viewedAssetIndexes: [], selectedAssetIndex: 0 },
  evaluatedAssessment: undefined,
  currentAssessmentStep: AssessmentStep.Taking,
  error: undefined,
  updateStatus: RtnStatus.Idle,
  redirectId: undefined,
};

const name = "flow";

const flowSlice = createSlice({
  name,
  initialState: initialState,
  reducers: {
    req(_state, _: PayloadAction<{ id: number; startFlowActive?: boolean }>) {
      return { ...initialState, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ current: FlowState["current"] }>) {
      return {
        ...state,
        current: action.payload.current,
      };
    },
    gotAssets(state, action: PayloadAction<{ assets: FlowState["assets"] }>) {
      return {
        ...state,
        status: RStatus.Got,
        assets: action.payload.assets,
        progress: getFlowProgress(state.current?.progress?.records ?? []),
      };
    },
    reqAssetDetails(state, _: PayloadAction<{ asset: FlowAssetMeta }>) {
      return {
        ...state,
        assetsMap: {
          ...state.assetsMap,
          [getAssetKey(_.payload.asset)]: {
            asset: undefined,
            status: RStatus.Pending,
            error: undefined,
          },
        },
      };
    },
    gotAssetDetails(state, action: PayloadAction<{ asset: FlowAsset }>) {
      return {
        ...state,
        assetsMap: {
          ...state.assetsMap,
          [getAssetKey(action.payload.asset)]: {
            asset: action.payload.asset,
            status: RStatus.Got,
            error: undefined,
          },
        },
      };
    },
    err(state, action: PayloadAction<{ error: FlowState["error"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        error: action.payload.error,
      };
    },
    assetErr(state, action: PayloadAction<AssetError>) {
      state.assetsMap = {
        ...state.assetsMap,
        [getAssetKey(action.payload.asset)]: {
          asset: undefined,
          status: RStatus.Error,
          error: action.payload,
        },
      };
    },
    reset() {
      return initialState;
    },
    setAssetIndex(state, action: PayloadAction<{ index: number }>) {
      state.progress = {
        selectedAssetIndex: action.payload.index,
        viewedAssetIndexes: state.progress.viewedAssetIndexes,
      };
      state.assetTransitionPending = false;
    },
    nextAsset: moveNext,
    previousAsset(state) {
      const newSelectedIndex =
        state.progress.selectedAssetIndex && state.progress.selectedAssetIndex > 0
          ? state.progress.selectedAssetIndex - 1
          : 0;

      state.progress = {
        selectedAssetIndex: newSelectedIndex,
        viewedAssetIndexes: state.progress.viewedAssetIndexes,
      };
      state.assetTransitionPending = false;
    },
    completePdfAsset(
      state,
      action: PayloadAction<{
        flowId: number;
        assetId: number;
        assetOrder: number;
      }>,
    ) {},
    completeVideoAsset(
      state,
      action: PayloadAction<{
        flowId: number;
        assetId: number;
        assetOrder: number;
      }>,
    ) {},
    completeAssessmentAsset(
      state,
      _: PayloadAction<{
        flowId: number;
        assetId: number;
        data: QuestionsAnswers;
      }>,
    ) {
      state.currentAssessmentStep = AssessmentStep.Grading;
    },
    completeSurveyAsset(
      state,
      action: PayloadAction<{
        flowId: number;
        surveyId: number;
        data: SurveyAnswers;
        assetOrder: number;
      }>,
    ) {},
    skip(state, action: PayloadAction<SkipPayload>) {},
    startTransition(
      state,
      action: PayloadAction<{
        assetOrder: number;
      }>,
    ) {
      const {
        current,
        progress: { selectedAssetIndex },
      } = state;
      const { assetOrder } = action.payload;

      if (selectedAssetIndex !== assetOrder) {
        return;
      }

      const isCompleted =
        current?.progress.records.find(({ order }) => order === assetOrder)?.status === ProgressStatus.Completed;

      if (!isCompleted) {
        state.updateStatus = RtnStatus.Pending;
        state.assetTransitionPending = true;
      } else {
        moveNext(state, action);
      }
    },
    startProgressUpdate(state, action: PayloadAction<Progress>) {
      return state;
    },
    finishProgressUpdate(state, action: PayloadAction<{ assets: FlowState["assets"]; progress: Progress }>) {
      const {
        current,
        progress: { selectedAssetIndex },
      } = state;
      const { progress: newProgress, assets: newAssets } = action.payload;

      if (current?.flow?.id !== parseInt(newProgress?.flowId)) {
        return state;
      }

      const nextItemIndex = selectedAssetIndex + 1;
      const nextAsset = newProgress.records.find(({ order }) => order === nextItemIndex);

      const isNextItemAvailable =
        !!nextAsset && [ProgressStatus.Active, ProgressStatus.Completed].includes(nextAsset?.status);
      const isNextDelayed = !!nextAsset?.bag.availableAt;

      state.current!.progress = newProgress;
      state.status = RStatus.Got;
      state.assets = newAssets;
      state.progress = getFlowProgress(
        newProgress?.records,
        selectedAssetIndex,
        isNextItemAvailable && state.assetTransitionPending,
      );
      state.updateStatus = isNextItemAvailable || isNextDelayed ? RtnStatus.Idle : state.updateStatus;
      state.assetTransitionPending = !isNextItemAvailable && state.assetTransitionPending;
    },
    setEvaluatedAssessment(state, action: PayloadAction<EvaluatedAssessment>) {
      if (state.currentAssessmentStep !== AssessmentStep.Grading) {
        return state;
      }
      const assessment = state.assets.find(a => a.type === EntityType.Assessment && a.id === action.payload.id);
      if (!assessment) {
        return state;
      }
      return {
        ...state,
        evaluatedAssessment: action.payload,
      };
    },
    registerOnEventSessionInFlow(state, _: PayloadAction<SessionRegistrationFlowRequest>) {
      return state;
    },
    restartFlow(state, _: PayloadAction<{ id: number }>) {
      if (state.current) {
        state.current.progress.records = [];
      }
      state.status = RStatus.Pending;
      state.assetsMap = {};
      state.evaluatedAssessment = initialState.evaluatedAssessment;
      state.updateStatus = RtnStatus.Idle;
    },
    updateFlow(state, action: PayloadAction<Flow>) {
      if (state.current) state.current.flow = action.payload;
    },
    redirect(state, action: PayloadAction<number | undefined>) {
      state.redirectId = action.payload;
    },
  },
});

function moveNext(state: FlowState, action: PayloadAction<{ assetOrder: number }>) {
  const { assetOrder } = action.payload;
  const {
    progress: { viewedAssetIndexes, selectedAssetIndex },
    current,
  } = state;

  if (assetOrder !== selectedAssetIndex) {
    return;
  }

  const isCurrentAssetCompleted =
    current?.progress.records.find(({ order }) => order === assetOrder)?.status === ProgressStatus.Completed;

  if (!isCurrentAssetCompleted) {
    return;
  }

  const newSelectedIndex = selectedAssetIndex + 1;

  state.evaluatedAssessment = undefined;
  state.progress = {
    selectedAssetIndex: newSelectedIndex,
    viewedAssetIndexes: viewedAssetIndexes,
  };
  state.assetTransitionPending = false;
}

function getFlowProgress(records: ProgressRecord[], currentIndex?: number, moveNext?: boolean) {
  const viewedAssetIndexes: number[] = records
    .filter(x => x.status === ProgressStatus.Completed)
    .map(x => x.order)
    .sort((a, b) => a - b);

  let selectedAssetIndex: number;

  const isInitization = !(isNumber(currentIndex) && currentIndex >= 0);

  if (isInitization || viewedAssetIndexes.length === 0) {
    const nextActive = records.find(x => x.status === ProgressStatus.Active);
    selectedAssetIndex = nextActive?.order ?? viewedAssetIndexes[viewedAssetIndexes.length - 1] ?? 0;
  } else {
    selectedAssetIndex = !moveNext ? currentIndex : currentIndex + 1;
  }

  return { selectedAssetIndex, viewedAssetIndexes };
}

// actions
export const {
  req,
  got,
  gotAssets,
  reqAssetDetails,
  gotAssetDetails,
  err,
  assetErr,
  setAssetIndex,
  reset,
  nextAsset,
  previousAsset,
  completePdfAsset,
  completeVideoAsset,
  completeAssessmentAsset,
  completeSurveyAsset,
  startProgressUpdate,
  finishProgressUpdate,
  setEvaluatedAssessment,
  registerOnEventSessionInFlow,
  restartFlow,
  updateFlow,
  startSurveyAsset,
  redirect,
  skip,
  startTransition,
} = {
  ...flowSlice.actions,
  startSurveyAsset: createAction<{ surveyId: number; flowId: number }>(`${name}/startSurveyAsset`),
};

// reducers
export default flowSlice.reducer;
