import { useCallback, useMemo } from "react";
import { Radio } from "semantic-ui-react";
import { Checkbox } from "../../../../Application/layout/Checkbox/Checkbox";

import { type SurveyAnswer, type SurveyQuestion as Question } from "../../../types";

import styles from "./SurveyQuestion.module.scss";

interface Props {
  answer: SurveyAnswer;
  idx: number;
  question: Question;
  updateCheckbox: (idx: number) => void;
  updateRadio: (idx: number) => void;
  selectedAnswer: boolean;
}

export const SurveyQuestionAnswers: React.FC<Props> = ({
  answer,
  idx,
  question,
  updateCheckbox,
  updateRadio,
  selectedAnswer,
}) => {
  const isQuestionMultiselect = useMemo(() => question.type === "MultipleChoiceTextQuestion", [question]);

  const AnswerComponent = isQuestionMultiselect ? Checkbox : Radio;
  const answerUpdater = isQuestionMultiselect ? updateCheckbox : updateRadio;

  const innerUpdateAnswer = useCallback(() => {
    answerUpdater(idx);
  }, [answerUpdater, idx]);

  return (
    <div className={styles.answer} key={answer.id}>
      <AnswerComponent
        className={styles.item}
        label={answer.answer}
        aria-label={answer.answer}
        checked={selectedAnswer}
        name={`answer${idx}`}
        onChange={innerUpdateAnswer}
      />
    </div>
  );
};
