import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../../../Application/layout/Tooltip/Tooltip";
import { unregisterFromSession } from "../../../redux/sessions/sessionsSlice";
import { type EventReminder, type EventSession } from "../../../types";
import { formatSessionDateTimeToLocalTime } from "../helpers/sessionDateFormatter";
import { SessionRegistration } from "../SessionRegistration/SessionRegistration";
import styles from "./SessionInfo.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Props {
  session: EventSession;
  onRegistration?: (sessionId: string, registrationType: number, sessionStartDate: string) => void;
  reminders: EventReminder[];
}
export const SessionInfo: React.FC<Props> = ({ session, onRegistration, reminders }) => {
  const dispatch = useDispatch();
  const browserTimeZone = useMemo(() => dayjs.tz.guess(), []);

  const sessionInfo: string = useMemo(() => {
    const { startDateTimeUtc, endDateTimeUtc } = session;
    return formatSessionDateTimeToLocalTime(startDateTimeUtc, endDateTimeUtc, browserTimeZone, true);
  }, [session, browserTimeZone]);

  const handleOnRegistration = useCallback(
    (registrationType: number) => {
      const { sessionId, startDateTime } = session;
      onRegistration?.(sessionId, registrationType, startDateTime);
    },
    [onRegistration, session],
  );

  const handleOnUnregistration = useCallback(() => {
    const { externalEventId, sessionId, startDateTime } = session;
    dispatch(
      unregisterFromSession({
        eventId: externalEventId,
        sessionId: sessionId,
        sessionStartDateTime: startDateTime,
      }),
    );
  }, [dispatch, session]);

  return (
    <div className={styles.sessionInfoContainer} tabIndex={0} data-testid="session-info-container">
      <div className={styles.sessionDateTimeContainer}>
        <Icon className={`thin calendar alternate outline`} color="blue" size="large" />
        <span className={styles.sessionDateTime}>
          {sessionInfo}
          <Tooltip
            size="tiny"
            content={<i>Time zone is based on your settings in My Account &gt; Settings.</i>}
            trigger={<span> ({browserTimeZone})</span>}
          />
        </span>
      </div>
      <div className={styles.sessionRegistrationContainer}>
        <div className={styles.sessionRegistrationActions} data-testid="session-registration-actions">
          <SessionRegistration
            wasRegistered={session.wasRegistered}
            onRegistration={handleOnRegistration}
            onUnregistration={handleOnUnregistration}
          />
        </div>
      </div>
    </div>
  );
};
