import defaultImage from "assets/images/placeholder.png";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { PriorityLevel } from "../../../../../Discover/types";
import { EntityType } from "../../../../../common/types";
import { TextTruncator } from "../../../../layout/TextTruncator/TextTruncator";
import { durationSecondsToTime } from "../../../../utils/dateTime";
import { CardBody } from "../../CardBody/CardBody";
import { CardContent } from "../../CardContent/CardContent";
import { CardHeader } from "../../CardHeader/CardHeader";
import { CardImage } from "../../CardImage/CardImage";
import { CardMeta } from "../../CardMeta/CardMeta";
import { CardPriority } from "../../CardPriority/CardPriority";
import { ContentIcon } from "../../ContentIcon/ContentIcon";
import { type CardItem, type CardProps } from "./types";

import styles from "./AssetCard.module.scss";

const cx = classNames.bind(styles);

const defaultMetaProvider = (item: CardItem) => {
  return item.applications?.length ? [item.applications.join(", ")] : [];
};

export type AssetCardProps<T extends CardItem = CardItem> = CardProps<T> & {
  extraMeta?: React.ReactNode;
  metaProvider?: (item: T) => string[];
};

export const AssetCard = <T extends CardItem>(props: AssetCardProps<T>) => {
  const {
    item,
    to,
    action,
    hideAccessible,
    extraMeta,
    loading,
    orientation = "vertical",
    metaProvider = defaultMetaProvider,
    interactive: interactiveProp = true,
  } = props;
  const meta = useMemo(() => metaProvider(item), [item, metaProvider]);

  const {
    title,
    description,
    priority = PriorityLevel.None,
    dueDate,
    entityType: type,
    thumbnailUrl = defaultImage,
    duration,
    expired,
    isRecurrent,
  } = item;
  const interactive = interactiveProp && !expired;
  return (
    <div className={cx(styles.root, styles[`${orientation}`])}>
      <div
        className={cx(styles.imageContainer, {
          [styles.expired]: expired,
        })}
      >
        {expired && (
          <div className={styles.expiredWarning}>
            <div className={styles.expiredWarningTitle}>
              <Icon className="exclamation triangle" />
              <p>Content Unavailable</p>
            </div>
            <p>Contact your administrator to restore your access to this content in this Pack.</p>
          </div>
        )}
        <CardImage
          url={thumbnailUrl}
          alt={`${title} thumbnail`}
          link={to ? { to, ariaLabel: `Start watching ${title}` } : undefined}
          action={action}
          hideAccessible={hideAccessible}
          playable={type === EntityType.Video}
          className={cx(styles.image)}
          interactive={interactive}
          loading={loading}
        >
          {!!duration && <div className={cx(styles.length)}>{durationSecondsToTime(duration)}</div>}
        </CardImage>
      </div>
      <CardBody
        className={cx(styles.body, {
          [styles.expired]: expired,
        })}
      >
        <>
          <div className={styles.headerContainer}>
            <CardHeader loading={loading}>
              <>
                <div className={cx(styles.contentIcon)}>
                  <ContentIcon type={type} />
                  {isRecurrent && <Icon className={cx(styles.extraIcon, "repeat outline")} size="large" />}
                </div>
                <div className={styles.priorityContainer}>
                  <CardPriority level={priority} dueDate={dueDate} />
                </div>
              </>
            </CardHeader>
          </div>
          <CardContent
            title={title}
            link={to}
            action={action}
            description={description}
            hideAccessible={hideAccessible}
            interactive={interactive}
            loading={loading}
          />
          <div className={styles.metaContainer}>
            <CardMeta extra={extraMeta} loading={loading}>
              <>
                {meta?.map((m, i) => (
                  <TextTruncator
                    key={i}
                    tooltipContent={m.toLocaleUpperCase()}
                    clamp={1}
                    tabIndex={hideAccessible ? -1 : 0}
                    aria-label={m.toLocaleUpperCase()}
                  >
                    {m.toLocaleUpperCase()}
                  </TextTruncator>
                ))}
              </>
            </CardMeta>
          </div>
        </>
      </CardBody>
    </div>
  );
};
