import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppLoader } from "../../../Application/layout";
import { RStatus } from "../../../Application/types";
import { EntityType, FlowEntityType } from "../../../common/types";
import { getAssetKey } from "../../helpers/flowHelper";
import { reqAssetDetails } from "../../redux/flow/flowSlice";
import { selectAssetsMap, selectProgress } from "../../redux/flow/flowSelectors";
import {
  type AssetWithProgressFields,
  type FlowAssessmentAsset,
  type FlowEndMeta,
  type FlowExternalEventMeta,
  type FlowPdfAsset,
  type FlowProgress,
  type FlowSurveyAsset,
  type FlowVideoAsset,
} from "../../types";
import { FlowAssessment } from "../FlowAssessment/FlowAssessment";
import { FlowEmail } from "../FlowEmail/FlowEmail";
import { FlowEnd } from "../FlowEnd/FlowEnd";
import { FlowEvent } from "../FlowEvent/FlowEvent";
import { FlowMessage } from "../FlowMessage/FlowMessage";
import { FlowPdf } from "../FlowPdf/FlowPdf";
import { FlowSurvey } from "../FlowSurvey/FlowSurvey";
import { FlowVideo } from "../FlowVideo/FlowVideo";
import { FlowAssetDelayPlaceholder } from "./FlowAssetDelayPlaceholder/FlowAssetDelayPlaceholder";

import styles from "./FlowAssetView.module.scss";

const Loader = (
  <div className={styles.loader}>
    <AppLoader />
  </div>
);

interface Props {
  asset: AssetWithProgressFields;
  isMobileScreen: boolean;
  collapsed: boolean;
  onRestart: () => void;
  progress?: FlowProgress;
}

export const FlowAssetView: React.FC<Props> = ({ asset, isMobileScreen, collapsed, onRestart, progress }) => {
  const isHidden = isMobileScreen && collapsed;
  const dispatch = useDispatch();
  const assetsWithDetails = useSelector(selectAssetsMap)[getAssetKey(asset)];
  const hasError = assetsWithDetails?.status === RStatus.Error;
  const { selectedAssetIndex, viewedAssetIndexes } = useSelector(selectProgress);
  const currentAsset = assetsWithDetails?.asset;
  const resetAssetRef = useRef<() => void>();

  const isViewed = viewedAssetIndexes.includes(selectedAssetIndex);

  useEffect(() => {
    if (!currentAsset && !hasError) {
      dispatch(reqAssetDetails({ asset }));
    }
  }, [asset, dispatch, currentAsset, hasError]);

  if (assetsWithDetails?.status === RStatus.Pending || !currentAsset) {
    return Loader;
  }

  const renderFlowAsset = () => {
    if (asset.isDelayTimeNotExpired) {
      return <FlowAssetDelayPlaceholder entityType={asset.type} time={asset.bag.availableAt} />;
    }

    switch (asset.type) {
      case EntityType.Video:
        return (
          <FlowVideo
            hidden={isHidden}
            video={currentAsset as FlowVideoAsset}
            key={asset.order}
            order={asset.order}
            resetAssetRef={resetAssetRef}
          />
        );
      case EntityType.Assessment:
        return (
          <FlowAssessment
            hidden={isHidden}
            assessment={currentAsset as FlowAssessmentAsset}
            key={asset.order}
            order={asset.order}
            resetAssetRef={resetAssetRef}
          />
        );
      case EntityType.Survey:
        return (
          <FlowSurvey
            hidden={isHidden}
            survey={currentAsset as FlowSurveyAsset}
            key={asset.order}
            order={asset.order}
            resetAssetRef={resetAssetRef}
          />
        );
      case EntityType.Event:
        return (
          <FlowEvent
            hidden={isHidden}
            event={currentAsset as FlowExternalEventMeta}
            key={asset.order}
            order={asset.order}
            progress={progress}
            isViewed={isViewed}
          />
        );
      case EntityType.Email:
        return <FlowEmail isBranched={asset.bag.isBranched} />;
      case EntityType.Message:
        return <FlowMessage isBranched={asset.bag.isBranched} />;
      case FlowEntityType.FlowEnd:
        return <FlowEnd item={asset as FlowEndMeta} onRestart={onRestart} />;
      case EntityType.Pdf:
        return (
          <FlowPdf
            hidden={isHidden}
            pdf={currentAsset as FlowPdfAsset}
            key={asset.order}
            order={asset.order}
            completed={viewedAssetIndexes.includes(selectedAssetIndex)}
          />
        );
      default:
        return Loader;
    }
  };

  return <div className={styles.root}>{renderFlowAsset()}</div>;
};
