import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RStatus } from "features/Application/types";
import { type VideoQuestionsParsed } from "features/Assets/types";
import { type RootState } from "../../../Application/store/store";

interface VideoQuestionsState {
  status: RStatus;
  items: VideoQuestionsParsed[];
  answerStatus: RStatus;
  answer: string | undefined;
  answerErrorMessage: string | undefined;
  currentlyViewedQuestion: string | undefined;
  videoProgress: number;
  errorMessage: string | undefined;
}

const initialState: VideoQuestionsState = {
  status: RStatus.Idle,
  items: [],
  answerStatus: RStatus.Idle,
  answer: undefined,
  answerErrorMessage: undefined,
  currentlyViewedQuestion: undefined,
  videoProgress: 0,
  errorMessage: undefined,
};

const videoQuestionsSlice = createSlice({
  name: "videoQuestions",
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      state.status = RStatus.Pending;
    },
    reqAnswer(state, action: PayloadAction<{ id: number; questionText: string }>) {
      state.answerStatus = RStatus.Pending;
      state.currentlyViewedQuestion = action.payload.questionText;
      state.answer = undefined;
    },
    got(state, action: PayloadAction<{ items: VideoQuestionsState["items"] }>) {
      state.status = RStatus.Got;
      state.items = action.payload.items;
    },
    gotAnswer(state, action: PayloadAction<{ answer: string }>) {
      state.answerStatus = RStatus.Got;
      state.answer = action.payload.answer;
    },
    errAnswer(state, action: PayloadAction<{ errorMessage: VideoQuestionsState["answerErrorMessage"] }>) {
      state.answerStatus = RStatus.Error;
      state.answerErrorMessage = action.payload.errorMessage;
    },
    clearAnswer(state) {
      state.answerStatus = RStatus.Idle;
      state.currentlyViewedQuestion = undefined;
      state.answer = undefined;
      state.answerErrorMessage = undefined;
    },
    setVideoProgress(state, action: PayloadAction<number>) {
      state.videoProgress = action.payload;
    },
    err(state, action: PayloadAction<{ errorMessage: VideoQuestionsState["errorMessage"] }>) {
      state.status = RStatus.Error;
      state.errorMessage = action.payload.errorMessage;
    },
    reset() {
      return initialState;
    },
  },
});

// // actions
export const { req, reqAnswer, got, gotAnswer, clearAnswer, setVideoProgress, err, errAnswer, reset } =
  videoQuestionsSlice.actions;

// // selectors
export const selectVideoQuestions = (state: RootState) => state.assets.videoQuestions.items;
export const selectVideoQuestionsStatus = (state: RootState) => state.assets.videoQuestions.status;
export const selectVideoQuestionsError = (state: RootState) => state.assets.videoQuestions.errorMessage;
export const selectVideoQuestionsAnswerStatus = (state: RootState) => state.assets.videoQuestions.answerStatus;
export const selectVideoQuestionsAnswer = (state: RootState) => state.assets.videoQuestions.answer;
export const selectVideoProgress = (state: RootState) => state.assets.videoQuestions.videoProgress;
export const selectVisibleQuestion = (state: RootState) => state.assets.videoQuestions.currentlyViewedQuestion;
export const selectVideoQuestionsAnswerErrorMessage = (state: RootState) =>
  state.assets.videoQuestions.answerErrorMessage;

// // reducers
export default videoQuestionsSlice.reducer;
