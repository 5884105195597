import classNames from "classnames/bind";
import React, { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Icon, type SemanticCOLORS, type SemanticICONS } from "semantic-ui-react";
import { InvisibleButton } from "..";
import styles from "./Toast.module.scss";

const cx = classNames.bind(styles);

const AUTO_DISMISS_DELAY = 3000;

export enum ToastType {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

export interface Props {
  type: ToastType;
  body: ReactNode;
  callToAction?: {
    body: ReactNode;
    onClick: () => void;
  };
  visible: boolean;
  onClose: () => void;
  autoDismiss?: boolean;
  showDismiss?: boolean;
}

export const Toast: React.FC<Props> = ({ type, body, callToAction, visible, onClose, autoDismiss, showDismiss }) => {
  const [hide, setHide] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const callToActionOnClickRef = useRef(callToAction?.onClick);

  useEffect(() => {
    if (callToAction?.onClick) callToActionOnClickRef.current = callToAction.onClick;
  }, [callToAction?.onClick]);

  useEffect(() => {
    if (visible) {
      rootRef.current?.classList.add(styles.visible);
    } else {
      rootRef.current?.classList.remove(styles.visible);
      rootRef.current?.classList.add(styles.hidden);
    }
  }, [visible]);

  // Auto dismiss toast after set time
  useEffect(() => {
    let t: NodeJS.Timeout;
    if (visible && autoDismiss) {
      t = setTimeout(() => {
        setHide(true);
      }, AUTO_DISMISS_DELAY);
    }

    return () => {
      t && clearTimeout(t);
    };
  }, [hide, visible, autoDismiss]);

  // Run close event after transition completed
  useEffect(() => {
    if (!visible) return;
    const ele = rootRef.current as HTMLDivElement;
    const endHandler = (e: TransitionEvent) => {
      if (e.target !== ele) {
        return;
      }
      if (!ele.classList.contains(styles.visible)) {
        setHide(false);
        onClose?.();
      }
    };

    ele.addEventListener("transitionend", endHandler);
    return () => {
      ele.removeEventListener("transitionend", endHandler);
    };
  }, [visible, onClose]);

  const handleActionClick = useCallback(() => {
    callToActionOnClickRef.current?.();
  }, []);

  const closeHandler = useCallback(() => {
    setHide(true);
  }, []);

  const color = useMemo<SemanticCOLORS>(() => {
    switch (type) {
      case ToastType.Success:
        return "green";
      case ToastType.Warning:
        return "yellow";
      case ToastType.Error:
        return "red";
    }
  }, [type]);

  const icon = useMemo(() => {
    let iconName: SemanticICONS;
    switch (type) {
      case ToastType.Success:
        iconName = "check circle";
        break;
      case ToastType.Warning:
        iconName = "exclamation circle";
        break;
      case ToastType.Error:
        iconName = "exclamation triangle";
        break;
    }
    return <Icon data-testid="toast status icon" name={iconName} fitted size="large" color={color} />;
  }, [type, color]);

  return ReactDOM.createPortal(
    <div
      className={cx(styles.root, styles.centerAlignedBox, {
        [styles.hidden]: hide,
        [styles.success]: type === ToastType.Success,
        [styles.warning]: type === ToastType.Warning,
        [styles.error]: type === ToastType.Error,
      })}
      ref={rootRef}
      data-testid="toastRoot"
    >
      <div className={cx(styles.centerAlignedBox, styles.message)}>
        <div className={styles.iconWrapper}>{icon}</div>
        <div>{body}</div>
      </div>
      <div className={styles.centerAlignedBox}>
        {callToAction && (
          <div className={styles.actionWrapper}>
            <Button color={color} inverted content={callToAction.body} onClick={handleActionClick} />
          </div>
        )}
        {showDismiss && (
          <div className={styles.closeWrapper}>
            <InvisibleButton onClick={closeHandler} ariaLabel="Close">
              <Icon className="thin" name="times" size="large" color="grey" />
            </InvisibleButton>
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};
