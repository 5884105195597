import classNames from "classnames/bind";
import { FeatureFlags } from "featureFlags";
import {
  req,
  selectVideoProgress,
  selectVideoQuestions,
  reset,
} from "features/Assets/redux/videoQuestions/videoQuestionsSlice";
import { useFeatureFlag } from "features/common/hooks/useFeatureFlag";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./VideoDetailsQuestions.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  id: number;
  onBubbleClick: (question: string) => void;
}

export const VideoDetailsQuestions: React.FC<Props> = ({ id, onBubbleClick }) => {
  // Should we be displaying any AI questions?
  const isAiQuestionsEnabled = useFeatureFlag(FeatureFlags.AiVideoQuestions);
  const currentVideoTimestamp = useSelector(selectVideoProgress);
  const questions = useSelector(selectVideoQuestions);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the questions
    if (isAiQuestionsEnabled) {
      dispatch(req({ id }));
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, id, isAiQuestionsEnabled]);

  if (!isAiQuestionsEnabled) {
    return null;
  }

  // Find the question that should be displayed at the current timestamp
  const currentQuestion = questions.find(
    q => q.startTimeSeconds <= currentVideoTimestamp && q.endTimeSeconds > currentVideoTimestamp,
  );

  return (
    <button
      className={cx(styles.bubble, {
        [styles.hidden]: !currentQuestion || currentVideoTimestamp === 0,
        [styles.visible]: !!currentQuestion,
      })}
      onClick={() => onBubbleClick(currentQuestion?.question!)}
    >
      {currentQuestion?.question}
    </button>
  );
};
