import axios, { type AxiosResponse } from "axios";
import http from "../../Application/http/http";
import {
  type AssessmentAsset,
  type PdfAssetStandAloneDetails,
  type QuestionsAnswers,
  type SurveyAnswers,
  type SurveyAsset,
  type VideoPreviewAsset,
} from "../types";

const apiV4Url = "/api/v4/client/assets";

let abortController = new AbortController();

export function fetchAssetV4(id: number) {
  return http.get<VideoPreviewAsset>(`${apiV4Url}/videos/${id}`);
}

export function fetchVideoAiQuestions(id: number) {
  return axios.get<{ questions: Record<string, string[]> }>(`/api/assets/videos/${id}/ai-assistant/questions`);
}

export function fetchVideoAiAnswer(id: number, questionText: string) {
  return axios.get<{ answer: string }>(`/api/assets/videos/${id}/ai-assistant/answer`, {
    params: {
      question: encodeURIComponent(questionText),
    },
  });
}

export function translate(id: number, languageId: string) {
  return axios.post(`/api/assets/videos/${id}/closed-captions/translate`, { languageId });
}

export function fetchAssessmentV4(id: number) {
  return http.get<AssessmentAsset>(`${apiV4Url}/assessments/${id}`);
}

export function evaluateAssessmentV4(assessmentId: number, data: QuestionsAnswers) {
  return axios.post(`${apiV4Url}/assessments/${assessmentId}/evaluate`, data);
}

export function fetchSurveyV4(id: number) {
  return http.get<SurveyAsset>(`${apiV4Url}/surveys/${id}`);
}

export function startSurveyV4(id: number) {
  return axios.post(`${apiV4Url}/surveys/${id}/start`);
}

export function completeSurveyV4(surveyId: number, data: SurveyAnswers) {
  return axios.post(`${apiV4Url}/surveys/${surveyId}/complete`, data);
}

export function markVideoAsStartedV4(id: number): Promise<void> {
  return axios.post(`${apiV4Url}/videos/${id}/start`);
}

export function markVideoAsWatchedV4(id: number): Promise<void> {
  return axios.post(`${apiV4Url}/videos/${id}/watched`);
}

export function markAssessmentAsStartedV4(assessmentId: number): Promise<void> {
  return axios.post(`${apiV4Url}/assessments/${assessmentId}/start`);
}

export function fetchPdfV4(pdfId: number) {
  return http.get<PdfAssetStandAloneDetails>(`${apiV4Url}/pdfs/${pdfId}`);
}

export function markPdfAsWatchedV4(pdfId: number): Promise<AxiosResponse<void>> {
  return axios.post<void>(`${apiV4Url}/pdfs/${pdfId}/completed`);
}

export async function makeObjectUrl(pdfDocumentPath: string, type = "application/pdf"): Promise<string> {
  abortController.abort();
  abortController = new AbortController();
  const blobData = await fetch(pdfDocumentPath, {
    signal: abortController.signal,
  });
  const res = await blobData.blob();
  const blob = new Blob([res], { type });
  return URL.createObjectURL(blob);
}
