import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { type Event } from "../../types";

interface EventState {
  hasAccess?: boolean;
  status: RStatus;
  current?: Event;
  errorMessage: string | undefined;
}

const initialState: EventState = {
  hasAccess: true,
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
};

const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      return { ...state, status: RStatus.Pending, hasAccess: true };
    },
    got(state, action: PayloadAction<{ current: EventState["current"] }>) {
      return {
        ...state,
        status: RStatus.Got,
        current: action.payload.current,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: EventState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    restricted(state) {
      state.hasAccess = false;
      state.status = RStatus.Got;
    },
  },
});

export const { req, got, err, restricted } = eventSlice.actions;

export const selectEvent = (state: RootState) => state.events.event.current;
export const selectEventStatus = (state: RootState) => state.events.event.status;
export const selectEventError = (state: RootState) => state.events.event.errorMessage;
export const hasAccessSelector = (state: RootState) => state.events.event.hasAccess;

export default eventSlice.reducer;
