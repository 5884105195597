import videojs from "video.js";
import type Player from "video.js/dist/types/player";
import { removeClassIfElementExists } from "../../../VideoPlayer/utils/vjsHelpers";
import SettingsMenuItem from "./SettingsMenuItem";

const MenuButton = videojs.getComponent("MenuButton");
const Menu = videojs.getComponent("Menu");

class SettingsMenuButton extends MenuButton {
  foundEventListener: (event: Event) => void;

  constructor(player: Player, options: Object) {
    super(player, options);
    this.el().setAttribute("aria-label", "Settings Menu");
    this.el().setAttribute("data-testid", "player-settings-menu-button");
    this.el().setAttribute("title", "Settings");

    // Both are necessary to remove hover display for settings menu
    (this as any).menuButton_.off("mouseenter");
    (this as any).menuButton_.off("mouseover");

    (this as any).menuButton_.on("click", this.toggleActiveSettings);
    // This is to close the seconday submenu when you click on the settings button
    (this as any).menuButton_.on("click", () => {
      this.triggerHideSubMenu();
      removeClassIfElementExists(document.getElementsByClassName("open")[0], "open");
    });

    this.foundEventListener = this.clickOff();
  }

  buildCSSClass() {
    return `vjs-settings-button vjs-menu-button settings-menu vjs-control vjs-button ${super.buildCSSClass()}`;
  }

  // Closes menu when clicking outside of the menu content or settings button
  clickOff() {
    const clickOffFunction = (event: Event) => {
      const content: HTMLElement = document.getElementsByTagName("ul")[1];
      const withinBoundaries =
        event.composedPath().includes(content) || event.composedPath().includes((this as any).menuButton_.el());
      if (!withinBoundaries) {
        (this as any).unpressButton();
        this.triggerHideSubMenu();
        removeClassIfElementExists(document.getElementsByClassName("open")[0], "open");
        (this as any).menuButton_.removeClass("active-settings");
      }
    };
    document.addEventListener("click", clickOffFunction);
    return clickOffFunction;
  }

  // dispose is a VideoJS keyword that gets called to clean up event listeners
  dispose() {
    document.removeEventListener("click", this.foundEventListener);
  }

  // This toggles highlighting the settings button
  toggleActiveSettings() {
    const menuButton = document.getElementsByClassName("vjs-settings-button")[0];
    if (menuButton.classList.contains("active-settings")) {
      menuButton.classList.remove("active-settings");
    } else {
      menuButton.classList.add("active-settings");
    }
  }

  // createMenu is a VideoJS keyword that gets called to build the menu
  createMenu(): any {
    const menu = new Menu(this.player());
    menu.el().setAttribute("data-testid", "player-settings-menu");

    // @ts-ignore
    // Primary sub menu item names to be assigned to the elements
    const primarySubMenuItemNames = this.options_.entries;
    if (!primarySubMenuItemNames) {
      return menu;
    }

    const triggerHideSubMenu = videojs.bind(this, this.triggerHideSubMenu);

    for (const primarySubMenuItemName of primarySubMenuItemNames) {
      const primarySubMenuItem = new SettingsMenuItem(this.player(), this.options_, primarySubMenuItemName);

      primarySubMenuItem.el().setAttribute("data-testid", primarySubMenuItemName);
      menu.addChild(primarySubMenuItem);

      primarySubMenuItem.on("click", triggerHideSubMenu);
      primarySubMenuItem.on("click", () => this.toggleOpenClass(primarySubMenuItem.el()));
      primarySubMenuItem.on("touchstart", handleTouchStart.bind(this, primarySubMenuItem));
    }

    function handleTouchStart(primarySubMenuItem: any, e: any) {
      const possibleClassNames = ["sub-menu-root", "vjs-settings-sub-menu-title", "vjs-settings-sub-menu-value"];

      if (possibleClassNames.some(name => e.target.className.includes(name))) {
        for (const parentNodeChildren of e.target.parentNode.childNodes) {
          const childNodes = e.target.childNodes;
          if (
            parentNodeChildren.className.includes("vjs-hidden") ||
            (childNodes.length > 1 && childNodes[2].className.includes("vjs-hidden"))
          ) {
            triggerHideSubMenu();
            break;
          }
        }
      }
      // @ts-ignore
      this.toggleOpenClass(primarySubMenuItem.el());
    }

    return menu;
  }

  // This toggles the open class on primary submenu items (quality/playback)
  toggleOpenClass(primarySubMenuItem: Element) {
    const childOne = (this as any).menu.children_[0];
    const childTwo = (this as any).menu.children_[1];
    if (videojs.dom.hasClass(primarySubMenuItem, "open")) {
      removeClassIfElementExists(primarySubMenuItem, "open");
      removeClassIfElementExists(childOne.el(), "open");
      removeClassIfElementExists(childTwo.el(), "open");
    } else {
      removeClassIfElementExists(childOne.el(), "open");
      removeClassIfElementExists(childTwo.el(), "open");
      videojs.dom.addClass(primarySubMenuItem, "open");
    }
  }

  // Calls hideSubMenu in the settings menu item file
  triggerHideSubMenu() {
    // menuChild is the current primary submenu item (Quality/Playback)
    for (let menuChild of (this as any).menu.children()) {
      // @ts-ignore
      menuChild.hideSubMenu();
    }
  }
}

export default SettingsMenuButton;
