import { isAxiosError } from "axios";
import { fetchVideoAiAnswer, fetchVideoAiQuestions } from "features/Assets/services/assetsService";
import { type VideoQuestions, type VideoQuestionsParsed } from "features/Assets/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { err, errAnswer, got, gotAnswer, req, reqAnswer } from "./videoQuestionsSlice";

export function* handleFetchVideoQuestions(action: ReturnType<typeof req>) {
  try {
    const id = action.payload.id;
    const response: { data: VideoQuestions } = yield call(fetchVideoAiQuestions, id);

    const videoQuestionsList: VideoQuestionsParsed[] = [];
    for (const [minute, questions] of Object.entries(response.data.questions)) {
      // Split the minute into equal time slices
      const timeSlice = Math.floor(60 / questions.length);

      for (let i = 0; i < questions.length; i++) {
        const trimmedQuestion = questions[i].trim();
        // Remove the 1. from the question
        questions[i] = trimmedQuestion.replace(/^\d+\.\s/, "");
        videoQuestionsList.push({
          startTimeSeconds: Number(minute) * 60 + i * timeSlice,
          endTimeSeconds: Number(minute) * 60 + (i + 1) * timeSlice,
          question: questions[i],
        });
      }
    }
    yield put(got({ items: videoQuestionsList }));
  } catch (error: any) {
    // Video has no questions (most likely no vtt file)
    if (isAxiosError(error) && error.status === 400) {
      yield put(got({ items: [] }));
    } else {
      yield put(err({ errorMessage: error?.message }));
    }
  }
}

export function* handleFetchVideoAnswer(action: ReturnType<typeof reqAnswer>) {
  try {
    const { id, questionText } = action.payload;
    const answerResponse: { data: { answer: string } } = yield call(fetchVideoAiAnswer, id, questionText);
    yield put(gotAnswer({ answer: answerResponse.data.answer }));
  } catch (error: any) {
    yield put(errAnswer({ errorMessage: error?.message }));
  }
}

export function* videoQuestionsWatcherSaga() {
  yield takeLatest(req.type, handleFetchVideoQuestions);
  yield takeLatest(reqAnswer.type, handleFetchVideoAnswer);
}
