import { useCallback } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import teamsService, { userInTeams } from "../../../../tools/teams/teamsService";

import styles from "./HoverableLink.module.scss";

interface Props {
  to: string;
  ariaLabel?: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  /** If not defined, will use onMouseEnter */
  onFocus?: () => void;
  /** If not defined, will use onMouseLave */
  onBlur?: () => void;
  children: React.ReactNode;
}

/**
 * Link wrapper to enable custom side effects onMouseHover/Leave and Focus/Blur
 * @param param0
 * @returns
 */
export const HoverableLink: React.FC<Props> = ({
  to,
  ariaLabel,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  children,
}) => {
  const resolver = useResolvedPath(to);

  const openInNewWindow = useCallback(() => {
    const url = new URL(`${window.envtConfig.baseUrl + resolver.pathname}?${teamsService.urlQueryParams}`);
    window.open(url);
  }, [resolver.pathname]);

  if (userInTeams()) {
    return (
      <button
        className={styles.invisible}
        type="button"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role="link"
        onClick={openInNewWindow}
        aria-label={ariaLabel}
      >
        {children}
      </button>
    );
  }

  return (
    <Link
      to={to}
      aria-label={ariaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus ?? onMouseEnter}
      onBlur={onBlur ?? onMouseLeave}
      state={{ prevPage: "/discover" }}
    >
      {children}
    </Link>
  );
};
