import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { type FlowEntity } from "../../../../common/types";
import { getDelayStringComponent } from "../../../helpers/flowHelper";

import styles from "./FlowAssetDelayPlaceholder.module.scss";

export interface Props {
  entityType: FlowEntity;
  time?: string;
}

export const FlowAssetDelayPlaceholder: React.FC<Props> = ({ time, entityType }) => {
  const { t } = useTranslation(["flows"]);

  return (
    <div className={styles.root}>
      <Icon className="clock" fitted />
      <div className={styles.delayPlaceholderDesc}>{getDelayStringComponent(t, entityType, time)}</div>
    </div>
  );
};
