import { useTranslation } from "react-i18next";

import { AppLoader } from "../../../Application/layout";
import { FlowAssetHeader } from "../../../Flows/components/FlowAssetHeader/FlowAssetHeader";
import { type EvaluatedAssessment } from "../../../Flows/types";
import { type AssessmentQuestion, type QuestionsAnswers } from "../../types";
import { AssessmentQuestionForm } from "./AssessmentQuestionForm/AssessmentQuestionForm";
import { AssessmentResults } from "./AssessmentResults/AssessmentResults";

import { type ReactNode, useCallback } from "react";
import styles from "./AssessmentViewer.module.scss";

export interface Props {
  assessment: {
    title: string;
    questionsCount: number;
    questions: AssessmentQuestion[];
  };
  currentQuestion: number;
  onNextQuestion: () => void;
  answers: QuestionsAnswers;
  updateAnswers: (answers: { [x: number]: boolean[] }) => void;
  complete: (data: QuestionsAnswers) => void;
  evaluatedAssessment?: EvaluatedAssessment;
  reset?: boolean;
  children?: React.ReactNode;
}

function getFormattedData(answers: QuestionsAnswers, _: AssessmentQuestion[]) {
  let data = {
    questionsAnswers: {},
  };
  for (let questionOrder in answers) {
    const indices = answers[questionOrder].flatMap((a, idx) => (a ? idx : []));
    data = {
      questionsAnswers: {
        ...data.questionsAnswers,
        [questionOrder]: indices,
      },
    };
  }

  return data;
}

export const AssessmentViewer: React.FC<Props> = ({
  assessment,
  currentQuestion,
  onNextQuestion,
  answers,
  updateAnswers,
  complete,
  evaluatedAssessment,
  reset,
  children,
}) => {
  const { t } = useTranslation("assets");
  const { questions, questionsCount, title } = assessment;
  const question = questions.find(q => q.questionOrder === currentQuestion);

  const onSubmitAnswer = useCallback(
    (answer?: boolean[]) => {
      if (currentQuestion !== null && answer) {
        const newAnswers = { ...answers, [currentQuestion]: answer };
        updateAnswers(newAnswers);
        onNextQuestion();
        if (currentQuestion === questionsCount - 1) {
          complete(getFormattedData(newAnswers, questions));
        }
      }
    },
    [answers, complete, currentQuestion, onNextQuestion, questions, questionsCount, updateAnswers],
  );

  let header: ReactNode,
    body = <AppLoader />;
  if (currentQuestion < questionsCount) {
    header = `${t("assessments.question")} ${currentQuestion + 1} ${t("assessments.of")} ${questionsCount}`;
    body = (
      <AssessmentQuestionForm
        onSubmitAnswer={onSubmitAnswer}
        question={question}
        isLastQuestion={currentQuestion === questionsCount - 1}
        reset={reset}
      />
    );
  } else if (evaluatedAssessment) {
    const { passed, percentage, correctQuestions, totalQuestions, percentageToPass } = evaluatedAssessment;
    const results = evaluatedAssessment?.questions.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.order]: { isCorrect: curr.passed, answers: curr.answers },
      };
    }, {});

    header =
      percentageToPass === 0 ? (
        <span>{`${correctQuestions}/${totalQuestions} - ${percentage}%`}</span>
      ) : (
        <span className={passed ? styles.passed : styles.failed}>{`${
          passed ? t("assessments.youPassed") : t("assessments.youFailed")
        } ${correctQuestions}/${totalQuestions} - ${percentage}%`}</span>
      );

    body = (
      <>
        <AssessmentResults questions={questions} selectedAnswers={answers} results={results} />
        {children}
        {/* renders on the final step after all assessments are completed */}
      </>
    );
  }

  return (
    <div className={styles.root}>
      <FlowAssetHeader title={title}>{header}</FlowAssetHeader>
      {body}
    </div>
  );
};
