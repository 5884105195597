import React, { useCallback, useRef, useState } from "react";
import Dotdotdot, { type DotdotdotProps } from "react-dotdotdot";
import { HTML } from "../../../common/helpers/htmlHelpers";
import useOnResize from "../../hooks/useOnResize";
import { Tooltip } from "../Tooltip/Tooltip";

export interface TextTruncatorProps extends DotdotdotProps {
  tooltipContent: string;
  disableTooltip?: boolean;
}

export const TextTruncator: React.FC<TextTruncatorProps> = props => {
  const { children, tooltipContent, disableTooltip, clamp } = props;

  const tooltipRef = useRef<HTMLDivElement>(null);
  const truncatorRef = useRef<HTMLDivElement>(null);

  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(() => HTML.isTextTruncated(truncatorRef));

  const onResize = useCallback(() => setShouldDisplayTooltip(HTML.isTextTruncated(truncatorRef)), []);
  useOnResize(onResize, tooltipRef);

  const renderTruncateText = () => {
    return (
      <div ref={truncatorRef} aria-label={props["aria-label"]} tabIndex={props["tabIndex"]} data-testid="truncate">
        <Dotdotdot useNativeClamp clamp={clamp || 1}>
          {children}
        </Dotdotdot>
      </div>
    );
  };

  return (
    <div ref={tooltipRef}>
      <Tooltip
        wide
        position={"top center"}
        on={["focus", "hover"]}
        trigger={renderTruncateText()}
        content={tooltipContent}
        disabled={!shouldDisplayTooltip || disableTooltip}
      />
    </div>
  );
};
