import { type FC, memo } from "react";
import cn from "classnames";

import { CompletionStatus } from "../../../../Flows/types/enums";

import styles from "./CardProgressBar.module.scss";

interface Props {
  status: CompletionStatus;
  barStatus: CompletionStatus;
  wideBars?: boolean;
  title?: string;
  index: number;
}

const CardProgressBar: FC<Props> = props => {
  const { index, wideBars, status, barStatus, title } = props;
  const { NotStarted, InProgress, Finished } = CompletionStatus;
  const barClassName = cn({
    [styles.progressBar]: !wideBars,
    [styles.progressBarWide]: wideBars,
    [styles.grey]: status === NotStarted && barStatus === NotStarted,
    [styles.empty]: status !== Finished && barStatus > status,
    [styles.yellow]: status === InProgress && barStatus <= status,
    [styles.green]: status === Finished,
  });

  return (
    <div className={cn(styles.innerProgressContainer)}>
      <div data-testid={`bar${index + 1}`} className={barClassName} />
      <span
        data-testid={`text${index + 1}`}
        className={cn(styles.progressText, {
          [styles.opaque]: status === barStatus,
        })}
      >
        {title}
      </span>
    </div>
  );
};

export default memo(CardProgressBar);
