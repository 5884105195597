import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { SurveyEnd, SurveyViewer } from "..";
import { completeSurveyAsset } from "../../redux/survey/surveySlice";
import { startSurveyV4 } from "../../services/assetsService";
import { type SurveyAnswers, type SurveyAsset as Survey, type SurveyQuestionsAnswers } from "../../types";

import styles from "./SurveyAsset.module.scss";

interface Props {
  survey: Survey;
}

export const SurveyAsset: React.FC<Props> = ({ survey }) => {
  const dispatch = useDispatch();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<SurveyQuestionsAnswers>({});

  useEffect(() => {
    startSurveyV4(survey.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev + 1);
  }, []);

  const prevQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev - 1);
  }, []);

  const completeSurvey = useCallback(
    (data: SurveyAnswers) => {
      dispatch(
        completeSurveyAsset({
          assetId: survey.id,
          data: data,
        }),
      );
    },
    [dispatch, survey.id],
  );

  return (
    <div className={styles.root}>
      <SurveyViewer
        survey={survey}
        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        onPrevQuestion={prevQuestion}
        answers={answers}
        updateAnswers={setAnswers}
        complete={completeSurvey}
      >
        <SurveyEnd endNoteText={survey.endNoteText} />
      </SurveyViewer>
    </div>
  );
};
