import { createAction, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { type Event, type EventAppointment, type GetEventAppointmentRequest } from "../../types";

export interface RedirectState {
  hasAccess?: boolean;
  status: RStatus;
  event?: Event;
  appointment?: EventAppointment | null;
  errorMessage?: string;
}

export const initialState: RedirectState = {
  status: RStatus.Idle,
  hasAccess: true,
};

const name = "event/redirect";
const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<GetEventAppointmentRequest>) {
      state.hasAccess = true;
      state.status = RStatus.Pending;
    },
    got(
      state,
      action: PayloadAction<{
        event: Event;
        appointment: EventAppointment | null;
      }>,
    ) {
      const payload = action.payload;
      state.event = payload.event;
      state.appointment = payload.appointment;
      state.status = RStatus.Got;
    },
    err(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
      state.status = RStatus.Error;
    },
    forbidden(state) {
      state.hasAccess = false;
      state.status = RStatus.Got;
    },
    reset() {
      return initialState;
    },
  },
});

export const { got, req, err, forbidden, reset, track } = {
  ...slice.actions,
  track: createAction<{
    sessionId: string;
    sessionStart: string;
  }>(`${name}/track`),
};

export const statusSelector = (state: RootState) => state.events.redirect.status;

export const eventSelector = (state: RootState) => state.events.redirect.event;

export const appointmentSelector = (state: RootState) => state.events.redirect.appointment;

export const hasAccessSelector = (state: RootState) => state.events.redirect.hasAccess;

export const errorSelector = (state: RootState) => state.events.redirect.errorMessage;

export default slice.reducer;
