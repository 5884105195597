import classNames from "classnames/bind";
import { type MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppLoader } from "../../../Application/layout";

import { SurveyViewer } from "../../../Assets/components";
import { type SurveyAnswers, type SurveyQuestionsAnswers } from "../../../Assets/types";
import { completeSurveyAsset, startTransition } from "../../redux/flow/flowSlice";
import { markSurveyAsStarted } from "../../services/flowsService";

import styles from "./FlowSurvey.module.scss";
import { type FlowSurveyAsset } from "features/Flows/types";

const cx = classNames.bind(styles);

export interface Props {
  order: number;
  survey: FlowSurveyAsset;
  hidden: boolean;
  resetAssetRef?: MutableRefObject<(() => void) | undefined>;
}

export const FlowSurvey: React.FC<Props> = ({ order, survey, hidden, resetAssetRef }) => {
  const { flowId } = useParams();
  const dispatch = useDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<SurveyQuestionsAnswers>({});
  const [reset, setReset] = useState(false);

  const resetSurvey = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setReset(true);
  };

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    markSurveyAsStarted(Number(flowId), survey.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetAssetRef) {
      resetAssetRef.current = resetSurvey;
    }
  }, [resetAssetRef]);

  const nextQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev + 1);
  }, []);

  const prevQuestion = useCallback(() => {
    setCurrentQuestion(prev => prev - 1);
  }, []);

  const completeSurvey = useCallback(
    (data: SurveyAnswers) => {
      dispatch(
        startTransition({
          assetOrder: order,
        }),
      );
      dispatch(
        completeSurveyAsset({
          flowId: Number(flowId),
          surveyId: survey.id,
          data: data,
          assetOrder: order,
        }),
      );
    },
    [survey, flowId, dispatch, order],
  );

  const loader = (
    <div className={styles.loader}>
      <AppLoader />
    </div>
  );

  return (
    <div className={cx(styles.root, { [styles.hidden]: hidden })}>
      <SurveyViewer
        survey={survey}
        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        onPrevQuestion={prevQuestion}
        answers={answers}
        updateAnswers={setAnswers}
        complete={completeSurvey}
        reset={reset}
      >
        {loader}
      </SurveyViewer>
    </div>
  );
};
