import dayjs from "dayjs";
import type i18next from "i18next";
import { type ReactNode } from "react";
import { Trans } from "react-i18next";
import { type Asset } from "../../Assets/types";
import { EntityType, type FlowEntity, FlowEntityType } from "../../common/types";
import { type FlowAssetMeta, FlowStatus } from "../types";

export const getDelayStringComponent = (t: (typeof i18next)["t"], type: FlowEntity, dateTime?: string): ReactNode => {
  // Return the time argument
  if (!dateTime) {
    return t("hints.delayWithoutTime.popup.content");
  }

  if (type === EntityType.Email) {
    return <Trans t={t} i18nKey="hints.delayWithDateAndTime.email" values={getDelayWithDateAndTimeArgs(dateTime)} />;
  }
  return <Trans t={t} i18nKey="hints.delayWithDateAndTime.default" values={getDelayWithDateAndTimeArgs(dateTime)} />;
};

export const getDelayWithDateAndTimeArgs = (dateString: string) => {
  return { date: dayjs(dateString).format("MM/DD/YYYY"), time: dayjs(dateString).format("hh:mm a") };
};

export const isStarted = (status?: FlowStatus): boolean =>
  status === FlowStatus.InProgress || status === FlowStatus.Completed;

export const getAssetKey = (asset: FlowAssetMeta | Asset): string =>
  asset.type === FlowEntityType.UnavailableAsset ? `${asset.originType}:${asset.id}` : `${asset.type}:${asset.id}`;
