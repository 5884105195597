import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { WEEK_LENGTH } from "../../Application/utils/date";
import { type PeriodicRule, PeriodType } from "../types";
import eventSessionUtils from "./eventSessionUtils";

export enum WeekDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export const dayOfWeeks = [
  WeekDays.Sunday,
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
  WeekDays.Saturday,
];

const repetitionOptions = [
  { value: 1, humanized: "" },
  { value: 2, humanized: "other" },
  { value: 3, humanized: "third" },
  { value: 4, humanized: "fourth" },
  { value: 5, humanized: "fifth" },
];

export class RecurrenceDescriptionBuilder {
  periodType: PeriodType;
  periodicRule?: PeriodicRule | null;
  repetitionNumber: number;
  repetition: string;
  startsAt: string;
  startsOn: string;
  endsAt: string;
  endsOn: string;
  timeZone: string;
  weekDays: WeekDays[];
  weekDaysOrder: WeekDays[] = dayOfWeeks;

  constructor() {
    const { value: repetitionNumber, humanized: repetition } = repetitionOptions[0];

    this.startsAt = "";
    this.startsOn = "";
    this.endsAt = "";
    this.endsOn = "";
    this.timeZone = "";
    this.periodicRule = null;
    this.periodType = PeriodType.Day;
    this.repetitionNumber = repetitionNumber;
    this.repetition = repetition;
    this.weekDays = [];
  }

  startingAt(time?: string | null) {
    if (time) {
      this.startsAt = `from ${time}`;
    }
    return this;
  }

  endingAt(time?: string | null) {
    if (time) {
      this.endsAt = `- ${time}`;
    }
    return this;
  }

  usingTimeZone(timeZone?: string | null) {
    if (timeZone) {
      this.timeZone = `${dayjs().format("z")} (${dayjs.tz.guess()})`;
    }
    return this;
  }

  endingOn(date?: string | null) {
    if (date) {
      this.endsOn = dayjs(date).utc().isValid() ? `until ${dayjs(date).utc().format("MMM DD, YYYY")}` : "";
    }
    return this;
  }

  startingOn(date: string) {
    this.startsOn = date;
    return this;
  }

  usingPeriod(periodType: PeriodType) {
    this.periodType = periodType;
    return this;
  }

  usingPeriodicRule(periodicRule?: PeriodicRule | null) {
    this.periodicRule = periodicRule;
    return this;
  }

  withRepetition(number: number) {
    const option = repetitionOptions.find(x => x.value === number);
    if (option) {
      this.repetition = option.humanized;
    }
    return this;
  }

  onWeekDays(weekDays?: WeekDays[] | null) {
    if (weekDays) {
      this.weekDays = weekDays;
    }
    return this;
  }

  build(): string {
    return this.parts.filter(x => !isEmpty(x)).join(" ");
  }

  private get parts(): string[] {
    const { periodPattern, startsAt, endsAt, timeZone, endsOn } = this;

    let parts: string[] = ["Occurs"];
    parts = parts.concat(periodPattern);
    parts.push(startsAt);
    parts.push(endsAt);
    parts.push(timeZone);
    parts.push(endsOn);

    return parts;
  }

  private get periodPattern(): string[] {
    const { repetition, weekDayString, concreteDate } = this;
    switch (this.periodType) {
      case PeriodType.Day:
        return ["every", repetition, "day"];
      case PeriodType.Week:
        if (weekDayString) {
          return ["every", repetition, weekDayString];
        }
        break;
      case PeriodType.Month:
        return [concreteDate, "of every", repetition, "month"];
      case PeriodType.Year:
        return ["every", repetition, "year", concreteDate];
    }
    return [];
  }

  private get concreteDate(): string {
    const { startsOn, periodicRule } = this;

    if (typeof periodicRule !== "number") {
      return "";
    }

    const startDate = dayjs(startsOn).utc().toString();
    return eventSessionUtils.convertPeriodicRuleToString(startDate, periodicRule) ?? "";
  }

  private get weekDayString(): string {
    const { weekDays, weekDaysOrder: order } = this;
    if (isEmpty(weekDays)) {
      return "";
    }

    if (weekDays.length === 1) {
      return weekDays[0];
    }

    if (weekDays.length === WEEK_LENGTH) {
      return "day";
    }

    weekDays.sort((left, right) => order.indexOf(left) - order.indexOf(right));

    const parts = weekDays.slice(0, weekDays.length - 1);
    const comma = parts.length > 1 ? "," : "";
    return `${parts.join(", ")}${comma} and ${weekDays.slice(-1)}`;
  }
}
