import { type RootState } from "features/Application/store/store";

export const getSearchResults = (state: RootState) => state.discover.searchResults;
export const selectSearch = (state: RootState) => state.discover.searchResults.search;
export const selectSearchFilters = (state: RootState) => state.discover.searchResults.search.appliedFilters;
export const selectSearchTerm = (state: RootState) => state.discover.searchResults.search.term;
export const selectSearchResults = (state: RootState) => state.discover.searchResults.items;
export const selectSearchSortType = (state: RootState) => state.discover.searchResults.search.sortType;
export const selectSearchResultsStatus = (state: RootState) => state.discover.searchResults.status;
export const selectSearchResultsError = (state: RootState) => state.discover.searchResults.errorMessage;
export const selectSearchResultsActivePage = (state: RootState) => state.discover.searchResults.activePage;
export const selectSearchResultsReachedEnd = (state: RootState) => state.discover.searchResults.reachedEnd;
export const selectFilterOptions = (state: RootState) => state.discover.searchResults.filterOptions;
