import classNames from "classnames/bind";
import { type ReactNode } from "react";
import { Icon } from "semantic-ui-react";

import styles from "./EmptyState.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  icon?: ReactNode;
  iconClassName?: string;
  title?: ReactNode;
  description?: ReactNode;
  outline?: boolean;
  fullWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
  omitOpacity?: boolean;
}

export const EmptyState: React.FC<Props> = ({
  icon,
  iconClassName,
  title,
  description,
  fullWidth,
  className,
  outline = false,
  children,
  omitOpacity,
}) => {
  const renderIcon = () => {
    if (iconClassName) return <Icon className={cx("fal", styles.noResultsIcon, { outline }, iconClassName)} />;
    if (icon) return icon;
    return null;
  };

  const renderDescription = () => {
    const isString = typeof description === "string";
    return (
      description && (
        <span
          className={cx(styles.description, {
            [styles.markup]: !isString && !omitOpacity,
          })}
        >
          {isString ? <p tabIndex={0}>{description}</p> : description}
        </span>
      )
    );
  };

  const renderTitle = () => {
    return typeof title === "string" ? (
      <h1 tabIndex={0} className={styles.title}>
        {title}
      </h1>
    ) : (
      title
    );
  };

  return (
    <div className={cx(styles.root, className, { fullWidth })} data-testid="empty-state">
      {renderIcon()}
      {renderTitle()}
      {renderDescription()}
      {children}
    </div>
  );
};
