import { call, put, takeLatest } from "redux-saga/effects";
import i18next from "i18next";

import { req, got, err, completeSurveyAsset, restricted } from "./surveySlice";
import { fetchSurveyV4, completeSurveyV4 } from "../../services/assetsService";
import { fetchSurveyQuestionsById } from "../../../Flows/services/flowsService";
import { type SurveyAsset, type SurveyQuestion } from "../../types";
import HttpCodes from "../../../types/HttpCodes";
import { type HttpResponse } from "../../../Application/http/http";

function* handleResult(asset: HttpResponse<SurveyAsset>) {
  if (asset.isSuccess) {
    const questions: SurveyQuestion[] = yield call(fetchSurveyQuestionsById, asset.data!.id);

    yield put(got({ current: { ...asset.data!, questions: questions } }));
  } else if (asset.code === HttpCodes.Forbidden || asset.code === HttpCodes.NotFound) {
    yield put(restricted());
  } else {
    throw new Error(asset.message);
  }
}

export function* handleFetchSurvey(action: ReturnType<typeof req>) {
  try {
    const asset: HttpResponse<SurveyAsset> = yield call(fetchSurveyV4, action.payload.id);
    yield* handleResult(asset);
  } catch (error) {
    yield put(err({ errorMessage: i18next.t("assets:common.surveyNotFound") }));
  }
}

export function* handleSurveyComplete(action: ReturnType<typeof completeSurveyAsset>) {
  try {
    yield call(completeSurveyV4, action.payload.assetId, action.payload.data);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* surveyWatcherSaga() {
  yield takeLatest(req.type, handleFetchSurvey);
  yield takeLatest(completeSurveyAsset.type, handleSurveyComplete);
}
