import classNames from "classnames/bind";
import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { type AssetWithProgressFields, type ProgressRecordBag } from "../../../types";
import { getBagHintsData } from "./bagHintUtils";

import styles from "./FlowProgressBagHint.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  bag: ProgressRecordBag;
  selected: boolean;
  asset: AssetWithProgressFields;
}

export const FlowProgressBagHint: React.FC<Props> = ({ bag, selected, asset }) => {
  const { t } = useTranslation(["flows"]);

  const bagHintsData = getBagHintsData(t, asset.type, bag);
  if (!bagHintsData.length) return null;

  const renderTriggerIcon = (iconClassName: string, isOutlineDisabled?: boolean) => (
    <Icon
      className={cx(
        styles.bagTriggerIcon,
        iconClassName,
        { outline: !selected && !isOutlineDisabled },
        { selected: selected },
      )}
      fitted
    />
  );

  const renderCustomTriggerIcon = (icon: React.ReactNode) => (
    <div
      className={cx(styles.bagTriggerCustomIcon, {
        [styles.selected]: selected,
      })}
    >
      {icon}
    </div>
  );

  return (
    <div className={styles.root}>
      {bagHintsData.map(({ iconClassName, popupContent, isOutlineDisabled, customIcon }) => (
        <Popup
          key={`${asset.id}-${asset.order}-${iconClassName}`}
          content={popupContent}
          position="top center"
          inverted
          trigger={
            customIcon ? renderCustomTriggerIcon(customIcon) : renderTriggerIcon(iconClassName, isOutlineDisabled)
          }
        />
      ))}
    </div>
  );
};
