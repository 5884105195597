import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Icon, Loader } from "semantic-ui-react";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { AppPageWrapper } from "../../../Application/layout";
import { BackTitle } from "../../../Application/layout/BackTitle/BackTitle";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { CompletionStatus } from "../../../Flows/types/enums";
import {
  completePdf,
  hasAccessSelector,
  req,
  reset,
  selectPdf,
  selectPdfError,
  selectPdfStatus,
} from "../../redux/pdf/pdfSlice";
import { makeObjectUrl } from "../../services/assetsService";
import { type PdfAssetStandAloneDetails } from "../../types";
import styles from "./PdfDetailsPage.module.scss";

export const PdfDetailsPage = (): React.ReactElement => {
  const { pdfId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("assets");
  const { state } = useLocation();
  const asset = useSelector(selectPdf);
  const hasAccess = useSelector(hasAccessSelector);
  const status = useSelector(selectPdfStatus);
  const errorMessage = useSelector(selectPdfError);

  const [pdfUrl, setPdfUrl] = useState<string>();
  const [completed, setCompleted] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  useEffect(() => {
    if (pdfId) {
      dispatch(req({ id: +pdfId }));
    }
  }, [dispatch, pdfId]);

  const getData = async (asset: PdfAssetStandAloneDetails) => {
    const results = await makeObjectUrl(asset.pdfDocumentUrl);
    setPdfUrl(results);
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const markAsComplete = () => {
    dispatch(
      completePdf({
        assetId: parseInt(pdfId!),
      }),
    );
    setCompleted(true);
  };

  useEffect(() => {
    const getDetails = async () => {
      if (asset?.title) {
        document.title = asset.title;
        await getData(asset);
      }
    };
    getDetails().catch(e => console.error(e));
  }, [asset]);

  const pdfLoadingFinished = useCallback(() => {
    setPdfLoaded(true);
  }, []);

  return (
    <AppPageWrapper>
      <FetchingLayout status={status} errorMessage={errorMessage}>
        <div className={styles.root}>
          {hasAccess && asset ? (
            <>
              <div className={styles.headerSpacing}>
                <BackTitle to={"/discover"} state={{ prevPage: state }} title={asset.title} />
              </div>
              <section className={styles.content}>
                {!pdfLoaded && <Loader active />}
                {pdfUrl && (
                  <iframe
                    onLoad={pdfLoadingFinished}
                    data-testid="pdfIframe"
                    title="PDF viewer"
                    src={pdfUrl}
                    height="100%"
                    width="100%"
                  />
                )}
              </section>
              {pdfLoaded && (
                <div className={styles.markComplete}>
                  {asset.completionStatus === CompletionStatus.Finished || completed ? (
                    <div>
                      <Icon name="check circle" color="green" />
                      <div>{t("pdfs.completed")}</div>
                    </div>
                  ) : (
                    <button onClick={markAsComplete}>{t("pdfs.markAsComplete")}</button>
                  )}
                </div>
              )}
            </>
          ) : (
            <HttpErrorView code={ErrorType.Forbidden} back />
          )}
        </div>
      </FetchingLayout>
    </AppPageWrapper>
  );
};
