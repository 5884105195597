import { call, put, takeLatest } from "redux-saga/effects";

import { req, got, err, completeAssessmentAsset, restricted } from "./assessmentSlice";
import { fetchAssessmentV4, evaluateAssessmentV4 } from "../../services/assetsService";
import { fetchAssessmentQuestionsById } from "../../../Flows/services/flowsService";
import { type AssessmentAsset, type AssessmentQuestion } from "../../types";
import { type HttpResponse } from "../../../Application/http/http";

import HttpCodes from "../../../types/HttpCodes";

function* handleResult(asset: HttpResponse<AssessmentAsset>) {
  if (asset.isSuccess) {
    const questions: AssessmentQuestion[] = yield call(fetchAssessmentQuestionsById, asset.data!.id);

    yield put(got({ current: { ...asset.data!, questions: questions } }));
  } else if (asset.code === HttpCodes.Forbidden || asset.code === HttpCodes.NotFound) {
    yield put(restricted());
  } else {
    throw new Error(asset.message);
  }
}

export function* handleFetchAssessment(action: ReturnType<typeof req>) {
  try {
    const asset: HttpResponse<AssessmentAsset> = yield call(fetchAssessmentV4, action.payload.id);
    yield* handleResult(asset);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* handleAssessmentEvaluate(action: ReturnType<typeof completeAssessmentAsset>) {
  try {
    yield call(evaluateAssessmentV4, action.payload.assetId, action.payload.data);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* assessmentWatcherSaga() {
  yield takeLatest(req.type, handleFetchAssessment);
  yield takeLatest(completeAssessmentAsset.type, handleAssessmentEvaluate);
}
