import { checkDateIsBeforeNow } from "../../../../Application/utils/date";
import { checkIsProgressRecordDisabled } from "./progressRecordHelpers";
import {
  type FlowCourseItem,
  type ProgressRecord,
  type SectionHeader,
  type ProgressRecordBag,
  ProgressStatus,
  type FlowAssetMeta,
} from "../../../types";
import { FlowEntityType } from "../../../../common/types";

type HeadersMap = {
  [key: string]: SectionHeader;
};

export const checkIsDelayTimeNotExpired = (bag: ProgressRecordBag) => {
  const { isDelayed, availableAt } = bag;
  return isDelayed && (!availableAt || !checkDateIsBeforeNow(availableAt));
};

export const getExtendedProgress = (
  assets: FlowAssetMeta[],
  sectionHeaders: SectionHeader[] = [],
  records: ProgressRecord[] = [],
): FlowCourseItem[] => {
  const coursesMap: FlowCourseItem[] = [];
  let firstRecordWithDelayIndex: number | undefined;

  const setFirstRecordWithDelayIndex = (index: number) => {
    firstRecordWithDelayIndex = index;
  };

  const hMap: HeadersMap = sectionHeaders.reduce((acc: HeadersMap, curr, index) => {
    let r = records.find(el => el.uid === curr.id);
    if (r === undefined && index === 0 && records.length > 0) {
      r = records[0];
    }
    if (r) {
      acc[r.uid] = { ...curr };
    }
    return acc;
  }, {});

  records.forEach((record, index) => {
    if (!firstRecordWithDelayIndex && record.bag.isDelayed && record.status !== ProgressStatus.Completed) {
      setFirstRecordWithDelayIndex(index);
    }

    if (hMap[`${record.uid}`]) {
      coursesMap.push({
        header: hMap[`${record.uid}`],
        items: [],
      });
    }
    if (coursesMap[coursesMap.length - 1]) {
      const a = assets.find(
        asset =>
          (record.id === `${asset.id}` || record.uid === `${asset.id}`) &&
          (record.key === asset.type ||
            (asset.type === FlowEntityType.UnavailableAsset && asset.originType === record.key)),
      );
      if (a !== undefined) {
        coursesMap[coursesMap.length - 1].items.push({
          ...a,
          order: record.order,
          bag: record.bag,
          isDelayTimeNotExpired: checkIsDelayTimeNotExpired(record.bag),
          disabled: checkIsProgressRecordDisabled({
            index,
            firstRecordWithDelayIndex,
            record,
          }),
        });
      }
    }
  });
  return Object.values(coursesMap);
};
