import { type ReactNode, useRef } from "react";
import classnames from "classnames/bind";
import styles from "./Checkbox.module.scss";

const cx = classnames.bind(styles);

export interface Props {
  "aria-label"?: string;
  checked: boolean;
  disabled?: boolean;
  id?: string;
  label?: ReactNode;
  name?: string;
  onChange: () => void;
}

export const Checkbox: React.FC<Props> = props => {
  const { checked, disabled, id, label, name, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const clickHandler = () => {
    if (!disabled) {
      onChange();
    }
  };
  // Space is handled by default
  const keyHandler = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onChange();
    }
  };

  return (
    <div className={styles.root} onClick={clickHandler}>
      <input
        id={id}
        ref={inputRef}
        className={styles.hidden}
        type="checkbox"
        aria-label={props["aria-label"]}
        disabled={disabled}
        checked={checked}
        name={name}
        readOnly
        tabIndex={0}
        onKeyPress={keyHandler}
      />
      <label
        className={cx(styles.label, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};
