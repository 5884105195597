import classNames from "classnames/bind";
import React, { useEffect, useMemo, useRef, useState } from "react";

import styles from "./Flyout.module.scss";

const cx = classNames.bind(styles);

export type Direction = "top" | "right" | "bottom" | "left";

export interface Props {
  open: boolean;
  absoluteSize?: boolean;
  /** Percentage height the flyout will take */
  height: number;
  direction?: Direction;
  children?: React.ReactNode;
  onClosed?: () => void;
  onOpened?: () => void;
}

export const Flyout: React.FC<Props> = ({
  open,
  height,
  absoluteSize = true,
  direction = "bottom",
  children,
  onOpened,
  onClosed,
}) => {
  const [hidden, setHidden] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  // Handling transition visiblity changes
  useEffect(() => {
    const ele = ref.current as HTMLDivElement;

    const startHandler = (e: TransitionEvent) => {
      if (e.target !== ele) {
        return;
      }
      if (ele.classList.contains(styles.opened)) {
        setHidden(false);
      }
    };

    const endHandler = (e: TransitionEvent) => {
      if (e.target !== ele) {
        return;
      }
      if (ele.classList.contains(styles.closed)) {
        setHidden(true);
        onClosed?.();
      } else {
        setHidden(false);
        onOpened?.();
      }
    };

    ele.addEventListener("transitionstart", startHandler);
    ele.addEventListener("transitionend", endHandler);
    return () => {
      ele.removeEventListener("transitionstart", startHandler);
      ele.removeEventListener("transitionend", endHandler);
    };
  }, [onClosed, onOpened]);

  const style: React.CSSProperties = useMemo(() => {
    let baseStyle: React.CSSProperties;
    if (direction === "left" || direction === "right") {
      baseStyle = { height: "100%", bottom: 0 };
      if (absoluteSize) {
        baseStyle.width = `${height}%`;
      } else {
        baseStyle.maxWidth = `${height}%`;
      }
    } else {
      baseStyle = { width: "100%", left: 0 };
      if (absoluteSize) {
        baseStyle.height = `${height}%`;
      } else {
        baseStyle.maxHeight = `${height}%`;
      }
    }
    const distance = `${-Math.round(height)}%`;
    if (open) {
      switch (direction) {
        case "left":
          ref.current?.style.removeProperty("left");
          break;
        case "bottom":
          ref.current?.style.removeProperty("bottom");
          break;
        case "right":
          ref.current?.style.removeProperty("right");
          break;
        case "top":
          ref.current?.style.removeProperty("top");
          break;
      }
      return baseStyle;
    }
    switch (direction) {
      case "top":
        return { ...baseStyle, top: distance };
      case "right":
        return { ...baseStyle, right: distance };
      case "bottom":
        return { ...baseStyle, bottom: distance };
      case "left":
        return { ...baseStyle, left: distance };
    }
  }, [open, direction, height, absoluteSize]);

  return (
    <div
      className={cx(styles.flyout, {
        [styles.left]: direction === "left",
        [styles.right]: direction === "right",
        [styles.bottom]: direction === "bottom",
        [styles.top]: direction === "top",
        // Transition control
        [styles.closed]: !open,
        [styles.hidden]: !open && hidden,
        [styles.opened]: open,
      })}
      data-testid="Flyout Root"
      ref={ref}
      style={style}
    >
      {children}
    </div>
  );
};
