import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../store/store";

interface SignalRState {
  isConnected: boolean;
}

const initialState: SignalRState = { isConnected: false };

const signalRSlice = createSlice({
  name: "signalROperations",
  initialState,
  reducers: {
    setSignalRState(state: SignalRState, payloadAction: PayloadAction<boolean>) {
      state.isConnected = payloadAction.payload;
    },
  },
});

export const { setSignalRState } = signalRSlice.actions;

export type SignalRType = ReturnType<typeof signalRSlice.reducer>;

export const selectSignalRStatus = (state: RootState) => state.signalR.isConnected;

export default signalRSlice.reducer;
