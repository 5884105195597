import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Transition } from "semantic-ui-react";

import defaultImage from "../../../../assets/images/placeholder.png";
import { SwipeableArea, SwipeDirection } from "../../../Application/components/SwipeableArea/SwipeableArea";
import { AppErrorView } from "../../../Application/layout/AppErrorView/AppErrorView";
import { AppLoader } from "../../../Application/layout/AppLoader/AppLoader";
import { InvisibleButton } from "../../../Application/layout/InvisibleButton/InvisibleButton";
import { HighlightedLearningItem } from "../HighlightedLearningItem/HighlightedLearningItem";

import { animationDuration, Direction, initialAnimationState, maxFlows, reducer } from "./HlDefs.d";

import { dateInPast } from "../../../Application/utils/dateTime";
import { type PriorityLevel } from "../../../Discover/types";
import { type HomePageContentItem } from "../../types";
import styles from "./HighlightedLearning.module.scss";

const cx = classNames.bind(styles);

interface Props {
  items: HomePageContentItem[];
  itemsLoading: boolean;
  itemsLoaded: boolean;
  errors: (string | undefined)[];
  noItems: boolean;
}

export const HighlightedLearning = ({ items, itemsLoading, itemsLoaded, errors, noItems }: Props) => {
  const { t } = useTranslation(["home"]);

  const [imageMounted, setImageMounted] = useState<HTMLImageElement | null>(null);
  const [containerMounted, setContainerMounted] = useState<HTMLDivElement | null>(null);

  const highlightedItems = useMemo(
    () =>
      items
        .filter(item => !dateInPast(item?.dueDate))
        .filter((item): item is HomePageContentItem & { priority: PriorityLevel } => Boolean(item))
        .sort((a, b) => b.priority - a.priority)
        .slice(0, maxFlows),
    [items],
  );

  const [animationState, animationDispatch] = useReducer(reducer, initialAnimationState);

  const swipeHandler = useCallback(
    (dir: SwipeDirection) => {
      let payload = { flowCount: highlightedItems.length };

      if (dir === SwipeDirection.Left) {
        animationDispatch({ type: "increment", payload });
      } else if (dir === SwipeDirection.Right) {
        animationDispatch({ type: "decrement", payload });
      }
    },
    [highlightedItems],
  );

  /**
   * Begin dynamic image sizing
   */

  // Need to set the height of the image dynamically to the content size, because
  // the content must be absolutely positioned and won't effect the size of the
  // outer container
  const imageRef = useCallback((node: HTMLImageElement | null) => {
    if (node != null) setImageMounted(node);
  }, []);

  const contentRef = useCallback((node: HTMLDivElement | null) => {
    if (node != null) {
      setContainerMounted(node);
    }
  }, []);

  useEffect(() => {
    if (!containerMounted || !imageMounted) return;
    const obs = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      imageMounted.style.height = `${entries[0].contentRect.height}px`;
    });
    obs.observe(containerMounted);

    imageMounted.style.height = containerMounted.style.height;
    return () => obs.unobserve(containerMounted);
  }, [containerMounted, imageMounted]);

  /**
   * End dynamic image sizing
   */

  const hideComplete = useCallback(() => {
    animationDispatch({ type: "hidecomplete" });
  }, []);

  const increment = useCallback(() => {
    animationDispatch({
      type: "increment",
      payload: { flowCount: highlightedItems.length },
    });
  }, [highlightedItems]);

  const decrement = useCallback(() => {
    animationDispatch({
      type: "decrement",
      payload: { flowCount: highlightedItems.length },
    });
  }, [highlightedItems]);

  return (
    <SwipeableArea handler={swipeHandler}>
      <div className={cx(styles.root, { [styles.hidden]: noItems || !highlightedItems.length })}>
        {itemsLoading && <AppLoader />}
        {errors?.map(error => <AppErrorView key={error} message={error} />)}
        {itemsLoaded && (
          <>
            {highlightedItems?.map((item, idx) => (
              <Transition
                key={`Hero image ${item.id}`}
                visible={animationState.activeFlow === idx}
                animation={animationState.animationType}
                duration={animationDuration}
                unmountOnHide
                onHide={hideComplete}
              >
                <img
                  src={item?.thumbnailUrl ?? defaultImage}
                  className={styles.img}
                  alt={t("highlightedLearning.background")}
                  ref={imageRef}
                />
              </Transition>
            ))}
            <div
              className={cx(styles.overlay, {
                [styles.darkened]: animationState.direction !== Direction.Unset,
              })}
            />
            <div className={styles.container} ref={contentRef}>
              <div className={styles.header}>
                <span className={styles.title} tabIndex={0}>
                  {t("common.highlightedLearning")}
                </span>
                <span className={styles.right}>
                  <div className={styles.rightContainer}>
                    <span className={styles.count} tabIndex={0}>
                      <span data-testid="activeflow">{animationState.activeFlowContent + 1}</span>
                      {` ${t("common.of")} `}
                      <span data-testid="maxflows">{highlightedItems.length}</span>
                    </span>
                    <span className={styles.leftIcon}>
                      <InvisibleButton
                        onClick={decrement}
                        ariaLabel={t("highlightedLearning.previousItem")}
                        disabled={highlightedItems.length <= 1}
                      >
                        <Icon
                          className={cx("outline", styles.iconColor)}
                          name="angle left"
                          fitted
                          size="large"
                          disabled={highlightedItems.length <= 1}
                          data-testid="previous icon"
                        />
                      </InvisibleButton>
                    </span>
                    <span className={styles.rightIcon}>
                      <InvisibleButton
                        onClick={increment}
                        ariaLabel={t("highlightedLearning.nextItem")}
                        disabled={highlightedItems.length <= 1}
                      >
                        <Icon
                          className={cx("outline", styles.iconColor)}
                          name="angle right"
                          fitted
                          size="large"
                          disabled={highlightedItems.length <= 1}
                          data-testid="next icon"
                        />
                      </InvisibleButton>
                    </span>
                  </div>
                </span>
              </div>
              {highlightedItems?.map((item, idx) => (
                <Transition
                  key={`Hero content ${item.id}`}
                  visible={animationState.activeFlow === idx}
                  animation={animationState.animationType}
                  duration={animationDuration}
                  unmountOnHide
                  onHide={hideComplete}
                >
                  <div className={styles.body}>
                    <HighlightedLearningItem highlightedItem={item} />
                  </div>
                </Transition>
              ))}
            </div>
          </>
        )}
      </div>
    </SwipeableArea>
  );
};
