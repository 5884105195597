import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../store/store";

export interface BannerState {
  open: boolean;
}

const initialState: BannerState = {
  open: false,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<{ open: boolean }>) {
      state.open = action.payload.open;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { setOpen, reset } = bannerSlice.actions;

// selectors
export const selectBannerOpen = (state: RootState) => state.application.banner.open;

export default bannerSlice.reducer;
