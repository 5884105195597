import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { ContentIcon } from "../ContentIcon/ContentIcon";

import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { BreakpointValues } from "../../../Application/hooks/breakpointValues";
import { getFormattedDate } from "../../../Application/utils/date";
import { EntityType } from "../../../common/types";

import classNames from "classnames/bind";
import defaultImage from "../../../../assets/images/placeholder.png";
import { type HomePageContentItem } from "../../types";
import styles from "./HighlightedLearningItem.module.scss";
import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";

const DueIcon = (dueText: string, date: Date) => (
  <div className={styles.dueIcon}>
    <span className={styles.dueIconWrapper}>
      <Icon className={styles.exclamationIcon} name="exclamation" fitted style={{ width: "unset" }} />
    </span>
    <strong>
      {` ${dueText.toLocaleUpperCase()} `}
      {getFormattedDate(date)}
    </strong>
  </div>
);

const cx = classNames.bind(styles);
interface Props {
  highlightedItem: HomePageContentItem;
}

interface ContentProps {
  highlightedItem: HomePageContentItem;
  meta: string[];
}

const Content: React.FC<ContentProps> = ({ highlightedItem, meta }) => {
  return (
    <div className={cx(styles.content, { [styles.expiredContent]: highlightedItem.expired })}>
      <div className={styles.contentContainer}>
        <ContentIcon entityType={highlightedItem.entityType} background />
        <div className={styles.rightBorder}>
          <h2 className={styles.flowTitle} tabIndex={0}>
            <TextTruncator clamp={1} tooltipContent={highlightedItem.title}>
              {highlightedItem.title}
            </TextTruncator>
          </h2>
          <Description description={highlightedItem.description ?? ""} />
        </div>
        <div className={styles.bottomContainer}>
          <HighlightedLearningBottom>{Meta({ id: highlightedItem.id, values: meta })}</HighlightedLearningBottom>
        </div>
      </div>
    </div>
  );
};

export const HighlightedLearningItem: React.FC<Props> = ({ highlightedItem }) => {
  const { t } = useTranslation(["home"]);
  let startFlowButtonText = "";
  switch (highlightedItem.entityType) {
    case "Survey":
      startFlowButtonText = t("highlightedLearning.startSurvey");
      break;
    case "Video":
      startFlowButtonText = t("highlightedLearning.startVideo");
      break;
    case "ExternalEvent":
      startFlowButtonText = t("highlightedLearning.startEvent");
      break;
    case "Assessment":
      startFlowButtonText = t("highlightedLearning.startAssessment");
      break;
    case "Pdf":
      startFlowButtonText = t("highlightedLearning.viewPdf");
      break;
    default:
      if (highlightedItem.completionStatus === 1) {
        startFlowButtonText = t("highlightedLearning.resumeFlow");
      } else {
        startFlowButtonText = t("highlightedLearning.startFlow");
      }
  }

  const { breakpoint, isLargeScreen } = useContext(BreakpointContext);

  // Required to not violate hook rules since these include hooks not used in
  // the desktop/tablet views
  const MobileContentIcon = <ContentIcon entityType={highlightedItem.entityType} background />;
  const MobileDueIcon = highlightedItem.dueDate ? DueIcon(t("common.due"), new Date(highlightedItem.dueDate)) : null;

  const meta = useMemo(() => {
    return highlightedItem.applications as string[];
  }, [highlightedItem]);

  if (isLargeScreen || breakpoint === BreakpointValues.tabletLandscape) {
    return (
      <div className={styles.bodyContainer}>
        <ImageElement {...highlightedItem} dueString={t("common.due")} />
        <Content highlightedItem={highlightedItem} meta={meta} />
        <StartFlowButton item={highlightedItem} text={startFlowButtonText} />
      </div>
    );
  } else if (breakpoint === BreakpointValues.tabletPortrait) {
    // Removes button from the bodyContainer
    return (
      <>
        <div className={styles.bodyContainer}>
          <ImageElement {...highlightedItem} dueString={t("common.due")} />
          <Content highlightedItem={highlightedItem} meta={meta} />
        </div>
        <StartFlowButton item={highlightedItem} text={startFlowButtonText} />
      </>
    );
  } else {
    return (
      <>
        <div className={styles.bodyContainer}>
          <div className={styles.content}>
            <div className={cx(styles.contentContainer, { [styles.expiredContainer]: highlightedItem.expired })}>
              <div className={styles.mobileIconContainer}>
                {MobileContentIcon}
                {MobileDueIcon}
              </div>
              {highlightedItem.expired ? (
                <>
                  <h2 className={styles.flowTitle} tabIndex={0}>
                    {highlightedItem.title}
                  </h2>

                  <div className={styles.expiredBackGround}>
                    <div className={styles.expiredWarning}>
                      <div className={styles.expiredWarningTitle}>
                        <Icon className="exclamation triangle" />
                        <p>Content Unavailable</p>
                      </div>
                      <div className={styles.expiredWarningDescription}>
                        <p>Contact your administrator to restore your access to the content in this Pack.</p>
                      </div>
                    </div>
                    <div className={styles.backgroundPlaceholder} />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.rightBorder}>
                    <h2 className={styles.flowTitle} tabIndex={0}>
                      {highlightedItem.title}
                    </h2>
                    <Description description={highlightedItem.description ?? ""} />
                  </div>
                  <div className={styles.bottomContainer}>
                    <HighlightedLearningBottom>
                      {Meta({ id: highlightedItem.id, values: meta })}
                    </HighlightedLearningBottom>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <StartFlowButton item={highlightedItem} text={startFlowButtonText} />
      </>
    );
  }
};

interface HighlightedLearningBottomProps {
  children: React.ReactNode[];
}
// This should eventually be replaced by the CardMeta component, but I need to remove some styles from it
// and move them into Horizontal/Vertical card before
const HighlightedLearningBottom: React.FC<HighlightedLearningBottomProps> = ({ children }) => {
  return (
    <>
      {children?.map((child, idx) => (
        <Fragment key={`Highlighted Learning bottom ${child}`}>
          {child}
          {idx !== children.length - 1 && <IconSpacer />}
        </Fragment>
      ))}
    </>
  );
};

const IconSpacer = () => (
  <span className={styles.iconContainer} data-testid="spacer">
    <span>
      <Icon name="circle" size="mini" fitted />
    </span>
  </span>
);

// Used to not duplicate elements for jest tests and SonarCloud
const ImageElement = ({
  thumbnailUrl,
  title,
  expired,
  dueString,
  dueDate,
}: {
  title: string;
  expired: boolean;
  dueString: string;
  thumbnailUrl?: string;
  dueDate?: string | null;
}) => {
  return (
    <div
      className={cx(styles.imageContainer, {
        [styles.expiredImage]: expired,
      })}
    >
      {expired && (
        <div className={styles.expiredWarning}>
          <div className={styles.expiredWarningTitle}>
            <Icon className="exclamation triangle" />
            <p>Content Unavailable</p>
          </div>
          <div className={styles.expiredWarningDescription}>
            <p>Contact your administrator to restore your access to the content in this Pack.</p>
          </div>
        </div>
      )}
      <img className={styles.image} src={thumbnailUrl ?? defaultImage} alt={title} />
      {dueDate && DueIcon(dueString, new Date(dueDate))}
    </div>
  );
};

const Description = ({ description }: { description: string }) => {
  return (
    <p className={styles.description} tabIndex={description ? 0 : -1}>
      <TextTruncator clamp={5} tooltipContent={description}>
        {description}
      </TextTruncator>
    </p>
  );
};

const Meta = ({ id, values }: { id: number; values: string[] }) => {
  return values?.map((tag, idx) => (
    <span tabIndex={0} key={`Meta for ${id} ${tag}`}>
      {tag.toLocaleUpperCase()}
    </span>
  ));
};

const StartFlowButton = ({ item, text }: { item: HomePageContentItem; text: string }) => {
  return (
    <div className={styles.buttonContainer}>
      {item.expired ? (
        <div className={styles.expiredButton}>
          <button className={styles.startButton}>{text}</button>
        </div>
      ) : (
        <Link to={getCourseLink(item)} state={{ prevPage: "/home" }} tabIndex={-1} data-testid="course-link">
          <button className={styles.startButton}>{text}</button>
        </Link>
      )}
    </div>
  );
};

const getCourseLink = (item: HomePageContentItem): string => {
  switch (item.entityType) {
    case EntityType.Assessment: {
      return `/discover/assessment/${item.id}`;
    }
    case EntityType.Event: {
      return `/discover/event/${item.id}`;
    }
    case EntityType.Survey: {
      return `/discover/survey/${item.id}`;
    }
    case EntityType.Flow: {
      return `/discover/flows/${item.id}`;
    }
    case EntityType.Video: {
      return `/discover/asset/${item.id}`;
    }
    case EntityType.Pdf: {
      return `/discover/pdf/${item.id}`;
    }
    default: {
      throw new Error("Unknown entity type.");
    }
  }
};
