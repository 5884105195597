import classNames from "classnames/bind";
import { type ReactNode, useMemo } from "react";
import { Placeholder, PlaceholderLine, PlaceholderParagraph } from "semantic-ui-react";
import { InvisibleButton } from "../../../layout/InvisibleButton/InvisibleButton";
import { TextTruncator } from "../../../layout/TextTruncator/TextTruncator";
import { TeamsLink } from "../../TeamsLink/TeamsLink";
import styles from "./CardContent.module.scss";

const cx = classNames.bind(styles);

interface Props {
  title?: string;
  description?: string | null;
  body?: ReactNode;
  link?: string;
  /* Action to perform on title select. If link is set, action will be ignored. */
  action?: () => void;
  hideAccessible?: boolean;
  interactive?: boolean;
  loading?: boolean;
}

export const CardContent: React.FC<Props> = ({
  title,
  link,
  description,
  body,
  action,
  hideAccessible,
  loading,
  interactive = true,
}) => {
  const TitleElement = useMemo(() => {
    const ttl = title ?? "";
    const titleContent = (
      <TextTruncator tooltipContent={ttl} clamp={1} tabIndex={hideAccessible ? -1 : 0} aria-label={ttl}>
        <h2
          className={cx(styles.title, {
            [styles.interactive]: interactive,
          })}
        >
          {ttl}
        </h2>
      </TextTruncator>
    );
    if (!interactive) {
      return titleContent;
    } else if (link) {
      return (
        <TeamsLink to={link} state={{ prevPage: "/discover" }} className={styles.link} accessibleName={title!}>
          {titleContent}
        </TeamsLink>
      );
    } else {
      return (
        <InvisibleButton onClick={action} disabled={hideAccessible} tabIndex={-1}>
          {titleContent}
        </InvisibleButton>
      );
    }
  }, [title, hideAccessible, interactive, link, action]);

  const desc = description ?? "";

  const content = loading ? (
    <Placeholder>
      <PlaceholderParagraph>
        <PlaceholderLine length="medium" />
        <PlaceholderLine length="very long" />
        <PlaceholderLine length="very long" />
      </PlaceholderParagraph>
    </Placeholder>
  ) : (
    <>
      {title && TitleElement}
      <div
        className={cx(styles.description, {
          [styles.marginBottom]: description,
        })}
      >
        <TextTruncator
          tooltipContent={desc}
          clamp={2}
          tabIndex={description && !hideAccessible ? 0 : -1}
          aria-label={desc}
        >
          <div style={{ whiteSpace: "pre-line" }}>{desc}</div>
        </TextTruncator>
      </div>
      {body}
    </>
  );

  return <div>{content}</div>;
};
