import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import styles from "./ContentIcon.module.scss";
import { useMemo } from "react";
import { EntityType } from "../../../common/types";
import { ReactComponent as Course } from "../../../../assets/images/course.svg";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);
interface ContentIconPros {
  entityType: EntityType;
  background?: boolean;
}

export const ContentIcon: React.FC<ContentIconPros> = ({ entityType, background }) => {
  const { t } = useTranslation(["discover"]);

  const [iconClass, iconText] = useMemo(() => toHomePageContentIconMetadata(entityType), [entityType]);

  return (
    <div className={cx(styles.type, { [styles.background]: background })} tabIndex={0}>
      {entityType === EntityType.Flow ? <Course className={styles.courseIcon} /> : <Icon className={iconClass} />}
      <span className={styles.iconText} data-testid="content-icon-span">
        {t(iconText).toLocaleUpperCase()}
      </span>
    </div>
  );
};

function toHomePageContentIconMetadata(entityType: EntityType) {
  switch (entityType) {
    case EntityType.Event:
      return ["calendar day outline", "results.event"];
    case EntityType.Group:
      return ["users outline", "results.group"];
    case EntityType.Video:
      return ["play outline", "results.video"];
    case EntityType.Pdf:
      return ["file pdf outline", "results.pdf"];
    case EntityType.Assessment:
      return ["list ol", "results.assessment"];
    case EntityType.Survey:
      return ["list ul", "results.survey"];
    case EntityType.Flow:
      return ["code branch", "results.flow"];
    default:
      return ["code branch", "results.flow"];
  }
}
