import classNames from "classnames/bind";
import styles from "./CardHeader.module.scss";
import { Placeholder, PlaceholderHeader, PlaceholderLine } from "semantic-ui-react";

const cx = classNames.bind(styles);

interface Props {
  className?: boolean;
  children: React.ReactNode;
  loading?: boolean;
}

export const CardHeader: React.FC<Props> = ({ children, className, loading }) => {
  return (
    <div className={cx(styles.header, className)}>
      {loading ? (
        <div className={cx(styles.placeholder)}>
          <Placeholder fluid>
            <PlaceholderHeader>
              <PlaceholderLine length="short" />
            </PlaceholderHeader>
          </Placeholder>
        </div>
      ) : (
        children
      )}
    </div>
  );
};
