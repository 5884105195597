import { createContext } from "react";
import useBreakpoint, { type Breakpoints, BreakpointValues } from "../../hooks/useBreakpoint";

export interface IBreakpointContext {
  breakpoint: Breakpoints;
  isLargeScreen: boolean;
  isTabletScreen: boolean;
  isTabletPortraitScreen: boolean;
  isTabletLandscapeScreen: boolean;
  isMobileScreen: boolean;
}

export const BreakpointContextExamples = {
  small: {
    breakpoint: BreakpointValues.small,
    isLargeScreen: false,
    isTabletScreen: false,
    isTabletPortraitScreen: false,
    isTabletLandscapeScreen: false,
    isMobileScreen: true,
  },
  tabletPortrait: {
    breakpoint: BreakpointValues.tabletPortrait,
    isLargeScreen: false,
    isTabletScreen: true,
    isTabletPortraitScreen: true,
    isTabletLandscapeScreen: false,
    isMobileScreen: false,
  },
  tabletLandscape: {
    breakpoint: BreakpointValues.tabletLandscape,
    isLargeScreen: false,
    isTabletScreen: true,
    isTabletPortraitScreen: false,
    isTabletLandscapeScreen: true,
    isMobileScreen: false,
  },
  large: {
    breakpoint: BreakpointValues.large,
    isLargeScreen: true,
    isTabletScreen: false,
    isTabletPortraitScreen: false,
    isTabletLandscapeScreen: false,
    isMobileScreen: false,
  },
};

export const BreakpointContext = createContext<IBreakpointContext>({
  breakpoint: BreakpointValues.large,
  isLargeScreen: true,
  isTabletScreen: false,
  isTabletPortraitScreen: false,
  isTabletLandscapeScreen: false,
  isMobileScreen: false,
});

interface Props {
  children: React.ReactNode;
}

export const BreakpointContextWrapper: React.FC<Props> = ({ children }) => {
  const bp = useBreakpoint();
  return <BreakpointContext.Provider value={bp}>{children}</BreakpointContext.Provider>;
};
