import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { QuestionForm } from "../..";
import { type SurveyQuestion as Question, type SurveyQuestionAnswer } from "../../../types";
import { SurveyQuestion } from "../SurveyQuestion/SurveyQuestion";
import { Button } from "../../../../Application/layout";

export interface Props {
  question: Question;
  answer?: SurveyQuestionAnswer;
  prevQuestion: () => void;
  nextQuestion: () => void;
  submitAnswer: (a: SurveyQuestionAnswer) => void;
  submitLastAnswer: (a: SurveyQuestionAnswer) => void;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  questionOrder: number;
  reset?: boolean;
}

export const SurveyQuestionForm: React.FC<Props> = ({
  question,
  answer,
  prevQuestion,
  nextQuestion,
  submitAnswer,
  submitLastAnswer,
  isFirstQuestion,
  isLastQuestion,
  questionOrder,
  reset,
}) => {
  const { t } = useTranslation("assets");
  const [selectedAnswers, setSelectedAnswers] = useState<boolean[]>([]);
  const [otherText, setOtherText] = useState<string | null>(null);

  useEffect(() => {
    if (answer) {
      setSelectedAnswers(answer?.selected);
      setOtherText(answer?.otherText);
    }
  }, [answer]);

  useEffect(() => {
    if (reset) {
      setSelectedAnswers?.([]);
    }
  }, [reset]);

  const onPrevQuestion = useCallback(() => {
    prevQuestion();
    submitAnswer({ selected: selectedAnswers, otherText });
    setSelectedAnswers([]);
    setOtherText(null);
  }, [otherText, prevQuestion, selectedAnswers, submitAnswer]);

  const onNextQuestion = useCallback(() => {
    nextQuestion();
    const submit = isLastQuestion ? submitLastAnswer : submitAnswer;
    submit({ selected: selectedAnswers, otherText });
    setSelectedAnswers([]);
    setOtherText(null);
  }, [isLastQuestion, nextQuestion, otherText, selectedAnswers, submitAnswer, submitLastAnswer]);

  const isNextDisabled =
    (question?.isRequired && !selectedAnswers.some(a => a)) ||
    (!!selectedAnswers[question.answers.length] && question.otherAnswerOptions.isUserTextFieldRequired && !otherText);

  return (
    <QuestionForm
      question={
        <SurveyQuestion
          question={question}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          otherText={otherText}
          setOtherText={setOtherText}
          questionOrder={questionOrder}
        />
      }
      buttons={
        <>
          {!isFirstQuestion && <Button onClick={onPrevQuestion}>{t("assessments.previous")}</Button>}
          <Button onClick={onNextQuestion} disabled={isNextDisabled}>
            {isLastQuestion ? t("assessments.finish") : t("assessments.next")}
          </Button>
        </>
      }
    />
  );
};
