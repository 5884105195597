import { type Channel } from "redux-saga";
import { actionChannel, call, fork, take } from "redux-saga/effects";
import { type ActionCreatorWithPayload } from "@reduxjs/toolkit";

export const takeEveryWithQueue = <T, A extends string = string>(
  actionCreator: ActionCreatorWithPayload<T, A>,
  worker: (action: ReturnType<typeof actionCreator>) => void,
) =>
  fork(function* () {
    const requestChan: Channel<ReturnType<typeof actionCreator>> = yield actionChannel(actionCreator.type);

    while (true) {
      const action: ReturnType<typeof actionCreator> = yield take(requestChan);
      yield call(worker, action);
    }
  });
