import classNames from "classnames/bind";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Loader } from "semantic-ui-react";

import { AccountCompany } from "../../../Account/components/AccountCompany/AccountCompany";
import { selectCurrentProfile, selectProfileStatus } from "../../../Account/redux/profile/profileSlice";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { RStatus } from "../../../Application/types";
import { type Priority, useProgressBarsConfig } from "../../hooks/useProgressBarsConfig";
import { req } from "../../redux/homePageContent/homePageContentSlice";
import { HighlightedLearning } from "../HighlightedLearning/HighlightedLearning";
import { ProgressBarsSection } from "../ProgressBarsSection/ProgressBarsSection";
import { selectHomePageState } from "features/Home/redux/homePageContent/selectors";
import { UpcomingEvents } from "../UpcomingEvents/UpcomingEvents";

import { Button } from "../../../Application/layout";
import { useHomeItems } from "../../hooks/useHomeItems";

import { userInTeams } from "../../../../tools/teams/teamsService";
import styles from "./HomePage.module.scss";

const cx = classNames.bind(styles);

export const HomePage: React.FC = () => {
  const { t: appTranslation } = useTranslation(["application"]);
  const { t: homeTranslation } = useTranslation("home");

  const { isMobileScreen } = useContext(BreakpointContext);

  const dispatch = useDispatch();

  const profile = useSelector(selectCurrentProfile);
  const status = useSelector(selectProfileStatus);
  const homeState = useSelector(selectHomePageState);
  const itemsProvider = useMemo(() => {
    return (priority: Priority) => {
      return homeState.items[priority];
    };
  }, [homeState.items]);

  const progressBarsConfig = useProgressBarsConfig(itemsProvider, isMobileScreen, styles.progressBarsSection);

  const homeItems = useHomeItems(homeState);

  const [items, isLoading, isLoaded, noItems, errors] = homeItems;

  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    document.title = "Home";
    SendPageView();
  }, []);

  useEffect(() => {
    dispatch(req());
  }, [dispatch]);

  useEffect(() => {
    const handler = () => {
      const header = headerRef.current;
      if (header) {
        if (window.scrollY > 0 && !header.classList.contains(styles.shadow)) {
          header.classList.add(styles.shadow);
        } else if (window.scrollY === 0) {
          header.classList.remove(styles.shadow);
        }
      }
    };

    window.addEventListener("scroll", handler);

    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <React.StrictMode>
      <div className={styles.root}>
        <section className={styles.stickyHeader} ref={headerRef}>
          {status !== RStatus.Pending && (
            <div
              className={cx(styles.homePageWrapper, styles.header, {
                [styles.teamsHeader]: userInTeams(),
              })}
              data-testid="home header"
            >
              <h1 className={styles.title}>{`${appTranslation("common.welcomeWithComma")} ${profile?.firstName}!`}</h1>
              <div className={styles.company}>
                <AccountCompany companyClassName={styles.companyName} simple={isMobileScreen} useSmallerCompanyIcon />
              </div>
            </div>
          )}
        </section>

        <section className={styles.content}>
          {isLoading && (
            <section className={cx(styles.homePageWrapper, styles.emptyWrapper)}>
              <Loader active inline />
            </section>
          )}

          {!isLoading && noItems ? (
            <>
              <section className={cx(styles.homePageWrapper, styles.emptyWrapper)}>
                <EmptyState
                  icon={
                    <span className={styles.iconWrapper}>
                      <Icon name="handshake outline" size="large" className={styles.iconColor} />
                    </span>
                  }
                  title={homeTranslation("emptyState.title")}
                  description={<p className={styles.emptyDescription}>{homeTranslation("emptyState.paragraph")}</p>}
                >
                  <Link to="/discover" className={styles.fullWidth}>
                    <Button fullWidth>{appTranslation("mainNavigation.discover")}</Button>
                  </Link>
                </EmptyState>
              </section>
            </>
          ) : (
            <>
              {!userInTeams() && (
                <section className={styles.highlightedLearningWrapper}>
                  <HighlightedLearning
                    items={items}
                    itemsLoaded={isLoaded}
                    errors={errors}
                    itemsLoading={isLoading}
                    noItems={noItems}
                  />
                </section>
              )}

              <section className={styles.homePageWrapper}>
                <div className={styles.progressBarsWrapper}>
                  {progressBarsConfig.map(item => (
                    <ProgressBarsSection
                      title={item.title}
                      priorityLevel={item.priorityLevel}
                      showBorder={item.showBorder}
                      className={item.className}
                      items={item.items}
                      key={`progressLvl-${item.title}`}
                    />
                  ))}
                </div>
              </section>
            </>
          )}

          <section className={styles.homePageWrapper}>
            <UpcomingEvents />
          </section>
        </section>
      </div>
    </React.StrictMode>
  );
};
