import { call, put, takeLatest } from "redux-saga/effects";
import { err, got, req } from "./myDataSlice";
import { fetchMyData } from "../../services/myData.service";
import { type MyData } from "../../types";

export function* handleFetchMyData() {
  try {
    const myData: MyData = yield call(fetchMyData);
    yield put(got({ myData }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* myDataWatcherSaga() {
  yield takeLatest(req.type, handleFetchMyData);
}
