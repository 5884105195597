import axios from "axios";
import { serializeArrayAndFilterNullable } from "features/common/helpers/queryHelpers";
import { type Application } from "../types";

export async function fetchApplications(entityTypes: string[] | undefined): Promise<Application[]> {
  const resp = await axios.get<{ applications: Application[] }>("/api/client/applications", {
    paramsSerializer: serializeArrayAndFilterNullable,
    params: {
      entityType: entityTypes,
    },
  });
  return resp.data["applications"];
}
