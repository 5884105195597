import axios from "axios";
import { serializeArrayAndFilterNullable } from "features/common/helpers/queryHelpers";
import { type Publisher } from "../types";

export async function fetchPublishers(): Promise<Publisher[]> {
  const resp = await axios.get<Publisher[]>("/api/flows/publishers");
  return resp.data;
}

export async function fetchPublisherFilters(entityTypes: string[] | undefined): Promise<Publisher[]> {
  const resp = await axios.get<{ publishers: Publisher[] }>("/api/client/publishers", {
    paramsSerializer: serializeArrayAndFilterNullable,
    params: {
      entityType: entityTypes,
    },
  });
  return resp.data["publishers"];
}
