import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { type EvaluatedAssessment } from "../../../Flows/types";
import { type AssessmentAsset, type QuestionsAnswers } from "../../types";

export enum AssessmentStep {
  Taking,
  Grading,
  Scored,
}

interface AssessmentState {
  hasAccess?: boolean;
  status: RStatus;
  current?: AssessmentAsset;
  currentStep: AssessmentStep;
  errorMessage: string | undefined;
  evaluatedAssessment?: EvaluatedAssessment;
}

const initialState: AssessmentState = {
  hasAccess: true,
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
  currentStep: AssessmentStep.Taking,
  evaluatedAssessment: undefined,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    req(state, _: PayloadAction<{ id: number }>) {
      return { ...state, status: RStatus.Pending, hasAccess: true };
    },
    got(state, action: PayloadAction<{ current: AssessmentState["current"] }>) {
      return {
        ...state,
        status: RStatus.Got,
        current: action.payload.current,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: AssessmentState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    completeAssessmentAsset(
      state,
      _: PayloadAction<{
        assetId: number;
        data: QuestionsAnswers;
      }>,
    ) {
      state.currentStep = AssessmentStep.Grading;
    },
    setEvaluatedAssessment(state, action: PayloadAction<EvaluatedAssessment>) {
      // Prevents identical assessment in another tab affecting this one
      if (state.currentStep !== AssessmentStep.Grading) return state;
      // Prevents a different assessment in another tab affecting this one
      if (action.payload.id !== state.current?.id) {
        return state;
      }
      return {
        ...state,
        evaluatedAssessment: action.payload,
        currentStep: AssessmentStep.Scored,
      };
    },
    reset() {
      return initialState;
    },
    resetEvaluatedAssessment(state) {
      return {
        ...state,
        evaluatedAssessment: initialState.evaluatedAssessment,
      };
    },
    restricted(state) {
      state.hasAccess = false;
      state.status = RStatus.Got;
    },
  },
});

// actions
export const {
  req,
  got,
  err,
  completeAssessmentAsset,
  setEvaluatedAssessment,
  reset,
  resetEvaluatedAssessment,
  restricted,
} = assessmentSlice.actions;

// selectors
export const selectAssessment = (state: RootState) => state.assets.assessment.current;
export const selectAssessmentStatus = (state: RootState) => state.assets.assessment.status;
export const selectAssessmentError = (state: RootState) => state.assets.assessment.errorMessage;
export const selectEvaluatedAssessment = (state: RootState) => state.assets.assessment.evaluatedAssessment;
export const selectAssessmentCurrentStep = (state: RootState) => state.assets.assessment.currentStep;
export const hasAccessSelector = (state: RootState) => state.assets.assessment.hasAccess;

// reducers
export default assessmentSlice.reducer;
