import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import { Modal } from "../../../../Discover/layout/Modal/Modal";

import { useDispatch, useSelector } from "react-redux";
import { RStatus } from "../../../../Application/types";
import {
  req,
  selectMainContact,
  selectMainContactError,
  selectMainContactStatus,
} from "../../../redux/mainContact/mainContactSlice";
import styles from "./DeleteMyDataModal.module.scss";
import { faCircleExclamation, faTimes } from "@fortawesome/pro-solid-svg-icons";

export interface Props {
  open: boolean;
  setOpen: (b: boolean) => void;
}

export const DeleteMyDataModal = ({ open, setOpen }: Props) => {
  const { t } = useTranslation("account");
  const dispatch = useDispatch();
  const value = useSelector(selectMainContact),
    status = useSelector(selectMainContactStatus),
    error = useSelector(selectMainContactError);

  const close = useCallback(() => setOpen(false), [setOpen]);

  const shouldSendRequest = !value && !error;

  useEffect(() => {
    if (open && shouldSendRequest) {
      dispatch(req());
    }
  }, [dispatch, open, shouldSendRequest]);

  const modalBody = useMemo(() => {
    if (status === RStatus.Pending) {
      return (
        <div className={styles.loadContainer}>
          <Loader active />
        </div>
      );
    }

    // Conditionally render job title if available
    const adminInfoString = `${[`${value?.firstName} ${value?.lastName}`, value?.title].filter(i => !!i).join(", ")}.`;

    return (
      <>
        <span>{t("myData.modal.pleaseContact")}</span>
        {status === RStatus.Got ? (
          <div className={styles.admin}>
            <span className={styles.info}>{adminInfoString}</span>
            <a href={`mailto:${value?.email}`}>{value?.email}</a>
          </div>
        ) : (
          <div className={styles.centeredError} data-testid="deleteDataError">
            <FontAwesomeIcon className={styles.icon} icon={faCircleExclamation} />
            <span>
              <Trans t={t} i18nKey={"myData.modal.failedRequest"} />
            </span>
          </div>
        )}
        <span>
          {/* Trans required to render the "strong" tag */}
          <Trans t={t} i18nKey={"myData.modal.important"} />
        </span>
      </>
    );
  }, [status, t, value]);

  return (
    <Modal isOpen={open} handleClose={close}>
      <div className={styles.root}>
        <div className={styles.heading}>
          <h2>{t("myData.requestDeleteHeading")}</h2>
          <FontAwesomeIcon data-testid="close modal" icon={faTimes} onClick={close} scale={"2"} />
        </div>
        <hr />
        {modalBody}
      </div>
    </Modal>
  );
};
