import { call, put, takeLatest } from "redux-saga/effects";
import { req, got, err, restricted } from "./assetSlice";
import { fetchAssetV4 } from "../../services/assetsService";
import { type VideoPreviewAsset } from "../../types";
import { type HttpResponse } from "../../../Application/http/http";
import { EntityType } from "../../../common/types";
import HttpCodes from "../../../types/HttpCodes";

function* handleResult(asset: HttpResponse<VideoPreviewAsset>) {
  if (asset.isSuccess) {
    const result: VideoPreviewAsset = {
      ...asset.data!,
      type: EntityType.Video,
    };
    yield put(got({ current: result }));
  } else if (asset.code === HttpCodes.Forbidden || asset.code === HttpCodes.NotFound) {
    yield put(restricted());
  } else {
    throw new Error(asset.message);
  }
}

export function* handleFetchAsset(action: ReturnType<typeof req>) {
  try {
    const asset: HttpResponse<VideoPreviewAsset> = yield call(fetchAssetV4, action.payload.id);
    yield* handleResult(asset);
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* assetWatcherSaga() {
  yield takeLatest(req.type, handleFetchAsset);
}
