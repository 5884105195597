import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { EntityType } from "features/common/types";
import {
  type DiscoveryFilterName,
  type DiscoveryFiltersOptions,
  getEntityTypeFilters,
  getPriorityFilters,
} from "features/Discover/config/filters";
import { RStatus } from "../../../Application/types";
import { type DiscoveryItem, type Filter, type FilterSlice, FilterType, SortType } from "../../types";

export interface Search {
  appliedFilters: FilterSlice;
  sortType: SortType;
  term: string | undefined;
}

export interface SearchResultsState {
  searchInitialized: boolean;
  search: Search;
  filterOptions: DiscoveryFiltersOptions;
  status: RStatus;
  items: DiscoveryItem[];
  errorMessage: string | undefined;
  top: number;
  activePage: number;
  reachedEnd: boolean;
}

export const initialState: SearchResultsState = {
  search: {
    term: undefined,
    appliedFilters: {},
    sortType: SortType.Relevant,
  },
  filterOptions: {
    entityType: getEntityTypeFilters(getPriorityFilters(true)),
  },
  searchInitialized: false,
  items: [],
  status: RStatus.Idle,
  errorMessage: undefined,
  top: 50,
  activePage: 1,
  reachedEnd: false,
};

const isPrioritiesDisabled = (search: Search | DeepPartial<Search>) => {
  const selected = search?.appliedFilters?.["entityType"];
  return !!search.term || selected === undefined || selected.length !== 1 || selected[0] !== EntityType.Flow;
};

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState: initialState,
  reducers: {
    gotFilters(state, action: PayloadAction<{ key: DiscoveryFilterName; options: Filter }>) {
      state.filterOptions[action.payload.key] = action.payload.options;
    },
    req(state) {
      state.reachedEnd = false;
      state.items = [];
      state.status = RStatus.Pending;
    },
    got(state, action: PayloadAction<{ items: SearchResultsState["items"] }>) {
      return { ...state, status: RStatus.Got, items: action.payload.items };
    },
    fetchMore(state) {
      if (state.reachedEnd) return state;
      return {
        ...state,
        status: RStatus.Pending,
        activePage: state.activePage + 1,
      };
    },
    updateStatus(state, action: PayloadAction<{ status: RStatus }>) {
      return {
        ...state,
        status: action.payload.status,
      };
    },
    updateSort(state, action: PayloadAction<{ value: SortType }>) {
      state.search.sortType = action.payload.value;
      state.activePage = initialState.activePage;
    },
    updateTerm(state, action: PayloadAction<string>) {
      state.search.term = action.payload;
      state.activePage = initialState.activePage;
    },
    resetFilter(state, action: PayloadAction<Filter>) {
      delete state.search.appliedFilters[action.payload.key];
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    resetFilters(state) {
      state.search.appliedFilters = {};
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    updateFilters(state, action: PayloadAction<{ filter: Filter; newValue?: any }>) {
      const { filter, newValue } = action.payload;

      const mapFilters = () => {
        if (filter.type === FilterType.CheckboxArray) {
          const filtered = filter.items.filter(f => newValue?.includes(f.value));
          return filtered.map(x => x.value);
        }
        return [];
      };

      const dependentFilters = filter.dependentFilters?.map(
        x => state.filterOptions[x as DiscoveryFilterName] as Filter,
      );
      dependentFilters?.forEach(f => delete state.search.appliedFilters[f.key]);

      state.search.appliedFilters[filter.key] = mapFilters();
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
      state.filterOptions.entityType = getEntityTypeFilters(getPriorityFilters(isPrioritiesDisabled(state.search)));
    },
    clearFilters(state) {
      state.search = initialState.search;
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    outOfResults(state) {
      state.reachedEnd = true;
    },
    err(
      state,
      action: PayloadAction<{
        errorMessage: SearchResultsState["errorMessage"];
      }>,
    ) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    initSearch(state, action: PayloadAction<{ search: Search }>) {
      state.search = { ...state.search, ...action.payload.search }; // NOSONAR
      state.filterOptions.entityType = getEntityTypeFilters(getPriorityFilters(isPrioritiesDisabled(state.search)));
      state.searchInitialized = true;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const {
  req,
  got,
  fetchMore,
  updateStatus,
  updateSort,
  updateFilters,
  clearFilters,
  outOfResults,
  err,
  reset,
  initSearch,
  updateTerm,
  resetFilter,
  resetFilters,
  gotFilters,
} = searchResultsSlice.actions;

// reducers
export default searchResultsSlice.reducer;
