import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { type Account } from "../../types";

interface AccountState {
  status: RStatus;
  current: Account | undefined;
  errorMessage: string | undefined;
}

const initialState: AccountState = {
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
};

const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ current: AccountState["current"] }>) {
      return { ...state, status: RStatus.Got, current: action.payload.current };
    },
    err(state, action: PayloadAction<{ errorMessage: AccountState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

// actions
export const { req, got, err } = accountSlice.actions;

// selectors
export const selectAccount = (state: RootState) => state.account.account.current;
export const selectAccountStatus = (state: RootState) => state.account.account.status;
export const selectAccountError = (state: RootState) => state.account.account.errorMessage;

// reducers
export default accountSlice.reducer;
