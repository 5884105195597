import { BackLink } from "../BackLink/BackLink";
import { TextTruncator } from "../TextTruncator/TextTruncator";

import styles from "./BackTitle.module.scss";

export interface Props {
  to: string;
  state?: any;
  title: string;
}

export const BackTitle: React.FC<Props> = ({ to, title, state }) => {
  return (
    <div className={styles.root}>
      <BackLink to={to} state={state} />
      <h1 className={styles.title}>
        <TextTruncator clamp={2} tooltipContent={title}>
          {title}
        </TextTruncator>
      </h1>
    </div>
  );
};
