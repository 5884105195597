import { EntityType } from "../../common/types";
import {
  type Application,
  type Filter,
  type FilterNames,
  FilterType,
  PriorityLevel,
  type Publisher,
  asFilter,
} from "../types";

export const getPriorityFilters = (disabled: boolean) =>
  asFilter({
    key: "priority",
    disabled: disabled,
    type: FilterType.CheckboxArray,
    items: [
      {
        id: "required",
        value: PriorityLevel.Required,
      },
      {
        id: "important",
        value: PriorityLevel.Important,
      },
      {
        id: "normal",
        value: PriorityLevel.Normal,
      },
    ],
  });

export const getApplicationsFilters = (applications: Application[]) =>
  asFilter({
    key: "application",
    type: FilterType.CheckboxArray,
    items: applications
      .map(app => ({
        id: app.name,
        value: app.name,
      }))
      .sort((a, b) => a.id.localeCompare(b.id)),
  });

export const getPublishersFilters = (publishers: Publisher[]) =>
  asFilter({
    key: "publisher",
    type: FilterType.CheckboxArray,
    items: publishers
      .map(pub => ({
        id: pub.name,
        value: pub.id,
      }))
      .sort((a, b) => a.id.localeCompare(b.id)),
  });

export const getEntityTypeFilters = (priorityFilters: ReturnType<typeof getPriorityFilters>) =>
  asFilter({
    key: "entityType",
    dependentFilters: ["application", "publisher"],
    type: FilterType.CheckboxArray,
    items: [
      {
        id: "flows",
        value: EntityType.Flow,
        subfilter: asFilter(priorityFilters),
      },
      {
        id: "events",
        value: EntityType.Event,
      },
      {
        id: "videos",
        value: EntityType.Video,
      },
      {
        id: "surveys",
        value: EntityType.Survey,
      },
      {
        id: "assessments",
        value: EntityType.Assessment,
      },
      {
        id: "PDFs",
        value: EntityType.Pdf,
      },
    ],
  });

export type DiscoveryFilterName = FilterNames<
  | ReturnType<typeof getEntityTypeFilters>
  | ReturnType<typeof getApplicationsFilters>
  | ReturnType<typeof getPublishersFilters>
>;

export type DiscoveryFiltersOptions = { [key in DiscoveryFilterName]?: Filter };
