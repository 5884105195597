import { PRIVACY_POLICY_AGREEMENT_KEY } from "features/Account/types";
import { useAuth } from "oidc-react";
import { useCallback } from "react";
import { type useLocation } from "react-router-dom";

export const useSignout = () => {
  const auth = useAuth();

  const signOut = useCallback(
    (location: ReturnType<typeof useLocation>) => {
      localStorage.removeItem(PRIVACY_POLICY_AGREEMENT_KEY);
      return auth.signOutRedirect({ state: location });
    },
    [auth],
  );

  return [signOut];
};

export default useSignout;
