import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { chunk } from "lodash";
import classNames from "classnames/bind";

import { CardsRow } from "./CardsRow/CardsRow";

import { CarouselHeader } from "./CarouselHeader/CarouselHeader";

import styles from "./Carousel.module.scss";
import { SwipeableArea, SwipeDirection } from "../../../Application/components/SwipeableArea/SwipeableArea";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { BreakpointValues } from "../../../Application/hooks/breakpointValues";
import useOnResize from "features/Application/hooks/useOnResize";

export interface Props<T> {
  title: string;
  items: T[];
  renderCard: (item: T) => React.ReactNode;
}

const CARD_AND_PADDING_WIDTH = 293;

export const Carousel = <T,>(props: Props<T>) => {
  const { title, items, renderCard } = props;
  const cx = classNames.bind(styles);
  const bp = useContext(BreakpointContext);
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setCurrentIndex(0);
  }, [bp.breakpoint]);

  useOnResize(() => {
    setHeight(ref.current?.getBoundingClientRect().height ?? 0);
  }, ref);

  const [currentIndex, setCurrentIndex] = useState(0);

  const getRowSize = useCallback(() => {
    switch (bp.breakpoint) {
      case BreakpointValues.tabletLandscape:
        return 3;
      case BreakpointValues.tabletPortrait:
        return 2;
      case BreakpointValues.small:
        return 1;
      default:
        return 4;
    }
  }, [bp.breakpoint]);

  useEffect(() => {
    const current = ref.current as HTMLDivElement;
    current.style.left = `-${currentIndex * getRowSize() * CARD_AND_PADDING_WIDTH}px`;
  }, [currentIndex, getRowSize]);

  const goToNext = useCallback(() => {
    setCurrentIndex(c => c + 1);
  }, []);

  const goToPrevious = useCallback(() => {
    setCurrentIndex(c => c - 1);
  }, []);

  let rowSize = getRowSize();

  const getPages = (allItems: T[]) => {
    return chunk(allItems, rowSize);
  };
  const pages = getPages(items);

  const swipeHandler = useCallback(
    (direction: SwipeDirection) => {
      if (direction === SwipeDirection.Left && currentIndex < pages.length - 1) {
        goToNext();
      } else if (direction === SwipeDirection.Right && currentIndex > 0) {
        goToPrevious();
      }
    },
    [currentIndex, pages.length, goToNext, goToPrevious],
  );

  return (
    <div className={cx(styles.root, styles[`rowSize${rowSize}`])} data-testid="carousel">
      <CarouselHeader
        title={title}
        onPrevious={goToPrevious}
        onNext={goToNext}
        canPrev={currentIndex > 0}
        canNext={currentIndex < pages.length - 1}
      />
      <SwipeableArea handler={swipeHandler}>
        <div
          className={cx(styles.pageWindow, {
            [styles.lastPageActive]: currentIndex === pages.length - 1,
          })}
          data-testid="carousel-page-window"
          style={{ height }}
        >
          <div className={cx(styles.carouselPage, styles.activePage)} ref={ref}>
            <CardsRow items={items} rowSize={rowSize} currentIndex={currentIndex} renderCard={renderCard} />
          </div>
          <div className={styles.fadeOutGradient} />
        </div>
      </SwipeableArea>
    </div>
  );
};
