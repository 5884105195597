import classNames from "classnames/bind";
import { type NavigationLink } from "./AppNavigation";

import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import styles from "./AppNavigation.module.scss";

const cx = classNames.bind(styles);

export interface Props {
  link: NavigationLink;
  disabled?: boolean;
}

export const AppNavigationLink: React.FC<Props> = ({ link, disabled }) => {
  const handleActiveLink = useCallback(
    ({ isActive }: { isActive: boolean }) => (isActive ? cx("active", styles.navigationItem) : styles.navigationItem),
    [],
  );
  const text = (
    <span
      className={cx(styles.linkText, {
        [styles.disabledText]: disabled,
      })}
    >
      {link.label}
    </span>
  );

  if (disabled) {
    return <div className={styles.navigationItem}>{text}</div>;
  }

  return (
    <NavLink to={link.to} className={handleActiveLink}>
      {text}
    </NavLink>
  );
};
