import React, { useContext } from "react";
import { Popup, type PopupProps } from "semantic-ui-react";
import { BreakpointContext } from "../../components/context/BreakpointContext";

export const Tooltip: React.FC<PopupProps> = props => {
  const { position, ...rest } = props;
  const breakpoint = useContext(BreakpointContext);

  return (
    <Popup
      hideOnScroll
      inverted
      data-testid="tooltip"
      // Mobile does not work well with the hover on enum. After activating a popup, scrolling, then activating the next popup does not mouseleave the first one, causing the second one to not open until you click on the screen elsewhere.
      on={breakpoint.isLargeScreen ? ["focus", "hover"] : ["focus"]}
      position={position ?? undefined}
      {...rest}
    />
  );
};
