import classNames from "classnames/bind";
import {
  clearAnswer,
  reqAnswer,
  selectVisibleQuestion,
} from "features/Assets/redux/videoQuestions/videoQuestionsSlice";
import { useState, type SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { VideoDetailsQuestions } from "./AiQuestions/VideoDetailsQuestions";
import styles from "./VideoDetails.module.scss";
import { useFeatureFlag } from "features/common/hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { VideoDetailsAnswer } from "./AiQuestions/VideoDetailsAnswer";

const cx = classNames.bind(styles);

export interface Props {
  title: string;
  description: string;
  id: number;
}

type Tabs = "overview" | "relatedContent";

const defaultActiveTab: Tabs = "overview";

interface TabContent {
  [key: string]: React.ReactNode;
}

const tabContent = ({ title, description }: Props): TabContent => ({
  overview: (
    <div id="panel-1" role="tabpanel" tabIndex={0} aria-labelledby="tab-1">
      <VideoDetailsAnswer />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  ),
  resources: <div id="panel-2" role="tabpanel" tabIndex={0} aria-labelledby="tab-2" />,
});

export const VideoDetails: React.FC<Props> = ({ title, description, id }) => {
  const { t } = useTranslation(["flows"]);
  const [activeTab, setActiveTab] = useState<Tabs>(defaultActiveTab);
  const dispatch = useDispatch();
  const answeredQuestion = useSelector(selectVisibleQuestion);

  const videoQuestionsEnabled = useFeatureFlag(FeatureFlags.AiVideoQuestions);

  const onTabClick = (e: SyntheticEvent) => {
    const tab = e.currentTarget.getAttribute("data-name") as Tabs;
    setActiveTab(tab || defaultActiveTab);
  };

  /* Accessibility stuff */
  const dismissAnswer = () => dispatch(clearAnswer());

  const onQuestionBubbleClick = (question: string) => {
    if (question !== answeredQuestion) {
      dispatch(reqAnswer({ id, questionText: question }));
    } else {
      dismissAnswer();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabs} role="tablist">
        <div
          role="tab"
          tabIndex={0}
          aria-selected={activeTab === "overview"}
          aria-controls="panel-1"
          id="tab-1"
          data-name="overview"
          className={cx(styles.tab, {
            [styles.active]: activeTab === "overview",
          })}
          onClick={onTabClick}
        >
          {t("overview")}
        </div>
        {/* Display possible AI question here */}
        {videoQuestionsEnabled && <VideoDetailsQuestions id={id} onBubbleClick={onQuestionBubbleClick} />}
      </div>
      <section>{tabContent({ title, description, id })[activeTab]}</section>
    </div>
  );
};
