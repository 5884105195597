import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RStatus } from "../../../Application/types";
import { PriorityLevel } from "../../../Discover/types";
import { type HomePageContentItem } from "../../types";

export type Priority = Exclude<PriorityLevel, PriorityLevel.None>;
export type ItemsByPriorityMap = {
  [key in Priority]: HomePageContentItem[];
};

export interface ContentState {
  items: ItemsByPriorityMap;
  status: RStatus;
  errorMessage?: string;
}

export const initialState: ContentState = {
  items: {
    [PriorityLevel.Normal]: [],
    [PriorityLevel.Important]: [],
    [PriorityLevel.Required]: [],
  },
  status: RStatus.Idle,
  errorMessage: undefined,
};

const homePageContentSlice = createSlice({
  name: "homeContent",
  initialState: initialState,
  reducers: {
    req(state) {
      state.status = RStatus.Pending;
    },
    got(state, action: PayloadAction<ItemsByPriorityMap>) {
      state.status = RStatus.Got;
      state.items = action.payload;
    },
    err(state, action: PayloadAction<string>) {
      state.status = RStatus.Error;
      state.errorMessage = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, err, reset } = homePageContentSlice.actions;

// reducers
export default homePageContentSlice.reducer;
