import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { EntityType } from "features/common/types";
import { formatSessionDateTimeToLocalTime } from "features/Events/components/EventViewer/helpers/sessionDateFormatter";
import { fetchUpcomingEvents, type UpcomingEventResponse } from "features/Home/services/homePageDataService";
import { call, put, takeLatest } from "redux-saga/effects";
import { err, got, req } from "./homeUpcomingEventsSlice";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat); // For formatting timezone (e.g. "PDT") in the date string

export function* fetchHomeUpcomingEvents() {
  try {
    const events: UpcomingEventResponse[] = yield call(fetchUpcomingEvents, 9);
    const tz = dayjs.tz.guess();
    yield put(
      got(
        events.map((event: UpcomingEventResponse) => {
          return {
            ...event,
            entityType: EntityType.UpcomingEvent,
            description: formatSessionDateTimeToLocalTime(event.startDateTimeUtc, event.endDateTimeUtc, tz, true),
          };
        }),
      ),
    );
  } catch (error: any) {
    yield put(err(error?.message));
  }
}

export function* homeUpcomingEventsWatcher() {
  yield takeLatest(req.type, fetchHomeUpcomingEvents);
}
