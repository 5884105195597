import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { ReactComponent as Calendar } from "../../../../assets/images/calendar-clock.svg";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { BackTitle } from "../../../Application/layout/BackTitle/BackTitle";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { isTicksBefore, ticksToMoment } from "../../../Application/utils/date";
import { queryStringService } from "../../../Application/utils/queryStringService";
import {
  appointmentSelector,
  errorSelector,
  eventSelector,
  hasAccessSelector,
  req,
  reset,
  statusSelector,
  track,
} from "../../redux/event/redirectSlice";
import { type EventAppointment, type GetEventAppointmentRequest } from "../../types";
import { EventInfo } from "../EventViewer/EventInfo/EventInfo";
import styles from "./RedirectToExternalEvent.module.scss";
import { doesSpanMultipleDays } from "../EventViewer/helpers/dateUtil";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const formatSessionDateTimeToLocalTime = (
  startDateTimeUtc: string,
  endDateTimeUtc: string,
  timeZone: string,
): string => {
  const start = dayjs.utc(startDateTimeUtc).tz(timeZone);
  const end = dayjs.utc(endDateTimeUtc).tz(timeZone);
  if (doesSpanMultipleDays(start, end)) {
    return `${start.format("dddd, LL, LT")} - ${end.format("dddd, LL, LT")}`;
  }
  return `${start.format("dddd, LL")}, from \n${start.format("LT")} to ${end.format("LT")} (${start.format("z")})`;
};

export const RedirectToExternalEvent = () => {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [isEventOver, setIsEventOver] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation("events");

  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const event = useSelector(eventSelector);
  const hasAccess = useSelector(hasAccessSelector);
  const appointment = useSelector(appointmentSelector);
  const error = useSelector(errorSelector);

  const sessionInfo = useMemo(() => {
    const request = queryStringService.parse<GetEventAppointmentRequest>(searchParams.toString());
    return { ...request, eventId: Number(eventId) };
  }, [eventId, searchParams]);

  const { sessionId, sessionStartDate } = sessionInfo;

  useEffect(() => {
    dispatch(req({ ...sessionInfo }));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, sessionInfo]);

  useEffect(() => {
    if (!appointment || !eventId) return;

    // This handles the case when the event is scheduled to end on the next day.
    if (appointment.startsAt > appointment.endsAt) {
      const minutesInDay = 1440;
      appointment.endsAt += minutesInDay;
    }

    const isEventOver = isTicksBefore(
      formatDate(sessionStartDate, appointment.endsAt, appointment.timeZone),
      appointment.timeZone,
    );
    if (isEventOver) {
      setIsEventOver(true);
      return;
    }

    initRedirect(appointment, sessionStartDate, () =>
      dispatch(
        track({
          sessionId: sessionId,
          sessionStart: sessionStartDate,
        }),
      ),
    );
  }, [appointment, dispatch, eventId, sessionId, sessionStartDate]);

  const description = useMemo(() => {
    if (!appointment) {
      return undefined;
    }

    const timeRange = formatSessionDateTimeToLocalTime(
      formatDate(sessionStartDate, appointment?.startsAt, appointment?.timeZone),
      formatDate(sessionStartDate, appointment?.endsAt, appointment?.timeZone),
      dayjs.tz.guess(),
    );

    if (isEventOver) {
      if (event?.recurrenceSession) {
        return (
          <>
            <Trans t={t} i18nKey="common.sessionOverParagraph" values={{ date: timeRange }}>
              This session took place on <div className={styles.timeRange}>{timeRange}</div>.
            </Trans>
            <div className={styles.moreSessions}>{t("common.eventMoreSessions")}</div>
            <button
              className={styles.moreSessionsButton}
              onClick={e => {
                e.stopPropagation();
                return navigate(`/discover/event/${event.id}`);
              }}
            >
              {t("common.eventViewSessions")}
            </button>
          </>
        );
      }
      return (
        <Trans t={t} i18nKey="common.eventOverParagraph" values={{ date: timeRange }}>
          This event took place on <div className={styles.timeRange}>{timeRange}.</div> Thank you for your interest!
        </Trans>
      );
    }

    return (
      <>
        <Trans t={t} i18nKey="common.sessionBeginParagraph" values={{ date: timeRange }}>
          This session will begin on <div className={styles.timeRange}>{timeRange}.</div> Please check back then!
        </Trans>
        <br />
        {event?.recurrenceSession && (
          <button
            className={styles.moreSessionsButton}
            onClick={e => {
              e.stopPropagation();
              return navigate(`/discover/event/${event.id}`);
            }}
          >
            {t("common.eventViewMoreSessions")}
          </button>
        )}
      </>
    );
  }, [appointment, sessionStartDate, isEventOver, event?.recurrenceSession, event?.id, navigate, t]);

  const title = useMemo(() => {
    if (event?.recurrenceSession && isEventOver) {
      return t("common.sessionOver");
    }
    if (isEventOver) {
      return t("common.eventOver");
    }
    return t("common.notStarted");
  }, [isEventOver, event?.recurrenceSession, t]);

  return (
    <FixedHeightAppPageWrapper>
      <div className={styles.root}>
        <FetchingLayout status={status} errorMessage={error}>
          <BackTitle
            to={event?.recurrenceSession ? `/discover/event/${event?.id}` : "/discover"}
            title={event?.title ?? ""}
          />
          <Divider fitted />
          <div className={styles.content}>
            <div className={styles.eventInfo}>
              {hasAccess ? (
                <>
                  {event && (
                    <>
                      <EventInfo {...event} />
                      <Divider fitted />
                    </>
                  )}
                  <EmptyState
                    icon={<Calendar className={styles.emptyStateIcon} />}
                    title={title}
                    description={description}
                    className={styles.notStarted}
                    fullWidth
                    omitOpacity
                  />
                </>
              ) : (
                <HttpErrorView code={ErrorType.Forbidden} back />
              )}
            </div>
          </div>
        </FetchingLayout>
      </div>
    </FixedHeightAppPageWrapper>
  );
};

function initRedirect(appointment: EventAppointment, startDate: string, onRedirect: () => void) {
  const diff = ticksToMoment(formatDate(startDate, appointment.startsAt, appointment.timeZone), appointment.timeZone);
  const maxDelay = 2147483647; // max value for setTimeout
  if (diff > maxDelay) return;
  setTimeout(onRedirect, diff);
}

function formatDate(date: string, minutes: number, tz = "UTC"): string {
  return dayjs(dayjs(date).startOf("d").format("LL")).startOf("day").tz(tz, true).add(minutes, "m").toString();
}
