import useScrollPoint from "../../../Application/hooks/useScrollPoint";
import { LazyLoading } from "../../../Application/layout";
import { type RStatus } from "../../../Application/types";
import { type GroupMember as Member } from "../../types";
import { GroupMember as GroupMembersListItem } from "../GroupMember/GroupMember";

import defaultUserAvatar from "../../../../assets/images/default-user-avatar.png";

import { useCallback } from "react";
import styles from "./GroupMembersList.module.scss";

export interface Props {
  status: RStatus;
  reachedEnd: boolean;
  error?: string;
  fetchMore: () => void;
  members?: Member[];
  isGroupPrivate?: boolean;
}

export const GroupMembersList: React.FC<Props> = ({
  status,
  reachedEnd,
  error,
  fetchMore,
  members,
  isGroupPrivate,
}) => {
  const node = useScrollPoint(fetchMore);

  const renderGroup = useCallback(
    (member: Member) => {
      return (
        <GroupMembersListItem
          key={member.id}
          thumbnailUrl={member?.imageUrl || defaultUserAvatar} // NOSONAR
          name={`${member.firstName} ${member.lastName}`}
          jobTitle={member.title}
          isPrivate={isGroupPrivate}
        />
      );
    },
    [isGroupPrivate],
  );

  return (
    <div className={styles.root}>
      <LazyLoading
        content={members}
        status={status}
        reachedEnd={reachedEnd}
        scrollContainer={node}
        errorMessage={error}
        renderItem={renderGroup}
      />
    </div>
  );
};
