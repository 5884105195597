import { type z } from "zod";
import type { AssessmentQuestion, ClosedCaptions, SurveyQuestion, VideoStreamManifest } from "../../Assets/types";
import { type EntityType, type FlowEntityType, type FlowEntity } from "../../common/types";
import { type ContentCore } from "../../Discover/types";
import { type CompletionStatus } from "./enums";
import { type sectionHeaderSchema, type flowSchema, type flowEndInfoSchema } from "./schemas";
import type HttpCodes from "../../types/HttpCodes";
import { type ErrorTypes } from "../../types/ErrorTypes";
import { type RecurrenceSession } from "features/Events/types";

export interface FlowEntityCore extends ContentCore {
  id: number;
}
export type SectionHeader = z.infer<typeof sectionHeaderSchema>;
export type FlowEndInfo = z.infer<typeof flowEndInfoSchema>;
export type Flow = z.infer<typeof flowSchema>;

export interface FlowDefinition {
  flowItems: FlowItem[];
  flowTriggers: FlowTrigger[];
}

export enum FlowStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
}

export interface Progress {
  accountId: number;
  flowId: string;
  userId: string;
  timestamp: string;
  records: ProgressRecord[];
  status: FlowStatus;
}

export interface ProgressRecordBag {
  isDelayed: boolean;
  isBranched: boolean;
  availableAt?: string;
}

export interface ProgressRecord {
  id: string;
  key: FlowEntity;
  order: number;
  resolution: string | null;
  status: ProgressStatus;
  uid: string;
  bag: ProgressRecordBag;
}

export enum ProgressStatus {
  Next = 0,
  Active = 1,
  Transition = 2,
  Completed = 3,
}

export interface FlowItem {
  id: string;
  entityId: string;
  entityType: FlowEntity;
  x: number;
  y: number;
}

export interface FlowTrigger {
  inId: string;
  outId: string;
  typeId: number;
  timeSpan: number;
  timeUnitId: number;
}

export interface FlowDetails {
  flow: Flow;
  progress: Progress;
}

export interface FlowProgress {
  selectedAssetIndex: number;
  viewedAssetIndexes: number[];
  isNextItemPending?: boolean;
}

export interface FlowError {
  type: ErrorTypes;
  message?: string;
  code?: HttpCodes;
}

export interface AssetError {
  asset: FlowAssetMeta;
  message?: string;
  code?: HttpCodes;
}

export interface FlowCourseItem {
  header: SectionHeader;
  items: AssetWithProgressFields[];
}

export interface FlowCourseItemWithPassed extends FlowCourseItem {
  coursePassed: boolean;
}

type FlowAssetMetaBase<T> = {
  id: number;
  publisher: string;
  title: string;
  type: T;
};

export type FlowVideoMeta = FlowAssetMetaBase<EntityType.Video> & {
  duration: number;
};

export type FlowPdfMeta = FlowAssetMetaBase<EntityType.Pdf> & {};

export type FlowAssessmentMeta = FlowAssetMetaBase<EntityType.Assessment> & {
  questionsCount: number;
};

export type FlowSurveyMeta = FlowAssetMetaBase<EntityType.Survey> & {
  questionsCount: number;
};

export type FlowExternalEventMeta = FlowAssetMetaBase<EntityType.Event> & {
  singleSessionsCount: number;
  recurrenceSession?: RecurrenceSession;
};

export type FlowEmailMeta = FlowAssetMetaBase<EntityType.Email> & {
  subject: string;
};

export type FlowMessageMeta = FlowAssetMetaBase<EntityType.Message> & {};

export type FlowEndMeta = {
  id: number;
  title: string;
  completedMessage: string;
  statusCode: number;
  nextFlowId: number;
  type: FlowEntityType.FlowEnd;
};

export type UnavailableAssetMeta = {
  id: number;
  title: string;
  originType: FlowEntity;
  type: FlowEntityType.UnavailableAsset;
};

export type FlowAssetMeta =
  | FlowVideoMeta
  | FlowPdfMeta
  | FlowAssessmentMeta
  | FlowSurveyMeta
  | FlowExternalEventMeta
  | FlowEmailMeta
  | FlowMessageMeta
  | FlowEndMeta
  | UnavailableAssetMeta;

export type FlowVideoAsset = FlowVideoMeta & {
  description: string;
  manifests: VideoStreamManifest[];
  closedCaptions: ClosedCaptions[];
};

export type FlowAssessmentAsset = FlowAssessmentMeta & {
  questions: AssessmentQuestion[];
};

export type FlowSurveyAsset = FlowSurveyMeta & {
  questions: SurveyQuestion[];
};

export type FlowPdfAsset = FlowPdfMeta & {
  pdfDocumentUrl: string;
};

export type FlowAsset =
  | FlowVideoAsset
  | FlowPdfAsset
  | FlowAssessmentAsset
  | FlowSurveyAsset
  | FlowExternalEventMeta
  | FlowEmailMeta
  | FlowMessageMeta
  | FlowEndMeta
  | UnavailableAssetMeta;

export type AssetWithProgressFields = FlowAssetMeta & {
  order: number;
  bag: ProgressRecordBag;
  isDelayTimeNotExpired: boolean;
  disabled: boolean;
};

export interface EvaluatedAssessment {
  id: number;
  passed: boolean;
  percentage: number;
  percentageToPass: number;
  showCorrectAnswers: boolean;
  totalQuestions: number;
  correctQuestions: number;
  questions: EvaluatedAssesmentQuestion[];
}
interface EvaluatedAssesmentQuestion {
  order: number;
  passed: boolean;
  answers: boolean[];
}

export type CompletionStatusName = {
  [key in keyof typeof CompletionStatus]?: string;
};

export const AssetCompletionStatusName: CompletionStatusName = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Finished: "Finished",
};

export const EventCompletionStatusName: CompletionStatusName = {
  NotStarted: "Not Registered",
  InProgress: "Registered",
  Finished: "Attended",
};

export const PdfCompletionStatusName: CompletionStatusName = {
  NotStarted: "Not Opened",
  Finished: "Viewed",
};
