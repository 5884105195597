import cn from "classnames";
import { type FC } from "react";

import { getStatusNames } from "../../../../Flows/helpers/completionStatusHelper";
import { CompletionStatus } from "../../../../Flows/types/enums";
import CardProgressBar from "../CardProgressBar/CardProgressBar";

import styles from "./CardProgress.module.scss";
import { type EntityType } from "features/common/types";

interface CardProgressProps {
  completionStatus: CompletionStatus;
  wideBars?: boolean;
  contentType?: EntityType;
  className?: string;
}

export const CardProgress: FC<CardProgressProps> = ({ completionStatus, wideBars, contentType, className }) => {
  const statusNames = getStatusNames(contentType);

  return (
    <div
      className={cn(styles.outerProgressContainer, className, {
        [styles.wide]: wideBars,
      })}
    >
      {(Object.entries(statusNames) as Array<[keyof typeof statusNames, string]>).map(([key, value], index) => (
        <CardProgressBar
          key={value}
          index={index}
          wideBars={wideBars}
          status={completionStatus}
          barStatus={CompletionStatus[key]}
          title={value}
        />
      ))}
    </div>
  );
};
