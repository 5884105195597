import { mapCompletionStatus } from "../../common/mapperServices/mapCompletionStatus";
import { type DiscoveryItem, type SearchResult } from "../types";

export function mapToDiscoveryItems(searchResults: SearchResult[]): DiscoveryItem[] {
  return searchResults.map(mapToDiscoveryItem);
}

export function mapToDiscoveryItem(searchResult: SearchResult): DiscoveryItem {
  return {
    ...searchResult,
    id: parseInt(searchResult.id),
    duration: searchResult.bag?.duration,
    thumbnailUrl: searchResult.bag?.thumbnailUrl,
    completionStatus: mapCompletionStatus(searchResult),
  } as DiscoveryItem;
}
