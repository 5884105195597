import axios from "axios";
import { type Flow, type FlowAssetMeta, type Progress } from "../types";

import {
  type AssessmentQuestion,
  type Asset,
  type QuestionsAnswers,
  type SurveyAnswers,
  type SurveyQuestion,
} from "../../Assets/types";
import { serializeArrayAndFilterNullable } from "../../common/helpers/queryHelpers";
import { type SessionRegistrationFlowRequest } from "../../Events/types";

export async function fetchFlow(id: number) {
  const resp = await axios.get<Flow>(`/api/v4/client/flows/${id}`);
  return resp.data;
}

export async function fetchFlowProgress(id: number) {
  const resp = await axios.get<Progress>(`/api/client/flows/${id}/progress`);
  return resp.data;
}

export function startFlow(id: number) {
  return axios.post(`/api/v2/client/flows/${id}/start`);
}

export function restartFlow(id: number) {
  return axios.post(`/api/client/flows/${id}/restart`);
}

export function setFlowVideoWatched(flowId: number, assetId: number) {
  return axios.post(`/api/v4/client/flows/${flowId}/videos/${assetId}/watched`);
}

export function setFlowPdfViewed(flowId: number, assetId: number) {
  return axios.post(`/api/v4/client/flows/${flowId}/pdfs/${assetId}/complete`);
}

export async function fetchVideoAssetData(id: number) {
  const resp = await axios.get<Asset[]>(`/api/v4/client/assets/videos/${id}`);
  return resp.data;
}

export async function fetchPdfAssetData(id: number) {
  const resp = await axios.get<Asset[]>(`/api/v4/client/assets/pdfs/${id}`);
  return resp.data;
}

export function skipAsset(id: number, assetId: number, type: string) {
  return axios.post(`/api/client/flows/${id}/${type}/${assetId}/skip`);
}

export async function getContent(keys: { type: string; id: string }[]) {
  if (!keys.length) {
    return [];
  }
  const config = {
    params: { key: keys.map(x => `${x.type}:${x.id}`) },
    paramsSerializer: { serialize: serializeArrayAndFilterNullable },
  };

  const resp = await axios.get<FlowAssetMeta[]>(`/api/v4/client/assets`, config);
  return resp.data;
}

export async function fetchAssessmentQuestionsById(id: number) {
  const resp = await axios.get<{ questions: AssessmentQuestion[] }>(`/api/client/assets/assessments/${id}/questions`);
  return resp.data.questions;
}

export function evaluateAssessmentForFlow(flowId: number, assessmentId: number, data: QuestionsAnswers) {
  return axios.post(`/api/v4/client/flows/${flowId}/assessments/${assessmentId}/evaluate`, data);
}

export async function fetchSurveyQuestionsById(id: number) {
  const resp = await axios.get<{ questions: SurveyQuestion[] }>(`/api/v4/client/assets/surveys/${id}/questions`);
  return resp.data.questions;
}

export function completeSurveyForFlow(flowId: number, surveyId: number, data: SurveyAnswers) {
  return axios.post(`/api/v4/client/flows/${flowId}/surveys/${surveyId}/complete`, data);
}

export function registerOnEventSessionInFlow(request: SessionRegistrationFlowRequest) {
  const { flowId, eventId, sessionId, registrationType, sessionStartDate } = request;
  const data = {
    sessionId,
    registrationType,
    sessionStartDate,
  };

  return axios.post(`/api/v4/client/flows/${flowId}/external-events/${eventId}/register`, data);
}

export function markVideoAsStarted(id: number, itemId: number): Promise<void> {
  return axios.post(`/api/v4/client/flows/${id}/videos/${itemId}/start`);
}

export function markAssessmentAsStarted(flowId: number, assessmentId: number): Promise<void> {
  return axios.post(`/api/v4/client/flows/${flowId}/assessments/${assessmentId}/start`);
}

export function markSurveyAsStarted(flowId: number, surveyId: number): Promise<void> {
  return axios.post(`/api/v4/client/flows/${flowId}/surveys/${surveyId}/start`);
}

export async function fetchEventAssetData(eventId: number) {
  const event = await axios.get<Asset[]>(`/api/v4/client/external-events/${eventId}`);
  return event.data;
}
