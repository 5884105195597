import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type MyData, type Profile } from "../../types";
import { RStatus } from "../../../Application/types";
import { type ProfileState } from "../profile/profileSlice";
import { loadState } from "features/Application/services/localStorageService";

export interface MyDataState {
  status: RStatus;
  current: MyData | undefined;
  errorMessage: string | undefined;
  requested: boolean;
}

export const initialState: MyDataState = {
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
  requested: false,
};

const myDataSlice = createSlice({
  name: "myData",
  initialState,
  reducers: {
    init(state, action: PayloadAction<{ profile: Profile | undefined }>) {
      if (state.requested || !action.payload.profile) {
        return { ...state };
      }

      return {
        ...initialState,
        current: {
          gdprLink: null,
          requestedTime: loadState(
            `BSI_GDPR_REQUESTED_TIME_FOR_${action.payload.profile?.firstName}_${action.payload.profile?.lastName}`,
          ),
        },
      };
    },
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ myData: MyData }>) {
      return { ...state, status: RStatus.Got, current: action.payload.myData, requested: true };
    },
    err(state, action: PayloadAction<{ errorMessage: ProfileState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { init, req, got, err, reset } = myDataSlice.actions;

export default myDataSlice.reducer;
