import axios from "axios";
import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { type Profile } from "../types";

const pipeline = newPipeline(new AnonymousCredential());

export interface ImageResponse {
  expired: Date;
  fileName: string;
  uri: string;
}

export async function fetchProfile() {
  const resp = await axios.get<Profile>("/api/me");
  return resp.data;
}

export function ReadImage(image: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result as string);
    };
    fr.onerror = () => reject("Could not read image");
    fr.readAsDataURL(image);
  });
}

export async function UpdateImageOnServer(file: File) {
  const resp = await axios.post<ImageResponse>("/api/users/images/sas", {
    Permissions: [2],
  });
  const blockBlobClient = new BlockBlobClient(resp.data.uri, pipeline);
  const defaultBlockSize = 4 * 2 ** 20;
  const defaultParallelism = 4;
  let options = {
    blockSize: defaultBlockSize,
    parallelism: defaultParallelism,
    maxSingleShotSize: defaultBlockSize,
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
  };
  await blockBlobClient.uploadData(file, options);
  await axios.put("/api/me/image", {
    ImageUrl: resp.data.uri,
  });
}

export function DeleteImageOnServer() {
  return axios.delete("/api/me/image");
}
