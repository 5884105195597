import classNames from "classnames/bind";
import styles from "./FlowProgressLine.module.scss";

const cx = classNames.bind(styles);

interface Props {
  isFirst: boolean;
  disabled: boolean;
}

export const FlowProgressLine: React.FC<Props> = ({ isFirst, disabled }) => {
  return <div className={cx(styles.root, { isFirst, disabled })} />;
};
