import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  req,
  got,
  err,
  reqMembers,
  updateMembersStatus,
  gotMembers,
  errMembers,
  fetchMembersMore,
  outOfMembersResults,
  reqContent,
  updateContentStatus,
  gotContent,
  errContent,
  outOfContentResults,
  fetchContentMore,
  updateSort,
  type ContentState,
  selectContentState,
  selectMembersState,
  type MembersState,
  selectGroup,
} from "./groupSlice";
import { type Group, type GroupMember } from "../../types";
import { getGroup, getMembers, getContent } from "../../services/groupsService";
import { type SearchResult } from "../../../Discover/types";
import { RStatus } from "../../../Application/types";
import { mapToGroupContentItems } from "../../services/groupMapperService";
import { CustomHttpHeaders } from "../../../Application/types/enums";

export function* handleFetchGroupInfo(action: ReturnType<typeof req>) {
  try {
    const current: Group = yield call(getGroup, action.payload.id);

    yield put(got({ current }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* handleFetchMembers(action: ReturnType<typeof reqMembers>) {
  try {
    const state: MembersState = yield select(selectMembersState);
    const { top } = state;

    const [members, headers]: [GroupMember[], any] = yield call(getMembers, action.payload.id, action.payload.term);
    const inlineCount = parseInt(headers[CustomHttpHeaders.recordsCount]);
    if (inlineCount < top) {
      yield put(outOfMembersResults());
    }
    yield put(
      gotMembers({
        members,
        count: inlineCount,
      }),
    );
  } catch (error: any) {
    yield put(errMembers({ errorMessage: error?.message }));
  }
}

export function* handleFetchMoreMembers(action: ReturnType<typeof fetchMembersMore>) {
  try {
    const groupState: Group = yield select(selectGroup);
    const state: MembersState = yield select(selectMembersState);
    const { activePage, top, reachedEnd, filtered } = state;
    const { id } = groupState;

    if (reachedEnd && filtered) {
      yield put(updateMembersStatus({ status: RStatus.Got }));
      return;
    }
    const skip = (activePage - 1) * top;
    const [members, headers]: [GroupMember[], any] = yield call(getMembers, id, action.payload.term, skip);
    const inlineCount = parseInt(headers[CustomHttpHeaders.recordsCount]);
    const loadedMembers = filtered.concat(members);
    if (inlineCount <= loadedMembers.length) {
      yield put(outOfMembersResults());
    }
    yield put(
      gotMembers({
        members: loadedMembers,
        count: inlineCount,
      }),
    );
  } catch (error: any) {
    yield put(errMembers({ errorMessage: error?.message }));
  }
}

export function* handleFetchContent(action: ReturnType<typeof reqContent>) {
  try {
    const state: ContentState = yield select(selectContentState);
    const { activePage, top } = state;
    const skip = (activePage - 1) * top;
    const content: SearchResult[] = yield call(getContent, action.payload.id, skip);
    if (content.length < top) {
      yield put(outOfContentResults());
    }
    const contentItems = mapToGroupContentItems(content);
    yield put(gotContent(contentItems));
  } catch (error: any) {
    yield put(errContent({ errorMessage: error?.message }));
  }
}

export function* handleFetchMoreContent() {
  try {
    const groupState: Group = yield select(selectGroup);
    const state: ContentState = yield select(selectContentState);
    const { activePage, top, reachedEnd, items } = state;
    const { id } = groupState;

    if (reachedEnd && items) {
      yield put(updateContentStatus({ status: RStatus.Got }));
      return;
    }
    const skip = (activePage - 1) * top;
    const content: SearchResult[] = yield call(getContent, id, skip);
    if (content.length < top) {
      yield put(outOfContentResults());
    }
    const contentItems = mapToGroupContentItems(content);
    yield put(gotContent(items.concat(contentItems)));
  } catch (error: any) {
    yield put(errContent({ errorMessage: error?.message }));
  }
}

export function* groupWatcherSaga() {
  yield takeLatest(req.type, handleFetchGroupInfo);
  yield takeLatest(reqMembers.type, handleFetchMembers);
  yield takeLatest(fetchMembersMore.type, handleFetchMoreMembers);
  yield takeLatest(reqContent.type, handleFetchContent);
  yield takeLatest(fetchContentMore.type, handleFetchMoreContent);
  yield takeLatest(updateSort.type, handleFetchContent);
}
