import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RStatus } from "features/Application/types";
import { type UpcomingEvent } from "features/Home/types";

interface UpcomingEventsState {
  status: RStatus;
  error: string | null;
  items: UpcomingEvent[];
}

export const initialState: UpcomingEventsState = {
  status: RStatus.Pending,
  error: null,
  items: [],
};

const slice = createSlice({
  name: "homeUpcomingEvents",
  initialState,
  reducers: {
    req(state) {
      state.status = RStatus.Pending;
    },
    got(state, action: PayloadAction<UpcomingEvent[]>) {
      state.status = RStatus.Got;
      state.items = action.payload;
    },
    err(state, action: PayloadAction<string>) {
      state.status = RStatus.Error;
      state.error = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { err, got, req, reset } = slice.actions;

export default slice.reducer;
