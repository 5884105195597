import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { type Profile } from "../../types";

export interface ProfileState {
  status: RStatus;
  current?: Profile;
  errorMessage: string | undefined;
  imageFile: File | null;
  // Used in determining if save button should be active
  // in some scenarios
  originalImage: string | null;
  dataChanged: boolean;
  saving: boolean;
}

export const initialState: ProfileState = {
  status: RStatus.Idle,
  current: undefined,
  errorMessage: undefined,
  imageFile: null,
  originalImage: null,
  dataChanged: false,
  saving: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ profile: Profile }>) {
      let originalImage = state.originalImage;
      // This is the first time we're calling this, so set original image
      if (!state.current) {
        originalImage = action.payload.profile.imageUrl;
      }
      return {
        ...state,
        status: RStatus.Got,
        current: action.payload.profile,
        originalImage,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: ProfileState["errorMessage"] }>) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setImage(state, action: PayloadAction<{ image: File | null }>) {
      return {
        ...state,
        imageFile: action.payload.image,
        dataChanged: !(action.payload.image === null && state.originalImage === null),
      };
    },
    updateImage(state) {
      return {
        ...state,
        saving: true,
      };
    },
    cancel(state) {
      if (!state.current) return state;
      return {
        ...state,
        dataChanged: false,
        current: {
          ...state.current,
          imageUrl: state.originalImage,
        },
      };
    },
    done(state, action: PayloadAction<{ errorMessage?: ProfileState["errorMessage"] }>) {
      if (!state.current) return state;
      const { errorMessage } = action.payload;
      return {
        ...state,
        saving: false,
        dataChanged: !!errorMessage,
        errorMessage,
        originalImage: errorMessage ? state.originalImage : state.current.imageUrl,
      };
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, err, setImage, updateImage, cancel, done, reset } = profileSlice.actions;

// selectors
export const selectProfile = (state: RootState) => state.account.profile;
export const selectCurrentProfile = (state: RootState) => state.account.profile.current;
export const selectProfileStatus = (state: RootState) => state.account.profile.status;
export const selectProfileError = (state: RootState) => state.account.profile.errorMessage;
export const selectProfileOriginalImage = (state: RootState) => state.account.profile.originalImage;
export const selectProfileDataChanged = (state: RootState) => state.account.profile.dataChanged;
export const selectProfileSaving = (state: RootState) => state.account.profile.saving;

// reducers
export default profileSlice.reducer;
