import { call, put, takeLatest } from "redux-saga/effects";
import { req, got, err } from "./publishersSlice";
import { type Publisher } from "../../types";
import { fetchPublishers } from "../../services/publishersService";

export function* handleFetchPublishers() {
  try {
    const publishers: Publisher[] = yield call(fetchPublishers);
    yield put(got({ items: publishers }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* publishersWatcherSaga() {
  yield takeLatest(req.type, handleFetchPublishers);
}
