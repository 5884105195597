import { faEmptySet, faFaceDisappointed, faHourglassClock, faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import HttpCodes from "../../../types/HttpCodes";
import { type ErrorData } from "./ErrorsHandler";

export const restrictedError: ErrorData = {
  title: "access.restricted.title",
  description: "access.restricted.description",
  icon: faLockKeyhole,
  canRetry: false,
};

export const notFoundError: ErrorData = {
  title: "access.notFound.title",
  description: "access.notFound.description",
  icon: faEmptySet,
  canRetry: false,
};

export const notReadyError: ErrorData = {
  title: "access.notReady.title",
  description: "access.notReady.description",
  icon: faHourglassClock,
  canRetry: true,
};

export const serverError: ErrorData = {
  title: "access.serverError.title",
  description: "access.serverError.description",
  icon: faFaceDisappointed,
  canRetry: true,
};

export const flowError: { [key in HttpCodes]?: ErrorData } = {
  [HttpCodes.Forbidden]: restrictedError,
  [HttpCodes.NotFound]: notFoundError,
  [HttpCodes.ServerError]: serverError,
};
export const progressError: { [key in HttpCodes]?: ErrorData } = {
  [HttpCodes.NotFound]: notReadyError,
  [HttpCodes.ServerError]: serverError,
};
