import "@youon/videojs-hotkeys";
import { type MutableRefObject, useCallback, useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-contrib-eme";
import "videojs-contrib-quality-levels";

import { type AutoTranslatePayload, type ClosedCaptions, type Playlist } from "../../../types";

import i18next from "i18next";
import { useRtn } from "../../../../Application/hooks/useRtn";
import {
  captionsAddSuccessRtn,
  captionsFailureRtn,
  CLOSED_CAPTION_STORAGE_KEY,
  getActiveTextTracks,
  handleChangeTextTrack,
  mapToTextTrackOptions,
  setCaptionButtonActive,
  setTrackActive,
  subscribeOnSelectedLanguageChange,
} from "../VideoPlayer/utils/textTracksUtils";
import { NextButtonPlugin, PrevButtonPlugin, QualityLevelsPlugin, SettingsPlugin } from "./plugins";
import { VideoJsControlButton } from "./plugins/VideoJsControlButton";

// Styles
import { datadogRum } from "@datadog/browser-rum";
import { getUserSetting, saveUserSettings } from "features/Account/redux/userSettings/userSettingsMiddleware";
import "video.js/dist/video-js.css";
import "./customVideoJs.scss";
import { CustomVjsComponentNames } from "./plugins/customVjsComponentNames";

/**
 * Event handlers
 */
type VideoPlayerEventCallback = {
  type: string;
  target: {
    player: any;
  };
};

/**
 * End Event handlers
 */

const DEFAULT_VIDEO_OPTIONS = {
  preload: "auto",
  autoplay: false,
  controls: true,
  playbackRates: [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5, 0.25],
  controlBar: {
    currentTimeDisplay: true,
    timeDivider: true,
    durationDisplay: true,
    remainingTimeDisplay: false,
    progressControl: { seekBar: true },
    volumePanel: {
      inline: true,
    },
    settingsMenuButton: {
      entries: ["QualityLevelsMenu", "playbackRateMenuButton"],
    },
    pictureInPictureToggle: false,
  },
  textTrackSettings: false,
  html5: {
    preloadTextTracks: false,
    vhs: {
      overrideNative: true,
    },
    nativeTextTracks: false,
    nativeAudioTracks: false,
    nativeVideoTracks: false,
  },
};

interface Source {
  src: string;
  type: string | undefined;
}
export interface Props extends Playlist {
  source?: Source;
  closedCaptions: ClosedCaptions[];
  options?: Object;
  resetAssetRef?: MutableRefObject<(() => void) | undefined>;
  onRateChange?: (rate: number) => void;
  onVolumeChange?: (volume: number) => void;
  onQualityChange?: (quality: number) => void;
  onClosedCaptionChange?: (language: string | null) => void;
  onTimeUpdate?: (currentTime: number) => void;
}

const registerPlugins = (onNext?: () => void, onPrevious?: () => void) => {
  videojs.registerComponent(CustomVjsComponentNames.controlButton, VideoJsControlButton);
  if (!videojs.getPlugin("quality")) {
    videojs.registerPlugin("quality", QualityLevelsPlugin);
  }

  if (!videojs.getPlugin("settings")) {
    videojs.registerPlugin("settings", SettingsPlugin);
  }

  if (!videojs.getPlugin("nextButton") && onNext) {
    videojs.registerPlugin("nextButton", NextButtonPlugin);
  }
  if (!videojs.getPlugin("previousButton") && onPrevious) {
    videojs.registerPlugin("previousButton", PrevButtonPlugin);
  }
};
const initPlugins = (
  player: any,
  options: Object,
  canNext?: boolean,
  canPrevious?: boolean,
  onNext?: () => void,
  onPrevious?: () => void,
) => {
  player.quality();
  player.settings(options);
  if (onNext) {
    player.nextButton({
      onClick: () => {
        onNext?.();
      },
      disableButton: !canNext,
      text: canNext ? i18next.t("assets:controls.playNext") : i18next.t("assets:controls.noNext"),
    });
  }
  if (onPrevious) {
    player.previousButton({
      onClick: () => {
        onPrevious?.();
      },
      disableButton: !canPrevious,
      text: canPrevious ? i18next.t("assets:controls.playPrevious") : i18next.t("assets:controls.noPrevious"),
    });
  }
};

export const VideoJSPlayer: React.FC<Props> = props => {
  const { onTimeUpdate } = props;
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<any>();
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    const playerRef = player.current;

    return () => {
      if (playerRef && !playerRef?.isDisposed()) {
        playerRef.dispose();
        player.current = undefined;
      }
    };
  }, [player]);

  const onAutoTranslateFinished = useCallback((payload: AutoTranslatePayload) => {
    handleChangeTextTrack(payload, player?.current);
  }, []);

  const onAutoTranslateFailed = useCallback(() => {
    const activeTrack = getActiveTextTracks(player.current?.remoteTextTracks());
    if (activeTrack) {
      activeTrack.mode = "disabled";
    }
  }, []);

  useRtn(captionsAddSuccessRtn, onAutoTranslateFinished);
  useRtn(captionsFailureRtn, onAutoTranslateFailed);

  const resetVideo = () => {
    const videoJSplayer = player.current;

    if (getCurrentTime()) {
      videoJSplayer?.autoplay(false);
      props.source && videoJSplayer?.src(props.source);
    }
  };

  const mapCaptions = (captions: ClosedCaptions[]) =>
    captions.forEach(caption => player.current?.addRemoteTextTrack(mapToTextTrackOptions(caption), false));

  const getCurrentTime = (): number => {
    const videoJSplayer = player.current;
    return Number(videoJSplayer?.currentTime());
  };

  const getDuration = (): number => {
    const videoJSplayer = player.current;
    return Number(videoJSplayer?.duration());
  };

  const { onVolumeChange, onQualityChange, onRateChange } = props;

  const onQualityLevelChangeHandler = useCallback(
    (_: any, data: any) => {
      const quality = data?.quality as number;
      if (quality) {
        saveUserSettings("videoQuality", quality.toString());
        onQualityChange && onQualityChange(quality);
      }
    },
    [onQualityChange],
  );

  const onVolumeChangeHandler = useCallback(
    (e: VideoPlayerEventCallback) => {
      const player = e.target.player;
      const volume = player.muted() ? 0 : player.volume();
      saveUserSettings("volume", volume!.toString());
      onVolumeChange && onVolumeChange(volume);
    },
    [onVolumeChange],
  );

  const onRateChangeHandler = useCallback(
    (e: VideoPlayerEventCallback) => {
      const playbackRate = e.target.player.playbackRate();
      saveUserSettings("playbackRate", playbackRate!.toString());
      onRateChange && onRateChange(playbackRate);
    },
    [onRateChange],
  );

  const onTimeUpdateHandler = useCallback(
    (e: VideoPlayerEventCallback) => {
      const currentTime = e.target.player.currentTime();
      onTimeUpdate?.(currentTime);
    },
    [onTimeUpdate],
  );

  useEffect(() => {
    if (initialized.current) {
      props.source && player.current?.src(props.source);
    } else {
      const options = { ...DEFAULT_VIDEO_OPTIONS, ...props.options };
      // Register custom components and plugins with video.js.
      registerPlugins(props.onNext, props.onPrevious);

      player.current = videojs(videoNode.current as Element, options, () => {
        const videoJSplayer = player.current;

        videoJSplayer!.eme();
        props.source && videoJSplayer?.src(props.source);
        mapCaptions(props.closedCaptions);
        videoJSplayer?.focus();
        const languageCode = getUserSetting(CLOSED_CAPTION_STORAGE_KEY);

        if (languageCode) {
          setCaptionButtonActive(videoJSplayer);
          setTrackActive(videoJSplayer, languageCode);
        }
      });

      subscribeOnSelectedLanguageChange(player.current, props.id!, props.onClosedCaptionChange);
      player.current.hotkeys({
        volumeStep: 0.1,
        seekStep: 5,
        enableModifiersForNumbers: false,
        enableVolumeScroll: false,
      });

      // Initialise plugins with the relevant options
      initPlugins(player.current, options as Object, props.canNext, props.canPrevious, props.onNext, props.onPrevious);
      initialized.current = true;
    }

    if (props.resetAssetRef) {
      props.resetAssetRef.current = resetVideo;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source?.src, props.closedCaptions]);

  useEffect(() => {
    if (player.current) {
      player.current.on("volumechange", onVolumeChangeHandler);
      player.current.on("ratechange", onRateChangeHandler);
      player.current.on("qualitychange", onQualityLevelChangeHandler);
      player.current.on("timeupdate", onTimeUpdateHandler);
    }

    return () => {
      player?.current?.off("volumechange", onVolumeChangeHandler);
      player?.current?.off("ratechange", onRateChangeHandler);
      player?.current?.off("qualitychange", onQualityLevelChangeHandler);
      player?.current?.off("timeupdate", onTimeUpdateHandler);
    };
  }, [onRateChangeHandler, onVolumeChangeHandler, onQualityLevelChangeHandler, onTimeUpdateHandler]);

  useEffect(() => {
    datadogRum.addAction("On play Effect: Player initialized", {
      instance: !!player.current,
    });
    const onPlay = () => {
      datadogRum.addAction("On play subscribing", {
        currentTime: getCurrentTime(),
      });
      if (getCurrentTime() <= 1) {
        props.onVideoStarted?.();
      }
      const volume = getUserSetting("volume");
      if (volume === null) {
        player.current?.volume(1);
        saveUserSettings("volume", "1");
      } else {
        player.current?.volume(Number(volume));
        saveUserSettings("volume", volume);
      }
      const playbackRate = getUserSetting("playbackRate");
      if (playbackRate === null) {
        player.current?.playbackRate(1);
        saveUserSettings("playbackRate", "1");
      } else {
        player.current?.playbackRate(Number(playbackRate));
        saveUserSettings("playbackRate", playbackRate);
      }
    };
    if (props.onVideoStarted && player.current) {
      player.current.on("play", onPlay);
    }
    return () => player?.current?.off("play", onPlay);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((props.onVideoEnded || props.id) && player.current) {
      player.current.on("ended", function () {
        if (getDuration() - getCurrentTime() <= 1) {
          props.onVideoEnded?.();
        }

        if (document.fullscreenElement) {
          player.current?.exitFullscreen();
        }
      });
    }

    return () => player?.current?.off("ended");
    // eslint-disable-next-line
  }, []);

  return (
    <div data-vjs-player className="video-js brainstorm-player vjs-16-9">
      <video ref={videoNode} id="video-js-player" />
    </div>
  );
};
