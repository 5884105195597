import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { ContentList } from "../ContentList/ContentList";

import { useTranslation } from "react-i18next";
import { type DropdownItemProps } from "semantic-ui-react";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { getParamValue } from "../../../Application/utils/routing";
import CustomDropdown from "../../../Flows/components/CustomDropdown/CustomDropdown";
import { SEARCH_TERM } from "../../constants";
import { useQueryObserver } from "../../hooks/useQueryObserver";
import {
  fetchMore,
  initSearch,
  reset,
  resetFilter,
  resetFilters,
  updateFilters,
  updateSort,
} from "../../redux/searchResults/searchResultsSlice";
import { type Filter, SortType } from "../../types";
import FiltersBar from "../Filters/FiltersBar";
import { useSortController } from "../Sorting/useSortCotroller";
import FilteringControlls from "./Header/FilteringControlls";
import { Header } from "./Header/Header";

import {
  getSearchResults,
  selectFilterOptions,
  selectSearchResults,
  selectSearchResultsError,
  selectSearchResultsReachedEnd,
  selectSearchResultsStatus,
} from "features/Discover/redux/searchResults/searchResultsSelectors";
import styles from "./DiscoverPage.module.scss";

const cx = classNames.bind(styles);

export const DiscoverPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(["discover"]);

  const onSortChange = useCallback(
    (newValue: any) => {
      dispatch(updateSort({ value: newValue }));
    },
    [dispatch],
  );
  const { search } = useSelector(getSearchResults);
  const [initialValues] = useQueryObserver(search);
  const filterOptions = useSelector(selectFilterOptions);

  const prevTerm = useRef(initialValues.term);

  useEffect(() => {
    dispatch(initSearch({ search: initialValues }));
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = useCallback(
    (filter: Filter, newValue: any) => {
      dispatch(
        updateFilters({
          filter,
          newValue,
        }),
      );
    },
    [dispatch],
  );

  const onReset = useCallback(
    (filter: Filter) => {
      dispatch(resetFilter(filter));
    },
    [dispatch],
  );

  const onResetAll = useCallback(() => {
    batch(() => {
      dispatch(resetFilters());
    });
  }, [dispatch]);

  const { showSort, setShowSort, toggleSort, isMobile } = useSortController();

  const { term } = search;
  useEffect(() => {
    if (prevTerm.current !== term) {
      prevTerm.current = term;
      onResetAll();
    }
  }, [onResetAll, term]);

  const dropdownOptions: DropdownItemProps[] = useMemo(
    () => [
      {
        key: "mostRelevant",
        text: t("sort.mostRelevant"),
        value: SortType.Relevant,
      },
    ],
    [t],
  );

  const [filtersCount, setFiltersCount] = useState(0);
  useEffect(() => {
    setFiltersCount(
      Object.values(search.appliedFilters)
        .flat()
        .filter(x => !!x).length,
    );
  }, [search.appliedFilters, setFiltersCount]);
  const [show, setShow] = useState(false);

  // Changing page title and updating google analytics
  useEffect(() => {
    // Do we have a search string (in other words, on the search page?)
    let query = "";
    if (location.search) {
      query = getParamValue(location.search, SEARCH_TERM);
    }
    let newTitle = query ? `${query} - Search` : "Discover";
    if (document.title === newTitle) return;

    document.title = newTitle;
    SendPageView({
      title: query ? "Search" : "Discover",
    });
  }, [location.search]);

  const handleCloseDropdown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLInputElement;
      if (!target.className.includes("sort amount down icon")) setShowSort(false);
    },
    [setShowSort],
  );

  return (
    <FixedHeightAppPageWrapper reactStrictMode={false}>
      <div className={styles.flexGrid}>
        <Header
          controls={
            <FilteringControlls
              filtersCount={filtersCount}
              setShowFilters={setShow}
              setSortOpen={setShowSort}
              showFilters={show}
              sortOpen={showSort}
            />
          }
        />
        <div className={styles.mainContent}>
          <div className={styles.filterBarContainer}>
            <FiltersBar
              filterValues={search.appliedFilters}
              filters={filterOptions}
              update={onUpdate}
              reset={onReset}
              show={show}
              setShow={setShow}
            />
          </div>
          <div className={cx(styles.flowList, styles.leftPadding)}>
            <ContentList
              closeDropdown={handleCloseDropdown}
              dropdown={
                <CustomDropdown
                  options={dropdownOptions}
                  isVisible={showSort}
                  toggleVisible={toggleSort}
                  defaultValue={initialValues.sortType}
                  onChange={onSortChange}
                  isMobile={isMobile}
                />
              }
              selectResult={selectSearchResults}
              selectStatus={selectSearchResultsStatus}
              selectError={selectSearchResultsError}
              selectReachedEnd={selectSearchResultsReachedEnd}
              fetchMore={fetchMore}
            />
          </div>
        </div>
      </div>
    </FixedHeightAppPageWrapper>
  );
};
