import { useCallback, useState } from "react";
import { type AssetWithProgressFields, type FlowProgress, type SectionHeader } from "../../types";
import { FlowCourseHeader } from "./FlowCourseHeader/FlowCourseHeader";
import { FlowProgressItem } from "./FlowProgressItem/FlowProgressItem";
import { FlowEntityType } from "features/common/types";

export interface Props {
  header: SectionHeader;
  isFirst: boolean;
  items: AssetWithProgressFields[];
  collapsedNotMobile: boolean;
  coursePassed: boolean;
  previousCoursePassed: boolean;
  progress?: FlowProgress;
  onProgressItemClick: (index: number) => void;
}

interface ItemProps {
  item: AssetWithProgressFields;
  collapsedNotMobile: boolean;
  progress: FlowProgress | undefined;
  onProgressItemClick: (index: number) => void;
}

const ProgressItem: React.FC<ItemProps> = ({ item, collapsedNotMobile, progress, onProgressItemClick }) => {
  const clickItem = useCallback(() => {
    onProgressItemClick(item.order);
  }, [item, onProgressItemClick]);

  return (
    <FlowProgressItem
      asset={item}
      key={`${item.id}-${item.order}`}
      collapsed={collapsedNotMobile}
      selected={item.order === progress?.selectedAssetIndex}
      completed={(progress?.viewedAssetIndexes ?? []).includes(item.order)}
      error={item.type === FlowEntityType.UnavailableAsset}
      onClickItem={clickItem}
    />
  );
};

export const FlowProgressCourse: React.FC<Props> = ({
  header,
  items,
  collapsedNotMobile,
  progress,
  isFirst,
  coursePassed,
  previousCoursePassed,
  onProgressItemClick,
}) => {
  const [opened, toggle] = useState(true);

  const toggler = useCallback(() => {
    toggle(!opened);
  }, [opened]);

  return (
    <>
      {[
        <FlowCourseHeader
          isFirst={isFirst}
          name={header.name}
          opened={opened}
          key={header.id}
          id={header.id}
          coursePassed={coursePassed}
          collapsedNotMobile={collapsedNotMobile}
          disabled={!previousCoursePassed && (progress?.viewedAssetIndexes ?? []).indexOf(items[0]?.order) === -1}
          description={header.description}
          onClick={toggler}
        />,
        ...(opened
          ? items.map(item => (
              <ProgressItem
                key={`${item.id}-${item.order}`}
                item={item}
                collapsedNotMobile={collapsedNotMobile}
                progress={progress}
                onProgressItemClick={onProgressItemClick}
              />
            ))
          : []),
      ]}
    </>
  );
};
