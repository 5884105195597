import classNames from "classnames";
import { Toast, ToastType } from "features/Application/layout/Toast/Toast";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import { useAuth } from "oidc-react";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Icon } from "semantic-ui-react";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { useRtn } from "../../../Application/hooks/useRtn";
import useScrollPoint from "../../../Application/hooks/useScrollPoint";
import { LazyLoading } from "../../../Application/layout";
import * as libraryEventTypes from "../../../Application/services/realTimeNotification/events/libraryEvents";
import { RStatus } from "../../../Application/types";
import { Flyout } from "../../../Discover/layout/Flyout/Flyout";
import {
  fetchMore,
  registerOnSessionSuccess,
  req,
  reset,
  selectSessions,
  selectSessionsError,
  selectSessionsReachedEnd,
  selectSessionsReminders,
  selectSessionsStatus,
  selectSessionsStatusCode,
} from "../../redux/sessions/sessionsSlice";
import { type EventSession, type SessionRegistrationSuccess } from "../../types";
import { EventInfo } from "./EventInfo/EventInfo";
import { SessionInfo } from "./EventSession/SessionInfo";
import styles from "./EventViewer.module.scss";
import { RegistrationOptions } from "./SessionRegistration/RegistrationOptions/SessionRegistrationOptions";

export interface Props {
  event: {
    id: number;
    title: string;
  };
  onRegistration?: (sessionId: string, registrationType: number, sessionStartDate: string) => void;
  onError?: (status: number, message?: string) => void;
  className?: string;
}

export const EventViewer: React.FC<Props> = (props: Props) => {
  const [showFlyout, setShowFlyout] = useState(false);
  const [eventName, setEventName] = useState("");
  const { event, onRegistration, onError } = props;

  const { t } = useTranslation(["events", "assets"]);

  const dispatch = useDispatch();
  const auth = useAuth();
  const email = auth.userData?.profile?.name;
  const sessions = useSelector(selectSessions);
  const reminders = useSelector(selectSessionsReminders);
  const status = useSelector(selectSessionsStatus);
  const code = useSelector(selectSessionsStatusCode);
  const errorMessage = useSelector(selectSessionsError);
  const end = useSelector(selectSessionsReachedEnd);
  const { isMobileScreen } = useContext(BreakpointContext);

  useEffect(() => {
    dispatch(req({ id: event.id }));
    return () => {
      dispatch(reset());
    };
  }, [dispatch, event.id]);

  useEffect(() => {
    if (status === RStatus.Error) {
      onError?.(code, errorMessage);
    }
  }, [dispatch, status, code, errorMessage, onError]);

  const node = useScrollPoint(
    useCallback(() => {
      dispatch(fetchMore());
    }, [dispatch]),
  );

  const debounceFlyout = useMemo(() => {
    return debounce(() => {
      setShowFlyout(false);
    }, 4000);
  }, []);

  useEffect(() => {
    return () => {
      debounceFlyout.cancel();
    };
  }, [debounceFlyout]);

  // Close flyout/toast in case screen size changes
  useEffect(() => {
    setShowFlyout(false);
  }, [isMobileScreen]);

  const handleRegistrationSuccess = (payload: SessionRegistrationSuccess) => {
    const { returnContentType, teamsDialogLink, calendarFileContent } = payload;

    switch (returnContentType) {
      case RegistrationOptions.Teams:
        window.open(teamsDialogLink, "_blank");
        break;
      case RegistrationOptions.Calendar:
        const file = new Blob([calendarFileContent], {
          type: "text/calendar;charset=utf-8",
        });
        saveAs(file, `${event.title}.ics`, { autoBom: false });
        break;
      default:
        setShowFlyout(true);
        setEventName(event.title);
        debounceFlyout();
        break;
    }

    dispatch(registerOnSessionSuccess(payload));
  };

  useRtn(libraryEventTypes.ClientUserSessionRegistrationSuccess, handleRegistrationSuccess);

  const viewEvent = useCallback(
    (session: EventSession) => {
      return (
        <Fragment key={session.sessionId}>
          <SessionInfo session={session} onRegistration={onRegistration} reminders={reminders} />
          <Divider fitted />
        </Fragment>
      );
    },
    [onRegistration, reminders],
  );

  const reachedEndWithNoResults = useMemo(
    () => status === RStatus.Got && sessions.length === 0 && end,
    [end, sessions.length, status],
  );

  return (
    <div className={classNames(styles.root, props.className)}>
      <EventInfo className={styles.eventInfo} {...event} />
      <section className={styles.content}>
        <Divider fitted />
        {reachedEndWithNoResults ? (
          <div className={styles.spaced}>No Available Sessions</div>
        ) : (
          <LazyLoading
            status={status}
            content={sessions}
            reachedEnd={end}
            scrollContainer={node}
            errorMessage={errorMessage}
            renderItem={viewEvent}
          />
        )}
      </section>
      {isMobileScreen ? (
        <div className={styles.flyoutRegistration}>
          <Flyout open={showFlyout} height={16}>
            <>
              <div className={styles.flyoutTopBar} />
              <div className={styles.flyoutMessage}>
                <div>
                  <Icon name="check circle" size="large" color="green" />
                  <div>
                    {t("registration.success", {
                      ns: "events",
                      eventName: eventName,
                      email: email,
                    })}
                  </div>
                </div>
              </div>
            </>
          </Flyout>
        </div>
      ) : (
        <Toast
          type={ToastType.Success}
          body={t("registration.success", {
            ns: "events",
            eventName: eventName,
            email: email,
          })}
          visible={showFlyout}
          onClose={() => setShowFlyout(false)}
        />
      )}
    </div>
  );
};
