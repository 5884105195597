import React from "react";
import { TextTruncator } from "../../../Application/layout/TextTruncator/TextTruncator";
import { type RecurrenceSession } from "../../types";
import { getRecurrenceMetadata } from "../../utils/getRecurrenceMetadata";

export type EventMeta = {
  singleSessionsCount: number;
  recurrenceSession?: RecurrenceSession;
};

export interface EventSessionMetadataProps<T extends EventMeta> {
  event: T;
}

export const EventSessionMetadata = <T extends EventMeta>({ event }: EventSessionMetadataProps<T>) => {
  const { singleSessionsCount, recurrenceSession } = event;
  let recurrenceMetadata = "";

  if (singleSessionsCount > 0) {
    return <></>;
  }

  if (recurrenceSession !== undefined) {
    recurrenceMetadata = getRecurrenceMetadata(recurrenceSession);
  }

  return (
    <div data-testid="recurrence metadata" aria-hidden="true">
      <TextTruncator clamp={1} aria-label={recurrenceMetadata} tabIndex={0} tooltipContent={recurrenceMetadata}>
        {recurrenceMetadata}
      </TextTruncator>
    </div>
  );
};

export default React.memo(EventSessionMetadata);
