import { getUserSetting } from "features/Account/redux/userSettings/userSettingsMiddleware";
import videojs from "video.js";
import type Player from "video.js/dist/types/player";
import { removeClassIfElementExists } from "../../../VideoPlayer/utils/vjsHelpers";

const MenuItem = videojs.getComponent("MenuItem");
const playbackRateMenuButton = videojs.getComponent("PlaybackRateMenuButton");
const component = videojs.getComponent("Component");

const toTitleCase = function (string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

class SettingsMenuItem extends MenuItem {
  subMenu?: any;
  isSwiping: boolean;

  constructor(player: Player, options: Object, entry: string) {
    super(player, options);
    this.isSwiping = false;
    this.subMenu = this.createSubMenu(player, options, entry);
    const update = videojs.bind(this, this.updateSelectedMenuOptions);
    const updateAfterTimeout = () => setTimeout(update, 0);

    for (const secondarySubMenuItem of this.getSecondarySubMenuItems()) {
      if (!(secondarySubMenuItem instanceof component)) {
        continue;
      }
      this.setupSecondarySubMenuEvents(secondarySubMenuItem, updateAfterTimeout);
    }

    this.populateMenuItems();
  }

  createSubMenu(_: Player, options: Object, entry: string): any {
    const subMenuName = toTitleCase(entry);
    const SubMenuComponent = videojs.getComponent(subMenuName);
    if (!SubMenuComponent) {
      throw new Error(`Component ${subMenuName} does not exist`);
    }
    return new SubMenuComponent(this.player(), options);
  }

  getSecondarySubMenuItems(): any[] {
    // @ts-ignore
    return this.subMenu.menu.children().filter(item => item instanceof component);
  }

  setupSecondarySubMenuEvents(secondarySubMenuItem: any, updateAfterTimeout: () => void): void {
    secondarySubMenuItem.on("mouseover", this.handleMouseOver);
    secondarySubMenuItem.on("keydown", this.handleKeyDown);
    secondarySubMenuItem.on("click", updateAfterTimeout);
    secondarySubMenuItem.on("touchmove", this.handleTouchMove);
    secondarySubMenuItem.on("touchstart", this.handleTouchStart);
    secondarySubMenuItem.on("touchend", this.handleTouchEnd);
    secondarySubMenuItem.on("click", this.removeOpenClass);
  }

  handleMouseOver(e: any): void {
    for (const children of e.target.parentNode.childNodes) {
      children.classList.remove("active");
    }
  }

  handleKeyDown(e: any): void {
    for (const children of e.target.parentNode.childNodes) {
      children.classList.remove("active");
    }

    const handleArrowKey = (key: string, nextSibling: any, previousSibling: any) => {
      setTimeout(() => {
        if (key === "ArrowDown" || key === "ArrowLeft") {
          if (nextSibling) {
            nextSibling.classList.add("active");
            nextSibling?.focus();
          } else {
            e.target.classList.add("active");
            e.target.focus();
          }
        } else if (key === "ArrowUp" || key === "ArrowRight") {
          if (previousSibling) {
            previousSibling.classList.add("active");
            previousSibling?.focus();
          } else {
            e.target.classList.add("active");
            e.target.focus();
          }
        }
      }, 0);
    };

    const nextSibling = e.target.nextSibling;
    const previousSibling = e.target.previousSibling;

    if (e.key === "ArrowDown" || e.key === "ArrowLeft") {
      handleArrowKey(e.key, nextSibling, previousSibling);
    } else if (e.key === "ArrowUp" || e.key === "ArrowRight") {
      // @ts-ignore
      handleArrowKey(e.key, previousSibling, nextSibling);
    }
  }

  handleTouchMove(): void {
    this.isSwiping = true;
  }

  handleTouchStart(): void {
    this.isSwiping = false;
  }

  handleTouchEnd(e: any): void {
    e.preventDefault();
    if (this.isSwiping) {
      this.isSwiping = false;
    } else {
      this.updateSelectedMenuOptions();
    }
  }

  // This creates the base menu structure, createEl is a called keyword
  createEl(type: string = "li"): HTMLLIElement {
    const el = videojs.dom.createEl(type, {
      className: "vjs-menu-item sub-menu-root",
      tabIndex: 0,
    });
    // @ts-ignores
    this.settingsSubMenuTitleEl_ = videojs.dom.createEl("div", {
      className: "vjs-settings-sub-menu-title",
    });
    // @ts-ignore
    el.appendChild(this.settingsSubMenuTitleEl_);
    // @ts-ignore
    this.settingsSubMenuValueEl_ = videojs.dom.createEl("div", {
      className: "vjs-settings-sub-menu-value",
    });
    // @ts-ignore
    el.appendChild(this.settingsSubMenuValueEl_);
    // @ts-ignore
    this.settingsSubMenuEl_ = videojs.dom.createEl("div", {
      className: "vjs-settings-sub-menu vjs-hidden",
    });
    // @ts-ignore
    el.appendChild(this.settingsSubMenuEl_);
    return el as HTMLLIElement;
  }

  // This is called when a primary menu item is clicked, it removes vjs-hidden from primary & secondary menu items when they have it
  handleClick(event: any) {
    // Remove open class to ensure only the open submenu gets this class

    // @ts-ignore
    super.handleClick(event);
    if (
      // @ts-ignore
      videojs.dom.hasClass(this.settingsSubMenuEl_ as Element, "vjs-hidden")
    ) {
      // @ts-ignore
      removeClassIfElementExists(this.settingsSubMenuEl_ as Element, "vjs-hidden");
      removeClassIfElementExists(
        // @ts-ignore
        this.settingsSubMenuEl_.firstChild as Element,
        "vjs-hidden",
      );
      // @ts-ignore
      for (const element of this.settingsSubMenuEl_.getElementsByClassName("vjs-menu-item")) {
        element.classList.add("inactive");
      }
      // @ts-ignore
      this.settingsSubMenuEl_.getElementsByClassName("vjs-selected")[0].focus();
      // This is used to detect if the click was from something other than a mouse.
      if (!event.isTrusted) {
        // @ts-ignore
        this.settingsSubMenuEl_.getElementsByClassName("vjs-selected")[0].classList.add("active");
      }
    } else {
      // @ts-ignore
      videojs.dom.addClass(this.settingsSubMenuEl_ as Element, "vjs-hidden");
    }
  }

  populatePlaybackRate() {
    // @ts-ignore
    if (this.settingsSubMenuTitleEl_.innerHTML === "Playback Rate:") {
      const playbackRateList =
        // @ts-ignore
        this.settingsSubMenuEl_.getElementsByClassName("vjs-menu-item-text");

      if (playbackRateList[0].innerHTML.slice(-1) === "x") {
        for (const element of playbackRateList) {
          if (element.innerHTML === "1x") {
            element.innerHTML = "Normal";
          } else {
            element.innerHTML = element.innerHTML.slice(0, -1);
          }
        }
      }
    }
  }

  checkPlaybackRate() {
    const playbackRate = getUserSetting("playbackRate");

    if (playbackRate === null || playbackRate === "1") {
      // @ts-ignore
      // This sets the currently selected value text of the Playback Rate menu item
      this.settingsSubMenuValueEl_.innerHTML =
        // @ts-ignore
        "Normal";
    } else {
      // @ts-ignore
      this.settingsSubMenuValueEl_.innerHTML = `${playbackRate}`;
    }
    // @ts-ignore
    this.settingsSubMenuValueEl_.parentNode.focus();
  }

  // @ts-ignore
  checkQualityValue(subMenuItem) {
    if (
      // @ts-ignore
      subMenuItem.options_.selected ||
      subMenuItem.hasClass("vjs-selected")
    ) {
      // @ts-ignore
      // Set quality value to selected value
      this.settingsSubMenuValueEl_.innerHTML = subMenuItem.options_.label;
    }
    // @ts-ignore
    this.settingsSubMenuValueEl_.parentNode.focus();
  }

  // @ts-ignore
  checkSubmenuForQualityValue() {
    // @ts-ignore
    // Quality item doesn't have label el, so we have to iterate through the children of quality's secondary submenu to find the selected value
    // We loop through the secondary submenu children to find the currently selected quality value to assign to the primary menu item
    for (let subMenuItem of this.subMenu.menu.children_) {
      if (!(subMenuItem instanceof component)) {
        continue;
      }

      this.checkQualityValue(subMenuItem);
    }
  }

  populateQuality() {
    // @ts-ignore
    if (this.settingsSubMenuTitleEl_.innerHTML === "Quality:") {
      // @ts-ignore
      const qualitySubMenu = this.settingsSubMenuEl_.firstChild.firstChild;
      qualitySubMenu.className += " qualitySubMenu";
    }

    // Playback Rate menu item doesn't get a vjs-selected class or an options_selected value, so we use labelEl to get the selected value text for Playback Rate
    if (this.subMenu instanceof playbackRateMenuButton) {
      this.checkPlaybackRate();
    } else {
      this.checkSubmenuForQualityValue();
    }
  }
  // Separating this allows the submenus to retain their state after selecting a new item, instead of being recreated each time.
  updateSelectedMenuOptions() {
    // @ts-ignore
    this.populatePlaybackRate();
    // @ts-ignore
    this.populateQuality();
  }

  populateMenuItems() {
    // @ts-ignore
    // This sets the title text of the primary submenu items
    this.settingsSubMenuTitleEl_.innerHTML = `${this.subMenu.controlText_}:`;
    // @ts-ignore
    // This appends the secondary submenu with the value options relevant to their parent primary submenu
    this.settingsSubMenuEl_.appendChild(this.subMenu.menu.el_);

    this.updateSelectedMenuOptions();
  }

  // This function targets the primary submenu items, removing the open class and adding vjs-hidden
  removeOpenClass() {
    removeClassIfElementExists(document.getElementsByClassName("open")[0], "open");
    videojs.dom.addClass(document.getElementsByClassName("vjs-settings-sub-menu")[0], "vjs-hidden");
    videojs.dom.addClass(document.getElementsByClassName("vjs-settings-sub-menu")[1], "vjs-hidden");
  }

  // This function will hide the sub menu element itself if clicking on a primary submenu item when it already has the open class
  hideSubMenu() {
    // This.el() is the current primary submenu item (Quality/Playback)
    if (videojs.dom.hasClass(this.el(), "open")) {
      // @ts-ignore
      videojs.dom.addClass(this.settingsSubMenuEl_ as Element, "vjs-hidden");
      // @ts-ignore
      for (const element of this.settingsSubMenuEl_.getElementsByClassName("active")) {
        element.classList.remove("active");
      }
    }
  }
}

export default SettingsMenuItem;
