import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { type HttpResponse } from "../../../Application/http/http";
import HttpCodes from "../../../types/HttpCodes";
import { fetchEvent, fetchAppointmentInfo, trackAttendance } from "../../services/eventsService";
import { type EventAppointment, type Event, type TrackActivityRequest } from "../../types";
import { req, got, err, forbidden, track, appointmentSelector } from "./redirectSlice";

function* handleResult(event: Event, appointment: HttpResponse<EventAppointment>) {
  if (appointment.isSuccess) {
    yield put(got({ event: event, appointment: appointment.data }));
  } else if (appointment.code === HttpCodes.Forbidden) {
    yield put(got({ event: event, appointment: null }));
    yield put(forbidden());
  } else {
    throw new Error(appointment.message);
  }
}

export function* handleFetchSessionInfo(action: ReturnType<typeof req>) {
  try {
    const { eventId } = action.payload;

    const [event, appointment]: [Event, HttpResponse<EventAppointment>] = yield all([
      call(async () => {
        const resp = await fetchEvent(eventId);
        return resp.data;
      }),
      call(fetchAppointmentInfo, action.payload),
    ]);

    yield* handleResult(event, appointment);
  } catch (error) {
    yield put(err((error as any).message));
  }
}

export function* handleTrack(action: ReturnType<typeof track>) {
  const { sessionId, sessionStart } = action.payload;
  const appointment: EventAppointment = yield select(appointmentSelector);

  try {
    yield call(trackAttendance, {
      sessionId: sessionId,
      sessionStartDate: sessionStart,
      attendedDate: new Date().toISOString(),
    } as TrackActivityRequest);
  } catch (error) {}

  window.open(appointment.link, "_self");
}

export function* eventRedirectWatcherSaga() {
  yield takeLatest(req.type, handleFetchSessionInfo);
  yield takeLatest(track.type, handleTrack);
}
