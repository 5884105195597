import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function durationSecondsToTime(seconds: number): string {
  const secNum = parseInt(seconds.toString(), 10);
  const h = Math.floor(secNum / 3600);
  const m = Math.floor(secNum / 60) % 60;
  const s = secNum % 60;

  return [h, m, s]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function isoStringToDate(isoString: string) {
  return dayjs(isoString).utc().format("YYYY/MM/DD");
}

export const dateInPast = (dateStr?: string | null, currentDate = new Date()): boolean => {
  if (!dateStr) return false;

  return dayjs(dateStr).isBefore(currentDate);
};
