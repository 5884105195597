import videojs from "video.js";
import type Player from "video.js/dist/types/player";

const Button = videojs.getComponent("Button"); // NOSONAR videojs deprecation warning, no alternative

export interface VideoJsControlOptions {
  onClick?: () => void;
  className?: string;
  text?: string | null;
  disableButton?: boolean;
}

export class VideoJsControlButton extends Button {
  private buttonOptions: VideoJsControlOptions;

  constructor(player: Player, buttonOptions: Partial<VideoJsControlOptions>) {
    super(player);
    this.buttonOptions = buttonOptions;
    this.el().classList.add("vjs-play-control");
    this.el().classList.add("vjs-navigation-menu-item");
    this.buttonOptions.className && this.el().classList.add(this.buttonOptions.className);
    this.buttonOptions.text && (this as any).controlText(this.buttonOptions.text);
    this.buttonOptions.disableButton && (this as any).disable();
  }

  createEl(tag = "button", props = {}, attributes = {}) {
    return super.createEl(tag, props, attributes);
  }

  handleClick() {
    this.buttonOptions.onClick && this.buttonOptions.onClick();
  }
}
