import classNames from "classnames/bind";
import styles from "./VideoDetailsAnswer.module.scss";
import { RStatus } from "features/Application/types";
import { AppLoader, AppErrorView } from "features/Application/layout";
import { Icon } from "semantic-ui-react";
import {
  clearAnswer,
  selectVideoQuestionsAnswer,
  selectVideoQuestionsAnswerStatus,
} from "features/Assets/redux/videoQuestions/videoQuestionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatureFlag } from "features/common/hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

const cx = classNames.bind(styles);

export const VideoDetailsAnswer = () => {
  const { t } = useTranslation("application");
  const aiEnabled = useFeatureFlag(FeatureFlags.AiVideoQuestions);
  const dispatch = useDispatch();
  const answer = useSelector(selectVideoQuestionsAnswer);
  const answerStatus = useSelector(selectVideoQuestionsAnswerStatus);

  const dismissAnswer = () => dispatch(clearAnswer());

  if (!aiEnabled) {
    return null;
  }

  return (
    <div
      className={cx(styles.answer, {
        [styles.visible]: answerStatus !== RStatus.Idle,
      })}
      data-testid="video-details-answer"
    >
      {answerStatus === RStatus.Pending && <AppLoader active />}
      {answerStatus === RStatus.Got && !!answer && (
        <>
          <Icon name="times" link className={styles.icon} onClick={dismissAnswer} /> {answer}
          <hr />
        </>
      )}
      {answerStatus === RStatus.Error && (
        <>
          <Icon name="times" link className={styles.icon} onClick={dismissAnswer} />
          <AppErrorView message={t("httpError.default")} />
        </>
      )}
    </div>
  );
};
