import { call, put, takeLatest } from "redux-saga/effects";
import { req, got, err } from "./accountSlice";
import { type Account } from "../../types";
import { fetchAccount } from "../../services/accountService";

export function* handleFetchAccount() {
  try {
    const account: Account = yield call(fetchAccount);
    yield put(got({ current: account }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* accountWatcherSaga() {
  yield takeLatest(req.type, handleFetchAccount);
}
