import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/sharp-solid-svg-icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { Button } from "../../../Application/layout";
import { ErrorTypes } from "../../../types/ErrorTypes";
import { type FlowError } from "../../types";
import { flowError, notReadyError, progressError, serverError } from "./constants";

import styles from "./errorsHandler.module.scss";
import HttpCodes from "../../../types/HttpCodes";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";

export interface Props {
  error: FlowError;
  onClick: () => void;
}

export interface ErrorData {
  icon: IconDefinition;
  title: string;
  description: string;
  canRetry: boolean;
}

const getErrorData = (error: FlowError): ErrorData => {
  return {
    [ErrorTypes.Flow]: (error.code && flowError[error.code]) || serverError,
    [ErrorTypes.Progress]: (error.code && progressError[error.code]) || serverError,
    [ErrorTypes.Assets]: notReadyError,
    [ErrorTypes.StartFlow]: serverError,
    [ErrorTypes.Operations]: serverError,
  }[error.type];
};

export const ErrorsHandler = (props: React.PropsWithChildren<Props>) => {
  const { error, onClick } = props;
  const { t } = useTranslation("flows");
  const { icon, title, description, canRetry } = useMemo(() => getErrorData(error), [error]);
  if (error.type === ErrorTypes.Flow && error.code === HttpCodes.Forbidden) {
    return <HttpErrorView code={ErrorType.Forbidden} back />;
  }

  return (
    <div className={styles.root}>
      <EmptyState
        icon={<FontAwesomeIcon className={styles.icon} icon={icon} />}
        title={t(title)}
        description={t(description)}
        fullWidth
      >
        <>
          {canRetry && (
            <Button className={styles.button} onClick={onClick}>
              {t("tryAgain")}
            </Button>
          )}
        </>
      </EmptyState>
    </div>
  );
};
