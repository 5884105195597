import classNames from "classnames";
import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./CardSessionStatus.module.scss";

const cx = classNames.bind(styles);

interface Props {
  isRegistered: boolean;
}

type Color = "gray" | "yellow";

export const CardSessionStatus = (props: Props) => {
  const color: Color = props.isRegistered ? "yellow" : "gray";
  const text = props.isRegistered ? "REGISTERED" : "NOT REGISTERED";
  return (
    <div className={cx(styles.root, styles[`${color}`])}>
      <Icon className="circle" /> {text}
    </div>
  );
};
