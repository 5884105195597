import axios from "axios";
import { type HomeItem } from "../types";

export interface GetHomeItemsRequest {
  priority: number;
  top?: number;
  skip: number;
  sortBy: string;
  sortOrder: string;
  priorityEntityType?: string;
}

export interface HomeItemsV4Response {
  [key: number]: HomeItem[];
}

export const fetchHomeItemsV4 = async (): Promise<HomeItemsV4Response> => {
  const response = await axios.get("api/v4/client/home");
  return response.data;
};

export interface UpcomingEventResponse {
  id: number;
  title: string;
  description: string;
  thumbnailUrl: string;
  isRegistered: boolean;
  isReccurent: boolean;
  applications: string[];
  startDateTimeUtc: string;
  endDateTimeUtc: string;
}

export const fetchUpcomingEvents = async (top: number) => {
  const response = await axios.get<UpcomingEventResponse[]>(`/api/client/external-events/upcoming?top=${top}`);
  return response.data;
};
