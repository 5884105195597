import classNames from "classnames/bind";
import { type DiscoveryFilterName, type DiscoveryFiltersOptions } from "features/Discover/config/filters";
import { type Dispatch, type SetStateAction, memo, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import { InvisibleButton } from "../../../Application/layout/InvisibleButton/InvisibleButton";
import { Flyout } from "../../layout/Flyout/Flyout";
import { type Filter } from "../../types";
import styles from "./FilterBar.module.scss";
import { FilterSection } from "./FilterSection";
import { FILTERS_TOGGLER_ID } from "./contants";

const cx = classNames.bind(styles);

export interface Props {
  show: boolean;
  filterValues: { [key: string]: any };
  setShow: Dispatch<SetStateAction<boolean>>;
  filters: DiscoveryFiltersOptions;
  update: (filter: Filter, value: any) => void;
  reset: (filter: Filter) => void;
}

export const FiltersBar = (props: Props) => {
  const { show: isVisible, setShow, filters, update, reset, filterValues } = props;
  const { isTabletPortraitScreen, isMobileScreen } = useContext(BreakpointContext);
  const { t } = useTranslation(["discover"]);

  const toggleFilters = useCallback(() => setShow(v => !v), [setShow]);
  const valueProvider = useCallback(
    (key: string) => {
      return filterValues[key] ?? [];
    },
    [filterValues],
  );

  const closeFlyout = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLInputElement;
      const toggler = document.getElementById(FILTERS_TOGGLER_ID);

      if (isVisible && !(target.isSameNode(toggler) || toggler?.contains(target))) {
        toggleFilters();
      }
    },
    [isVisible, toggleFilters],
  );

  const isMobile = isTabletPortraitScreen || isMobileScreen;
  const body = useMemo(
    () => (
      <>
        {isMobile && (
          <div className={styles.mobileFilterHeader}>
            <Icon className={styles.iconColor} fitted name="filter" />
            <span className={styles.filterResults}>{t("common.filterResults")}</span>
            <span className={styles.closeIconContainer}>
              <InvisibleButton onClick={toggleFilters}>
                <span className={styles.iconColor}>
                  <Icon
                    className={cx(styles.heavy, "thin")}
                    name="times"
                    data-testid="filter close button"
                    fitted
                    size="large"
                  />
                </span>
              </InvisibleButton>
            </span>
          </div>
        )}
        <div className={cx({ [styles.contentContainer]: isMobile })}>
          <div className={cx({ [styles.content]: isMobile })}>
            {Object.keys(filters).map(filterKey => (
              <div className={cx({ [styles.extraPadding]: isMobile })} key={`Filter ${filterKey}`}>
                <FilterSection
                  valueProvider={valueProvider}
                  filter={filters[filterKey as DiscoveryFilterName]!}
                  update={update}
                  reset={reset}
                  className={styles.filterSectionContainer}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    ),
    [filters, isMobile, reset, t, toggleFilters, update, valueProvider],
  );

  if (isMobile) {
    return (
      <OnClickOutside handler={closeFlyout}>
        <Flyout
          direction={isMobileScreen ? "bottom" : "right"}
          height={isMobileScreen ? 70 : 45}
          open={isVisible}
          absoluteSize
        >
          <div className={styles.flyoutContainer}>{body}</div>
        </Flyout>
      </OnClickOutside>
    );
  }

  return (
    <OnClickOutside handler={closeFlyout}>
      {!isMobile && (
        <div className={styles.head}>
          <Icon name="filter" fitted />
          <span className={styles.filterResults} tabIndex={0}>
            {t("common.filterResults")}
          </span>
        </div>
      )}
      <div data-testid="filtersection">{body}</div>
    </OnClickOutside>
  );
};

export default memo(FiltersBar);
