import { type MutableRefObject, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { updateSettings } from "features/Account/redux/userSettings/userSettingsSlice";
import { setVideoProgress } from "features/Assets/redux/videoQuestions/videoQuestionsSlice";
import { useDispatch } from "react-redux";
import { markVideoAsStartedV4, markVideoAsWatchedV4 } from "../../../services/assetsService";
import { type ClosedCaptions, type Playlist, type VideoStreamManifest } from "../../../types";
import { VideoJSPlayer } from "../VideoJSPlayer/VideoJSPlayer";
import useStreamingSettings from "./hooks/useStreamingSettings";
import utils from "./utils/languageUtils";
import styles from "./VideoPlayer.module.scss";

export interface Props extends Playlist {
  sources: VideoStreamManifest[];
  closedCaptions: ClosedCaptions[];
  resetAssetRef?: MutableRefObject<(() => void) | undefined>;
}

export const VideoPlayer: React.FC<Props> = props => {
  const { sources, closedCaptions, ...otherProps } = props;
  const streamingSettings = useStreamingSettings(sources);
  const { assetId } = useParams();
  const dispatch = useDispatch();

  const sortCaptions = useMemo(() => {
    const sortedClosedCaptions = [...closedCaptions];
    sortedClosedCaptions.sort((a, b) => {
      const nameA = utils.getLanguageByCode(a.isoCode)?.name;
      const nameB = utils.getLanguageByCode(b.isoCode)?.name;
      if (nameA! < nameB!) return -1;
      if (nameA! > nameB!) return 1;
      return 0;
    });

    let foundClosedCaption = sortedClosedCaptions.find(
      caption => caption.isoCode === utils.getLanguageByCode(utils.browserCurrentLanguage())?.code,
    );
    if (foundClosedCaption) {
      const filteredAndSortedClosedCaptions = sortedClosedCaptions.filter(
        closedCaption => closedCaption.isoCode !== foundClosedCaption!.isoCode,
      );
      filteredAndSortedClosedCaptions.unshift(foundClosedCaption);
      return filteredAndSortedClosedCaptions;
    } else {
      return sortedClosedCaptions;
    }
  }, [closedCaptions]);

  const onVideoStart = useCallback(() => {
    markVideoAsStartedV4(Number(assetId));
  }, [assetId]);

  const onVideoEnd = useCallback(() => {
    markVideoAsWatchedV4(Number(assetId));
    dispatch(setVideoProgress(0));
  }, [assetId, dispatch]);

  /* istanbul ignore next */
  const onVolumeChange = useCallback(
    (newVolume: number) => {
      dispatch(
        updateSettings({
          settings: {
            volume: newVolume,
          },
        }),
      );
    },
    [dispatch],
  );

  /* istanbul ignore next */
  const onRateChange = useCallback(
    (newRate: number) => {
      dispatch(
        updateSettings({
          settings: {
            playbackRate: newRate,
          },
        }),
      );
    },
    [dispatch],
  );

  /* istanbul ignore next */
  const onQualityChange = useCallback(
    (newQuality: number) => {
      dispatch(
        updateSettings({
          settings: {
            videoQuality: newQuality,
          },
        }),
      );
    },
    [dispatch],
  );

  /* istanbul ignore next */
  const onClosedCaptionChange = useCallback(
    (newCCLanguage: string | null) => {
      dispatch(
        updateSettings({
          settings: {
            ccLanguage: newCCLanguage,
          },
        }),
      );
    },
    [dispatch],
  );

  const onTimeUpdate = useCallback(
    (currentTime: number) => {
      dispatch(setVideoProgress(currentTime));
    },
    [dispatch],
  );

  return (
    <div className={styles.root} data-testid="video-asset-player-container">
      <VideoJSPlayer
        source={streamingSettings}
        closedCaptions={sortCaptions}
        options={{ autoplay: true }}
        onVideoStarted={onVideoStart}
        onVideoEnded={onVideoEnd}
        onRateChange={onRateChange}
        onVolumeChange={onVolumeChange}
        onQualityChange={onQualityChange}
        onClosedCaptionChange={onClosedCaptionChange}
        onTimeUpdate={onTimeUpdate}
        {...otherProps}
      />
    </div>
  );
};
