import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";
import { BreakpointContext } from "../../../Application/components/context/BreakpointContext";
import {
  AppLoader,
  Button,
  FixedHeightAppPageWrapper,
  InvisibleButton,
  Segment,
  Segments,
} from "../../../Application/layout";
import { BackTitle } from "../../../Application/layout/BackTitle/BackTitle";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { RStatus } from "../../../Application/types";
import { Flyout } from "../../../Discover/layout/Flyout/Flyout";
import {
  fetchMembersMore,
  gotMembers,
  req,
  reqContent,
  reqMembers,
  reset,
  selectFilteredMembers,
  selectGroup,
  selectGroupStatus,
  selectMembersError,
  selectMembersInlineCount,
  selectMembersResultsReachedEnd,
  selectMembersSearchTerm,
  selectMembersStatus,
} from "../../redux/group/groupSlice";
import { type Group, type GroupActionSuccess } from "../../types";
import { Access, Visibility } from "../../types/enums";
import { GroupContent } from "../GroupContent/GroupContent";
import { GroupImage } from "../GroupImage/GroupImage";
import { GroupMembers } from "../GroupMembers/GroupMembers";

import { InvalidRoutePage } from "../../../Application/components/InvalidRoutePage/InvalidRoutePage";

import * as groupEvents from "../../../Application/services/realTimeNotification/events/groupEvents";

import { AccessRestricted } from "../../../Application/components/AccessRestricted/AccessRestricted";
import { useRtn } from "../../../Application/hooks/useRtn";
import { Toast, ToastType } from "../../../Application/layout/Toast/Toast";
import { CustomHttpHeaders } from "../../../Application/types/enums";
import { groupActionButtonShouldBeAvailable } from "../../common/methods";
import { defaultGroupsTake, getMembers, joinGroup, leaveGroup } from "../../services/groupsService";
import styles from "./GroupDetails.module.scss";
import { TextTruncator } from "features/Application/layout/TextTruncator/TextTruncator";

export const groupMember = (member: boolean | undefined) => member !== undefined && !member;

export const GroupDetails: React.FC = () => {
  const { t } = useTranslation("groups");
  const params = useParams();
  const groupId = params["groupId"];

  const group = useSelector(selectGroup);
  const status = useSelector(selectGroupStatus);
  const members = useSelector(selectFilteredMembers);
  const membersInlineCount = useSelector(selectMembersInlineCount);
  const membersStatus = useSelector(selectMembersStatus);
  const membersError = useSelector(selectMembersError);
  const membersSearchTerm = useSelector(selectMembersSearchTerm);
  const membersReachedEnd = useSelector(selectMembersResultsReachedEnd);

  const dispatch = useDispatch();

  const [groupActionPending, setGroupActionPending] = useState(false);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [errorText, setErrorText] = useState("");

  const bp = useContext(BreakpointContext);

  const btnText = useMemo(() => {
    if (!group) return "";
    return group.joinedDate !== null ? t("leave") : t("join");
  }, [t, group]);

  const actionButtonShouldBeAvailable = useMemo(() => {
    if (!group) return false;
    return groupActionButtonShouldBeAvailable({ ...group });
  }, [group]);

  const fetchGroupInfo = useMemo(() => {
    return debounce((id: number) => {
      dispatch(req({ id }));
      dispatch(reqMembers({ id }));
      dispatch(reqContent({ id }));
      setGroupActionPending(false);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    const id = groupId && parseInt(groupId, 10);

    if (id) {
      fetchGroupInfo(id);
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, groupId, fetchGroupInfo]);

  useEffect(() => {
    setFlyoutOpen(false);
  }, [bp.breakpoint]);

  useEffect(() => {
    if (group?.name) {
      document.title = group.name;
      SendPageView();
    }
  }, [group]);

  const handleFilterChange = (value: string) => {
    dispatch(reqMembers({ id: parseInt(groupId!, 10), term: value }));
  };

  const fetchMoreMembers = useCallback(() => {
    dispatch(fetchMembersMore({ term: membersSearchTerm }));
  }, [dispatch, membersSearchTerm]);

  const restrictMembersArea = (groupData: Group) => {
    return (
      groupData.joinedDate === null &&
      (groupData.isVisibleToAll === Visibility.MembersOnly || groupData.isOpen === Access.Restricted)
    );
  };

  const closeFlyout = useCallback(() => {
    setFlyoutOpen(false);
  }, []);

  const toggleFlyout = useCallback(() => {
    setFlyoutOpen(!flyoutOpen);
  }, [flyoutOpen]);

  const getMembersSection = (): React.ReactNode | null => {
    if (!group || restrictMembersArea(group)) {
      return null;
    }

    return (
      <section className={styles.memberSection} data-testid="members-section">
        <GroupMembers
          group={group}
          status={membersStatus}
          error={membersError}
          fetchMore={fetchMoreMembers}
          reachedEnd={membersReachedEnd}
          membersToShow={members}
          handleFilterChange={debounce(handleFilterChange, 500)}
          handleClose={closeFlyout}
          searchTerm={membersSearchTerm}
          inlineCount={membersInlineCount}
        />
      </section>
    );
  };

  const performGroupAction = useCallback(() => {
    (async () => {
      // go to group overview
      setGroupActionPending(true);
      try {
        if (group) {
          if (group.joinedDate !== null) {
            await leaveGroup(group.id);
          } else {
            await joinGroup(group.id);
          }
        }
      } catch (err) {
        setGroupActionPending(false);
      }
    })();
  }, [group]);

  const groupActionSuccess = useCallback(
    ({ GroupId }: GroupActionSuccess) => {
      fetchGroupInfo(GroupId);
    },
    [fetchGroupInfo],
  );

  /**
   * Remove these calls once back-end resolves issue with group members not updating
   * immediately after joining a group
   */

  const debouncedUpdateMembersSilently = useMemo(() => {
    return debounce(async () => {
      if (!groupId) return;
      try {
        const [fetchedMembers, headers] = await getMembers(+groupId, membersSearchTerm, 0, defaultGroupsTake);
        const inlineCount = parseInt(headers[CustomHttpHeaders.recordsCount]);
        dispatch(gotMembers({ members: fetchedMembers, count: inlineCount }));
      } catch (err) {
        console.error("Unable to update group information");
        console.error(err);
      }
    }, 2000);
  }, [dispatch, groupId, membersSearchTerm]);

  useEffect(() => {
    if (!groupId || !group?.membersCount) {
      return;
    }
    if (group.membersCount !== membersInlineCount) {
      const debounce = async () => {
        await debouncedUpdateMembersSilently();
      };
      debounce().catch(e => console.error(e));
    }
  }, [groupId, group?.membersCount, membersInlineCount, debouncedUpdateMembersSilently]);

  /**
   * End calls to remove
   */

  const showJoinError = useCallback(() => {
    setErrorText(t("groupJoinFailed"));
    setDisplayToast(true);
  }, [t]);

  const showLeaveError = useCallback(() => {
    setErrorText(t("groupLeaveFailed"));
    setDisplayToast(true);
  }, [t]);

  // Show a banner when joining a group fails
  useRtn([groupEvents.UserJoinSuccess, groupEvents.UserLeaveSuccess], groupActionSuccess);
  useRtn([groupEvents.UserJoinFail], showJoinError);
  useRtn([groupEvents.UserLeaveFail], showLeaveError);

  const invalidId = !groupId || isNaN(+groupId);
  const noGroupFound = status === RStatus.Got && !group;

  const hideToast = useCallback(() => {
    setDisplayToast(false);
  }, []);

  if (invalidId || noGroupFound) {
    return <InvalidRoutePage />;
  }

  return (
    <FixedHeightAppPageWrapper reactStrictMode={false}>
      <>
        {status === RStatus.Pending && <AppLoader />}
        {status === RStatus.Error && (
          <AccessRestricted description={t("accessRestrictedDescription")} className={styles.restricted} />
        )}
        {status === RStatus.Got && group && (
          <div className={styles.root}>
            <div className={styles.titleContainer}>
              <BackTitle to={"/groups"} title={group.name} />
              {!bp.isLargeScreen && (
                <div className={styles.openMembersFlyout}>
                  <OnClickOutside handler={closeFlyout}>
                    <>
                      <InvisibleButton ariaLabel={t("toggleMembersList")} onClick={toggleFlyout}>
                        <div className={styles.membersLabelOffset}>
                          <Icon className={styles.iconColor} name="group" fitted />
                          {!bp.isMobileScreen && <span className={styles.membersLabel}>{t("member")}</span>}
                        </div>
                      </InvisibleButton>
                      <Flyout
                        open={flyoutOpen}
                        direction={bp.isMobileScreen ? "bottom" : "right"}
                        height={30}
                        absoluteSize
                      >
                        {getMembersSection()}
                      </Flyout>
                    </>
                  </OnClickOutside>
                </div>
              )}
            </div>
            <div className={styles.body}>
              <section className={styles.mainSection}>
                <div className={styles.topPart}>
                  <div className={styles.imageContainer}>
                    <GroupImage imageUrl={group.imageUrl} />
                  </div>
                  <div className={styles.groupInfo}>
                    <h2 className={styles.groupTitle}>
                      <TextTruncator clamp={2} tooltipContent={group.name}>
                        {group.name}
                      </TextTruncator>
                    </h2>
                    <p className={styles.groupDescription}>
                      <TextTruncator clamp={5} tooltipContent={group.description}>
                        {group.description}
                      </TextTruncator>
                    </p>
                    {actionButtonShouldBeAvailable && (
                      <Button
                        data-testid="Group Action"
                        onClick={performGroupAction}
                        disabled={groupActionPending}
                        fullWidth
                        loading={groupActionPending}
                      >
                        {btnText}
                      </Button>
                    )}
                  </div>
                </div>
                <div className={styles.controls}>
                  <Segments hideForSingleSegment>
                    <Segment to="content" label="Content" />
                  </Segments>
                </div>
                <div className={styles.groupContent}>
                  <Routes>
                    <Route index element={<Navigate to="content" replace />} />
                    <Route path="content" element={<GroupContent interactive={group.joinedDate !== null} />} />
                    <Route path="*" element={<InvalidRoutePage />} />
                  </Routes>
                </div>
              </section>
              {bp.isLargeScreen && getMembersSection()}
            </div>
          </div>
        )}
        <Toast visible={displayToast} type={ToastType.Error} body={errorText} onClose={hideToast} autoDismiss />
      </>
    </FixedHeightAppPageWrapper>
  );
};
