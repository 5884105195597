import { type Dispatch } from "@reduxjs/toolkit";
import EventEmitter from "events";
import { type AsyncOperationType } from "../../slices/asyncOperationSlice";
import rtnHandler from "./rtnHandler";

const eventEmitter = new EventEmitter();

type EventEmitterAction = "on" | "off" | "once" | "prependListener";

const handleMultipleEvents = (
  event: string | string[],
  handler: (...args: any[]) => void,
  action: EventEmitterAction,
) => {
  let events: string[] = Array.isArray(event) ? event : [event];
  events.forEach(eventItem => {
    eventEmitter[action](eventItem, handler);
  });
};

const RtnEventsEmitter = {
  subscribe: (event: string | string[], handler: (...args: any[]) => void) =>
    handleMultipleEvents(event, handler, "on"),

  subscribeOnce: (event: string, handler: (...args: any[]) => void) => handleMultipleEvents(event, handler, "once"),

  prependSubscribe: (event: string, handler: (...args: any[]) => void) =>
    handleMultipleEvents(event, handler, "prependListener"),

  unsubscribe: (event: string | string[], handler: (...args: any[]) => void) =>
    handleMultipleEvents(event, handler, "off"),

  subscribeFromMapping(eventTypes: string[], handlersMapping: any) {
    eventTypes.forEach(event => {
      const handler = handlersMapping[event];
      if (handler) {
        this.subscribe(event, handler);
      }
    });
  },

  subscribeOnceAndWait(event: string, timeout: number) {
    return new Promise((resolve, reject) => {
      const timeoutHandle = window.setTimeout(() => {
        this.unsubscribe(event, handler);
        reject();
      }, timeout);

      const handler = (payload: any) => {
        window.clearTimeout(timeoutHandle);
        resolve(payload);
      };

      this.subscribeOnce(event, handler);
    });
  },
};

export default RtnEventsEmitter;

export const createRtnHandler = (asyncActionsProvider: () => AsyncOperationType, dispatch: Dispatch) =>
  rtnHandler(eventEmitter, asyncActionsProvider, dispatch);
